import React, { useState } from 'react'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'
import {
  cardBorder,
  cardBorderGrey,
  darkBlue,
  darkBlueFontColor,
  lightBlueFontColor,
  mainText,
  pageTypeOptionButton,
  pageTypeOptionButtonHovered,
  pageTypeOptionButtonSelected,
  selectableCardBackgroundNotSelected,
  selectableCardBackgroundSelected,
} from 'assets/styles/variables'
import { CATALOG_URL } from 'features/project/utils'
import mixpanel from '../../../../../../../trackers/mixpanel'
import { AmazonOutlined, BarcodeOutlined } from '@ant-design/icons'
import { SourceIcon as Icon } from '../../../../../../../../shared/components'
import { YogiButton } from 'components/UI/YogiButton'
import { Modal } from 'antd'
import { SourceDetail } from '../../../../../sourcepage/components'
import { FocusData, FocusListOption } from '../../CatalogList'
import styled from 'styled-components'

interface FocusProps extends FocusData {
  item: FocusListOption
  category: string | null
  onSelect: (value: string) => void
  selectedItem: any
}

const CatalogListItem: React.FC<FocusProps> = ({
  item,
  category,
  title,
  reviewCount,
  imageUrl,
  productCount,
  onSelect,
  selectedItem,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)

  const [sourceModalOpen, setSourceModalOpen] = useState<boolean>(false)

  const isSelected = item.value === selectedItem

  return (
    <>
      <a
        style={{ textDecoration: 'none', color: mainText }}
        href={`${window.location.origin}/project/${projectId}/${CATALOG_URL}?category=${category}&item=${item.value}`}
        onClick={(e) => {
          mixpanel.track('catalog', {
            action: 'open item',
            value: item.value,
          })
          e.preventDefault()
        }}
      >
        <FocusItemContainer
          onClick={() => {
            onSelect(item.value)
          }}
          selected={isSelected}
        >
          <Content>
            {/*<ColorBar color={secondaryNavy} />*/}
            <FocusItemHeader>
              <Title>
                {title.length > 100 ? `${title.slice(0, 100)}...` : title}
              </Title>
              <Subheader>
                <div>
                  {productCount && productCount > 1 && (
                    <>
                      Items: {productCount.toLocaleString()}
                      <br />
                    </>
                  )}
                  Reviews & Ratings: {reviewCount.toLocaleString()}
                </div>
              </Subheader>
            </FocusItemHeader>
            {imageUrl && (
              <ImageWrapper>
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt=""
                    style={{ height: 'auto', width: 'auto', maxHeight: 80 }}
                  />
                ) : (
                  <BarcodeOutlined style={{ fontSize: '60px' }} />
                )}
              </ImageWrapper>
            )}
            {category === 'source_type' && (
              <>
                <ButtonWrapper>
                  <Icon
                    source={item.image_url ?? 'syndicated'}
                    style={{
                      width: '50px',
                      height: '50px',
                    }}
                  />
                  <YogiButton
                    onClick={(e: any) => {
                      e.preventDefault()
                      e.stopPropagation()
                      setSourceModalOpen(true)
                    }}
                  >
                    View Sources
                  </YogiButton>
                </ButtonWrapper>
              </>
            )}
          </Content>
          {/*{category === 'product_hierarchy' && isSelected && (*/}
          {/*  <Options>*/}
          {/*    <OptionButton*/}
          {/*      selected={tabIndex === 0}*/}
          {/*      onClick={() => setTabIndex(0)}*/}
          {/*    >*/}
          {/*      <GenericSvgIcon SvgComponent={OverviewSvgIcon} />*/}
          {/*      Overview*/}
          {/*    </OptionButton>*/}
          {/*    <OptionButton*/}
          {/*      selected={tabIndex === 1}*/}
          {/*      onClick={() => setTabIndex(1)}*/}
          {/*    >*/}
          {/*      <AmazonOutlined />*/}
          {/*      PDP Insights*/}
          {/*    </OptionButton>*/}
          {/*  </Options>*/}
          {/*)}*/}
        </FocusItemContainer>
      </a>

      {sourceModalOpen && (
        <Modal
          className="source-modal"
          title={item.value}
          open={true}
          footer={null}
          onCancel={() => setSourceModalOpen(false)}
          width={850}
        >
          <SourceDetail
            role={details.role}
            sourcename={item.value}
            projectId={projectId}
            syndicated={false}
          ></SourceDetail>
        </Modal>
      )}
    </>
  )
}
export default CatalogListItem

const Subheader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  color: ${lightBlueFontColor};
  font-size: var(--font-size-md);
`

const FocusItemContainer = styled.div<{ selected: boolean }>`
  padding: 8px;

  background: ${({ selected }) =>
    selected ? darkBlue : selectableCardBackgroundNotSelected};

  color: ${({ selected }) => (selected ? 'white' : darkBlueFontColor)};

  cursor: pointer;

  border: 1px solid ${cardBorderGrey};
  border-radius: var(--border-radius);

  transition: transform ease 0.2s;
  &:hover {
    background: ${(props) =>
      props.selected ? darkBlue : selectableCardBackgroundSelected};
  }

  ${Subheader} {
    color: ${({ selected }) => (selected ? cardBorder : lightBlueFontColor)};
  }
`

const Content = styled.div`
  position: relative;

  width: 100%;

  display: flex;
  justify-content: space-between;

  padding-left: 8px;
`

const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`

const OptionButton = styled.div<{ selected: boolean }>`
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 14px;
  background: ${({ selected }) =>
    selected ? pageTypeOptionButtonSelected : pageTypeOptionButton};
  color: ${({ selected }) => (selected ? lightBlueFontColor : 'white')};
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  gap: 10px;
  align-items: center;

  &:hover {
    background: ${({ selected }) =>
      selected ? pageTypeOptionButtonSelected : pageTypeOptionButtonHovered};
  }
`

const FocusItemHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  flex-basis: 75%;
  flex-grow: 1;
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 50%;
  border-radius: 5px;
  overflow: hidden;
  flex-grow: 1;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  min-width: 100px;
  max-width: 50%;
  border-radius: 5px;
  overflow: hidden;
`

const Title = styled.h3`
  overflow-wrap: anywhere;
`
