import React, { ErrorInfo, ReactNode } from 'react'
import styled from 'styled-components'
import { YogiButton } from 'components/UI/YogiButton'
import { ErrorBoundaryContent } from 'components/ErrorBoundary/ErrorBoundaryContent/ErrorBoundaryContent'
import * as Sentry from '@sentry/react'

interface ErrorBoundaryProps {
  children?: ReactNode
  fallback?: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can log the error to an error reporting service
    // console.log('Error:', error, errorInfo)
    Sentry.captureException(error)
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback || <ErrorBoundaryContent />
    }

    return this.props.children
  }
}

export default ErrorBoundary
