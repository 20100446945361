import Amplify, { Auth } from 'aws-amplify'
import AWS from 'aws-sdk'
import { init } from '@sentry/browser'
import awsconfig from './aws-exports'

try {
  Amplify.configure(awsconfig)
  // i believe version 6 uses localStorage by default
  Auth.configure({ storage: localStorage })

  const awsConfig = new AWS.Config({
    credentials: new AWS.Credentials(
      import.meta.env.VITE_AWS_CLIENT_ID,
      import.meta.env.VITE_AWS_CLIENT_SECRET,
    ),
    region: 'us-east-1',
  })

  AWS.config.update(awsConfig)
} catch (e) {
  console.log(e)
}

export const getS3 = (Bucket: string) =>
  new AWS.S3({
    params: { Bucket },
    apiVersion: '2006-03-01',
  })

// init({
//   dsn: 'https://b0ef76808b34498088b8c0520eba1bc1@o408751.ingest.sentry.io/5280003',
// })
