import React, { useEffect } from 'react'
import mixpanel from 'features/trackers/mixpanel'

type Props = {
  pageName: string
}

const PageDurationTracker: React.FC<Props> = ({ pageName }) => {
  useEffect(() => {
    // Record when the user enters the page
    const startTime = Date.now()

    // Cleanup function that runs when component unmounts
    return () => {
      const endTime = Date.now()
      const durationInSeconds = (endTime - startTime) / 1000

      // Track page duration when user leaves
      mixpanel.track('viewtime', {
        page_name: pageName,
        duration_seconds: durationInSeconds,
        start_timestamp: startTime,
        end_timestamp: endTime,
      })
    }
  }, [pageName])

  return null // This is a utility component with no UI
}

export default PageDurationTracker
