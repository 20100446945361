import React, { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { Filters, ProjectState } from 'features/project/types'
import {
  FeedbackResponse,
  getFeedback,
  Post,
  PostDetailModal,
  PostsGrid,
} from '../../../../../../feedback'
import { LogoSpinnerSmall } from 'components/Loading/LogoSpinner/LogoSpinnerSmall'
import { OutlierType } from '../../../../../model'
import { YogiButton } from 'components/UI/YogiButton'
import { ButtonWrapper } from './InsightReviews.styles'
import styled from 'styled-components'
import mixpanel from 'features/trackers/mixpanel'

interface Props {
  outlier: OutlierType
  filterValues: Filters
  postData: FeedbackResponse | undefined
  page: number
  setPage: (page: number) => void
  postsLoading: boolean
  postsFetching: boolean
}

const InsightReviews: React.FC<Props> = ({
  outlier,
  filterValues,
  postData,
  page,
  setPage,
  postsLoading,
  postsFetching,
}) => {
  // local state
  const [postDetailUuid, setPostDetailUuid] = useState<string>()
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false)

  // pagination handling and deduping - there may be a better way to do this
  useEffect(() => {
    if (postData) {
      const seenReviews = new Set<string>()
      const dedupedPosts: Post[] = []

      for (const post of postData.posts) {
        const reviewKey = post.title + post.body
        if (!seenReviews.has(reviewKey)) {
          seenReviews.add(reviewKey)
          dedupedPosts.push(post)
        }
      }

      setPosts([...(page > 1 ? posts : []), ...dedupedPosts])
      setLoadMoreLoading(false)
    }
  }, [postData])

  // weird post api local state
  const [posts, setPosts] = useState<Post[]>(postData ? postData.posts : [])

  // derived constants
  const hasMore = (postData?.n_pages || 0) > page
  const onClosePostDetail = useCallback(() => setPostDetailUuid(undefined), [])

  return (
    <>
      {postData ? (
        <>
          <PostsGrid
            posts={posts}
            onPostClick={(postId: string) => {
              mixpanel.track('insights feed', {
                action: 'click review',
              })
              setPostDetailUuid(postId)
            }}
            isLoading={postsLoading}
            isFetching={postsFetching}
          />
          <ButtonWrapper data-html2canvas-ignore={'true'}>
            {postData?.posts.length > 0 && hasMore && (
              <YogiButton
                onClick={() => {
                  setLoadMoreLoading(true)
                  setPage(page + 1)
                  mixpanel.track('insights feed', {
                    action: 'load more reviews',
                  })
                }}
                disabled={loadMoreLoading}
              >
                {loadMoreLoading ? 'Loading' : 'Load more'}
              </YogiButton>
            )}
          </ButtonWrapper>
        </>
      ) : (
        <LogoSpinnerSmall>
          <p>Fetching Reviews...</p>
        </LogoSpinnerSmall>
      )}
      <PostDetailModal
        postUuid={postDetailUuid}
        onClose={onClosePostDetail}
        fragment={false}
      />
    </>
  )
}

export default InsightReviews
