import { getOutlierAnalysis, OutlierType } from '../model'
import { useContext, useMemo } from 'react'
import {
  createChartItemFromOutlierChart,
  getOutlierChartFilterValues,
  getOutlierCharts,
  getOutlierFilterValues,
  getOutlierThemeFilterValues,
  getOutlierTitle,
} from '../utils'
import { Filters, ProjectState } from '../../../types'
import useThemes from '../../../hooks/useThemes'
import { NewDashboardPayload } from '../../dashboard/types'
import { useQuery } from 'react-query'
import { useProjectStore } from '../../../projectStore/projectStore'
import { AuthContext } from 'features/auth'
import useHub from 'features/project/hooks/useHub'

type AutoInsightsResult = {
  outlierFilters: ReturnType<typeof getOutlierFilterValues>
  themeFilters: ReturnType<typeof getOutlierThemeFilterValues>
  chartFilters: ReturnType<typeof getOutlierThemeFilterValues>
  convertOutlierToCustomDashboard: (isActive: boolean) => NewDashboardPayload
  getOutlierFilters: (
    outlier: OutlierType,
    filterValues: Filters,
  ) => ReturnType<typeof getOutlierFilterValues>
}

const useAutoInsights = (
  outlier: OutlierType,
  filterValues: Filters,
): AutoInsightsResult => {
  const { getClusterUUIDsFromThemeName } = useThemes()
  const authContext = useContext(AuthContext)
  const currentUserEmail = authContext?.user?.attributes?.email

  const { hasDatasets } = useHub()

  const outlierFilters = useMemo(
    () => getOutlierFilterValues(outlier, filterValues, hasDatasets),
    [filterValues, outlier, hasDatasets],
  )

  const chartFilters = useMemo(
    () => getOutlierChartFilterValues(outlier, filterValues, hasDatasets),
    [filterValues, outlier, hasDatasets],
  )

  const getOutlierFilters = (outlier: OutlierType, filterValues: Filters) => {
    return getOutlierFilterValues(outlier, filterValues, hasDatasets)
  }

  const themeFilters = useMemo(
    () => getOutlierThemeFilterValues(outlier, filterValues, hasDatasets),
    [filterValues, outlier, getClusterUUIDsFromThemeName],
  )

  const convertOutlierToCustomDashboard = (isActive: boolean) => {
    // Implementation goes here
    const charts = getOutlierCharts(outlier, outlierFilters, themeFilters)
    const { analysis } = outlier

    const items = charts.map((chart, index) =>
      createChartItemFromOutlierChart(chart.chartItem, index),
    )

    items.push({
      type: 'text',
      title: 'Analysis',
      content: analysis,
      layout: {
        h: 5,
        w: 12,
        minH: 4,
        minW: 4,
        i: items.length.toString(),
        x: 0,
        y: items.length * 5,
      },
      dashboardControls: {},
      filterValues: outlierFilters,
    })

    const createDashboardBody: NewDashboardPayload = {
      name: getOutlierTitle(outlier),
      dashboard_settings: {
        filters: filterValues,
        items: items,
        layout: items.map((item) => item.layout),
        isCurrent: false,
        extra_settings: {
          outlier: outlier,
          is_active: isActive,
          active_users: [currentUserEmail],
        },
      },
      is_shared: false,
    }

    return createDashboardBody
  }

  return {
    outlierFilters,
    themeFilters,
    chartFilters,
    convertOutlierToCustomDashboard,
    getOutlierFilters,
  }
}

export default useAutoInsights
