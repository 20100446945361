import styled from 'styled-components'
import { pageBackgroundGrey } from 'assets/styles/variables'

export const Container = styled.div`
  //position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 25px 34px;
  //margin-top: -7px;
  border-radius: 10px;
  background: ${pageBackgroundGrey};
`

export const InsightsListItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;

  @media (max-width: 1300px) {
    gap: 10px;
  }
`
