import { format, parse } from 'date-fns'
import { intersection } from 'lodash'
import Papa, { ParseResult } from 'papaparse'
import { generateProductId } from 'features/project-form/utils'
import { ProjectSourceURL } from '../source/types'
import { Product } from '../product/types'
import { sourceTypes } from 'utils/source_types'
import { getSourceTypeByUrl } from '../../../../utils/sourceProcessing'

const RESERVED_FIELDS = [
  'Product_Formal Name',
  'Product_Item Name',
  'Product_Brand',
  'Product_Name',
  'Product_General Listing',
]

const REQUIRED_SOURCE_EXTRA_FIELDS: any = {
  Facebook: ['access_token', 'page_id'],
  Instagram: ['access_token', 'page_id'],
  'Re:amaze': ['brand', 'email', 'access_token'],
  Stamped: [
    'email',
    'extra_product_id',
    'store_hash',
    'sku',
    'public_key',
    'private_key',
  ],
  Okendo: ['public_key', 'private_key'],
  Emplifi: ['extra_product_id'],
  Zendesk: ['access_token', 'username'],
  Yotpo: ['access_token', 'extra_product_id'],
  Bazaarvoice: ['access_token', 'extra_product_id'],
  PowerReviews: ['access_token', 'extra_product_id', 'client_id', 'locale'],
}

const NO_URL_SOURCES_LOWERCASE = Object.keys(REQUIRED_SOURCE_EXTRA_FIELDS)
  .filter((key) => key !== 'Re:amaze')
  .reduce((obj: any, key) => {
    obj[key.toLocaleLowerCase()] = key
    return obj
  }, {})

const isEmptyKeyValue = (value: any) => {
  return value === undefined || value === ''
}

const buildSourceTitle = (sourceType: string, itemName: string) => {
  return `${sourceType} - ${itemName}`
}

const buildErrorMessage = (errorMessage: string, rowNumber: number) => {
  return `${errorMessage} (row: ${rowNumber})`
}

const checkNoUrlSources = (source: string) => {
  return NO_URL_SOURCES_LOWERCASE[source.toLowerCase()]
}

const getUrlSource = (url: string) => {
  let source = checkNoUrlSources(url)
  if (source !== undefined) {
    return source
  }
  try {
    return getSourceTypeByUrl(url)
  } catch {
    return undefined
  }
}

const pushErrorMessage = (
  errorMessagesArray: string[],
  errorMessage: string,
  rowNumber: number
) => {
  errorMessagesArray.push(buildErrorMessage(errorMessage, rowNumber))
}

const checkRequiredColumns = (headerSet: Set<any>) => {
  if (
    !(headerSet.has('URL') || headerSet.has('Source')) ||
    !headerSet.has('Product_Item Name')
  ) {
    return (
      'CSV must contain "Product_Item Name" and "URL" columns\n' +
      ' (could be replaced with "Source" for Facebook, Instagram, Yotpo, Bazaarvoice and Powerreviews)'
    )
  }
}

const checkReservedKeys = (headerSet: Set<any>, reservedWords: string[]) => {
  let reservedWordsUsed = intersection(Array.from(headerSet), reservedWords)
  if (reservedWordsUsed.length > 0) {
    return (
      'Reserved words being used, please change following column names: ' +
      reservedWordsUsed
    )
  }
}

const getHeaderSet = (parseResults: ParseResult<any>) => {
  let headerSet = new Set()

  parseResults.data.forEach(function (result: any) {
    const keys = Object.keys(result)
    keys.forEach(function (key) {
      headerSet.add(key)
    })
  })
  return headerSet
}

export const getSourcesFromFile = (
  file: any,
  reservedWords: string[],
  sourceDate: Date,
  cb: (s: { sources: ProjectSourceURL[]; products: Product[] } | string) => void
) => {
  let sourceArray: ProjectSourceURL[] = []

  Papa.parse(file, {
    header: true,
    transformHeader: (h) => h.trim(),
    complete(results: ParseResult<any>) {
      let rowNumber = 1

      // Check if any reserved words are used
      const headerSet = getHeaderSet(results)
      const requiredColumnsMessage = checkRequiredColumns(headerSet)
      if (requiredColumnsMessage) {
        return cb(requiredColumnsMessage)
      }
      const reservedKeysMessage = checkReservedKeys(headerSet, reservedWords)
      if (reservedKeysMessage) {
        return cb(reservedKeysMessage)
      }

      let allRowsValid = true // Used to exit complete function in case of bad row
      let resultErrorMessages: string[] = []
      try {
        results.data.forEach(function (result: any) {
          // Skip empty lines. Use custom logic instead of the Papa.parse skipEmptyLines
          // parameter to save the correct line number for notifications.
          if (Object.values(result).join('').trim() === '') {
            rowNumber += 1
            return
          }
          let sourceJSON: any = {}
          let customJSON: any = {}
          let extraJSON: any = {}

          const csvKeys = Object.keys(result)
          csvKeys.forEach(function (key) {
            if (key === 'URL') {
              const url = result[key]
              let rowSource = result['Source']
              if (!rowSource) {
                rowSource = (url && getUrlSource(url)) || undefined
                if (!rowSource) {
                  pushErrorMessage(
                    resultErrorMessages,
                    'Cannot extract Source from url',
                    rowNumber
                  )
                  allRowsValid = false
                } else {
                  sourceJSON['source'] = rowSource
                }
              }
              // Don't put error if source doesn't require URL.
              if (!url && !(rowSource && checkNoUrlSources(rowSource))) {
                pushErrorMessage(resultErrorMessages, 'Bad URL', rowNumber)
                allRowsValid = false
              } else {
                sourceJSON['url'] = url
              }
            } else if (key === 'Source') {
              let rowSource = result[key]
              if (rowSource) {
                const noUrlSource = checkNoUrlSources(rowSource)
                if (!noUrlSource) {
                  sourceJSON['source'] = rowSource
                  if (sourceTypes[rowSource] === undefined) {
                    pushErrorMessage(
                      resultErrorMessages,
                      `Unsupported Source: ${sourceJSON['source']}`,
                      rowNumber
                    )
                    allRowsValid = false
                  }
                } else {
                  sourceJSON['source'] = noUrlSource
                }
              }
            } else if (key === 'Title') {
              if (result[key]) {
                sourceJSON['title'] = result[key]
              }
            } else if (key === 'Date') {
              sourceJSON['from_date'] = result[key]
              if (!sourceJSON['from_date']) {
                if (
                  // @ts-ignore
                  parse(sourceJSON['from_date'], 'mm/dd/yyyy', new Date()) ===
                  'Invalid Date'
                ) {
                  // Some degree of date format checking
                  pushErrorMessage(
                    resultErrorMessages,
                    'Badly formatted Date',
                    rowNumber
                  )
                  allRowsValid = false
                }
              }
            } else {
              const entry = result[key]
              if (!isEmptyKeyValue(entry)) {
                if (key.indexOf('SourceExtra_') !== -1) {
                  extraJSON[key.replace('SourceExtra_', '')] = entry
                } else {
                  // Blank entries in custom fields allowed
                  customJSON[key.trim()] = entry.trim().split(' ').join(' ')
                }
              }
            }
          })

          if (!customJSON['Product_Item Name']) {
            pushErrorMessage(
              resultErrorMessages,
              'Product_Item Name must be specified',
              rowNumber
            )
            allRowsValid = false
          } else if (!sourceJSON['title']) {
            sourceJSON['title'] = buildSourceTitle(
              sourceJSON['source'],
              customJSON['Product_Item Name']
            )
          }

          if (!sourceJSON['from_date']) {
            sourceJSON['from_date'] = format(sourceDate, 'MM/dd/yyyy')
          }

          if (
            REQUIRED_SOURCE_EXTRA_FIELDS[sourceJSON['source']] !== undefined
          ) {
            REQUIRED_SOURCE_EXTRA_FIELDS[sourceJSON['source']].forEach(
              (field: string) => {
                if (extraJSON[field] === undefined) {
                  pushErrorMessage(
                    resultErrorMessages,
                    `${sourceJSON['source']}: ${field} must be specified`,
                    rowNumber
                  )
                  allRowsValid = false
                }
              }
            )
          }

          sourceJSON['custom'] = customJSON
          if (Object.keys(extraJSON).length > 0) {
            sourceJSON['extra'] = extraJSON
          }

          if (
            allRowsValid &&
            (checkNoUrlSources(sourceJSON['source']) || sourceJSON.url)
          ) {
            sourceArray.push(sourceJSON)
          }
          rowNumber += 1
        })
      } catch (e) {
        pushErrorMessage(
          resultErrorMessages,
          'Unexpected error occurred while processing your file. Please, review your data and try again',
          rowNumber
        )
        allRowsValid = false
      }
      if (!allRowsValid) {
        return cb(resultErrorMessages.join('\n'))
      }
      const products: Product[] = []

      sourceArray = sourceArray.map((source) => {
        if (source.custom && source.custom['Product_Item Name']) {
          const name = source.custom['Product_Item Name'] as string
          const foundProduct = products.find((product) => product.name === name)

          if (!foundProduct) {
            const product_id = generateProductId()
            const customFields = Object.keys(source.custom).filter(
              (key) =>
                !RESERVED_FIELDS.includes(key) && key.indexOf('Product_') !== -1
            )

            products.push({
              id: product_id,
              name,
              brand: source.custom['Product_Brand'] as string,
              hierarchy: source.custom['Product_Name'] as string,
              general: source.custom['Product_General Listing']
                ? (
                    source.custom['Product_General Listing'] as string
                  ).toLowerCase() === 'true'
                : false,
              custom: customFields.reduce((res, key) => {
                if (source.custom) {
                  res[key.replace('Product_', '')] = source.custom[key]
                }
                return res
              }, {} as any),
            })
            for (let key in source.custom) {
              if (
                source.custom.hasOwnProperty(key) &&
                key.indexOf('Product_') !== -1
              ) {
                delete source.custom[key]
              }
            }
            return { ...source, product_id }
          } else {
            for (let key in source.custom) {
              if (
                source.custom.hasOwnProperty(key) &&
                key.indexOf('Product_') !== -1
              ) {
                delete source.custom[key]
              }
            }
            return { ...source, product_id: foundProduct.id }
          }
        }

        return source
      })
      return cb({ products, sources: sourceArray })
    },
    error(error: any) {
      return cb('Parse error: ' + error.message)
    },
  })
}
