import React, { useState } from 'react'
import styled from 'styled-components'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'
import {
  AppliedFilterOptionSelect,
  FilterOptionSelect,
} from 'features/project/features/filters/types'
import { Popover, Radio, Select } from 'antd'
import {
  buttonBlue,
  buttonHover,
  orangeButton,
  orangeButtonHover,
} from 'assets/styles/variables'
import { Cohort } from 'features/project/features/Insights/model'
import { useFilterList } from 'features/project/hooks/useFilterList'
import { emptyFilters, getFromLS, saveToLS } from 'features/project/utils'
import useHub from 'features/project/hooks/useHub'
import useDefaultFilterOptions from 'features/project/hooks/useDefaultFilterOptions'
import { FilterOutlined } from '@ant-design/icons'
import { Tune } from '@mui/icons-material'
import { YogiButton } from 'components/UI/YogiButton'
import {
  OUTLIER_TYPE_NAME,
  TIMESERIES_TYPE_NAME,
} from 'features/project/features/Insights/constants'
import mixpanel from 'features/trackers/mixpanel'

export type AdditionalFilters = {
  type: string
  context: string
  direction: string
  period: string
}

type Props = {
  setCohort: (cohort: Cohort) => void
  resetPageCount: () => void
  additionalFilters: AdditionalFilters
  setAdditionalFilters: (additionalFilters: AdditionalFilters) => void
}

export const Controls: React.FC<Props> = ({
  setCohort,
  resetPageCount,
  additionalFilters,
  setAdditionalFilters,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )

  const { getSelectList } = useDefaultFilterOptions()

  const cachedCohort: Cohort = JSON.parse(
    getFromLS(`${projectId}_cohort`) || '{}',
  )
  const [brandValues, setBrandValues] = React.useState<string[]>(
    cachedCohort.brands || [],
  )
  const [productValues, setProductValues] = React.useState<string[]>(
    cachedCohort.products || [],
  )
  const [datasetValues, setDatasetValues] = React.useState<string[]>(
    cachedCohort.datasets || [],
  )
  const [themeValues, setThemeValues] = React.useState<string[]>(
    cachedCohort.themes || [],
  )

  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)
  const [outlierType, setOutlierType] = useState(additionalFilters.type || '')
  const [outlierFilterContext, setOutlierFilterContext] = useState(
    additionalFilters.context || '',
  )
  const [outlierFilterDirection, setOutlierFilterDirection] = useState(
    additionalFilters.direction || '',
  )
  const [outlierFilterPeriod, setOutlierFilterPeriod] = useState(
    additionalFilters.period || '',
  )

  const brandOptions = getSelectList('brand')
  const productOptions = getSelectList('product_hierarchy')
  const datasetOptions = getSelectList('proj_uuid', true)
  // sometimes themes in the filter list are not uniquely labeled
  const themeOptions = [
    ...new Set(
      (
        defaultFilterList.find(
          (el) => el.field === 'theme',
        ) as FilterOptionSelect
      )?.values.map((el) => el.value),
    ),
  ].map((el) => ({ value: el, label: el }))

  const handleFindInsights = () => {
    resetPageCount()
    const newCohort = {
      brands: brandValues,
      products: productValues,
      themes: themeValues,
      datasets: datasetValues,
    }
    setCohort(newCohort)
    saveToLS(`${projectId}_cohort`, JSON.stringify(newCohort))
    mixpanel.track('insights feed', {
      action: 'find insights',
      value: [
        ...brandValues,
        ...productValues,
        ...themeValues,
        ...datasetValues,
      ],
    })
  }

  const handleUpdateAdditionalFilters = () => {
    resetPageCount()
    setAdditionalFilters({
      type: outlierType,
      context: outlierFilterContext,
      direction: outlierFilterDirection,
      period: outlierFilterPeriod,
    })
    setIsPopoverOpen(false)
  }

  // this prewarms the filter options request that is used after request is submitted
  const brandFilterValues = {
    ...emptyFilters,
    values: [
      {
        field: 'brand',
        values: brandValues,
      },
    ],
  }
  useFilterList(
    brandValues.length ? brandFilterValues : emptyFilters,
    brandValues.length ? 'product_hierarchy' : 'brand',
  )

  const controlChangeMixpanelEvent = (type: string, value: string[]) => {
    mixpanel.track('insights feed', {
      action: 'filter',
      type,
      value,
    })
  }

  return (
    <Container>
      <Text>Show me outliers {!datasetOptions ? 'limited to' : 'in'}</Text>
      {!!datasetOptions && (
        <>
          <StyledSelect
            mode={'multiple'}
            allowClear
            style={{ width: 250 }}
            placeholder="All Datasets"
            defaultValue={[]}
            onChange={(e) => {
              setDatasetValues(e as string[])
              controlChangeMixpanelEvent('dataset', e as string[])
            }}
            options={datasetOptions}
            maxTagCount={1}
            popupMatchSelectWidth={250}
            value={datasetValues}
          />
          <Text>limited to</Text>
        </>
      )}
      <StyledSelect
        mode={'multiple'}
        allowClear
        style={{ width: 200 }}
        placeholder="Selected Brands"
        defaultValue={[]}
        onChange={(e) => {
          setBrandValues(e as string[])
          controlChangeMixpanelEvent('brand', e as string[])
        }}
        options={brandOptions}
        maxTagCount={1}
        popupMatchSelectWidth={250}
        value={brandValues}
      />
      <Text>and</Text>
      <StyledSelect
        mode={'multiple'}
        allowClear
        style={{ width: 250 }}
        placeholder="Selected Products"
        defaultValue={[]}
        onChange={(e) => {
          setProductValues(e as string[])
          controlChangeMixpanelEvent('product', e as string[])
        }}
        options={productOptions}
        maxTagCount={1}
        popupMatchSelectWidth={250}
        value={productValues}
      />
      <Text>within</Text>
      <StyledSelect
        mode={'multiple'}
        allowClear
        style={{ width: 250 }}
        placeholder="All Themes"
        defaultValue={[]}
        onChange={(e) => {
          setThemeValues(e as string[])
          controlChangeMixpanelEvent('theme', e as string[])
        }}
        options={themeOptions}
        maxTagCount={1}
        popupMatchSelectWidth={250}
        value={themeValues}
      />
      <Right>
        <Popover
          open={isPopoverOpen}
          onOpenChange={(v: boolean) => {
            setIsPopoverOpen(v)
            if (v) {
              setOutlierType(additionalFilters.type)
              setOutlierFilterContext(additionalFilters.context)
              setOutlierFilterDirection(additionalFilters.direction)
              setOutlierFilterPeriod(additionalFilters.period)

              controlChangeMixpanelEvent('advanced', [
                additionalFilters.type,
                additionalFilters.context,
                additionalFilters.direction,
                additionalFilters.period,
              ])
            }
          }}
          trigger={'click'}
          content={
            <div>
              <AdditionalControl>
                <Label>Insight Type</Label>
                <Radio.Group
                  value={outlierType}
                  buttonStyle="solid"
                  onChange={(e) => {
                    setOutlierType(e.target.value)
                  }}
                >
                  <Radio.Button value="">All</Radio.Button>
                  <Radio.Button value={OUTLIER_TYPE_NAME}>Theme</Radio.Button>
                  <Radio.Button value={TIMESERIES_TYPE_NAME}>
                    Timeseries
                  </Radio.Button>
                </Radio.Group>
              </AdditionalControl>
              <AdditionalControl>
                <Label>Insight Context</Label>
                <Radio.Group
                  value={outlierFilterContext}
                  buttonStyle="solid"
                  onChange={(e) => {
                    setOutlierFilterContext(e.target.value)
                  }}
                >
                  <Radio.Button value="">All</Radio.Button>
                  <Radio.Button value="brand">Brand</Radio.Button>
                  <Radio.Button value="product">Product</Radio.Button>
                </Radio.Group>
              </AdditionalControl>
              <AdditionalControl>
                <Label>Insight Direction</Label>
                <Radio.Group
                  value={outlierFilterDirection}
                  buttonStyle="solid"
                  onChange={(e) => {
                    setOutlierFilterDirection(e.target.value)
                  }}
                >
                  <Radio.Button value="">All</Radio.Button>
                  <Radio.Button value="positive">Positive</Radio.Button>
                  <Radio.Button value="negative">Negative</Radio.Button>
                </Radio.Group>
              </AdditionalControl>
              {/*<AdditionalControl>*/}
              {/*  <Label>Time Period</Label>*/}
              {/*  <Radio.Group*/}
              {/*    value={outlierFilterPeriod}*/}
              {/*    buttonStyle="solid"*/}
              {/*    onChange={(e) => {*/}
              {/*      setOutlierFilterPeriod(e.target.value)*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <Radio.Button value="">All</Radio.Button>*/}
              {/*    <Radio.Button value="month">Month</Radio.Button>*/}
              {/*    <Radio.Button value="quarter">Quarter</Radio.Button>*/}
              {/*    <Radio.Button value="year">Year</Radio.Button>*/}
              {/*  </Radio.Group>*/}
              {/*</AdditionalControl>*/}
              <ButtonWrapper>
                <YogiButton
                  type={'primary'}
                  onClick={handleUpdateAdditionalFilters}
                >
                  Update Filters
                </YogiButton>
              </ButtonWrapper>
            </div>
          }
        >
          <AdditionalFilters>
            <Tune />
          </AdditionalFilters>
        </Popover>
        <StartButton onClick={handleFindInsights}>Find Insights</StartButton>
      </Right>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  //justify-content: space-between;
  //align-items: center;
  gap: 10px;
  row-gap: 20px;
  flex-wrap: wrap;
  align-items: center;

  background: white;

  padding: 17px 17px 10px;
`

const Text = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #5072a4;
`

const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 17px;
`
const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  //margin-bottom: 17px;
  flex-grow: 1;
  justify-content: end;
`

const AdditionalFilters = styled.div`
  display: flex;
  align-items: center;
  color: ${buttonBlue};
  cursor: pointer;
  &:hover {
    color: ${buttonHover};
  }
`

const StartButton = styled.div`
  display: flex;
  justify-content: space-between;
  //align-items: center;
  border-radius: 10px;
  background: ${orangeButton};
  cursor: pointer;
  color: white;
  padding: 5px 20px;
  font-size: 14px;
  &:hover {
    background: ${orangeButtonHover};
  }
`

const AdditionalControl = styled.div`
  margin-bottom: 10px;
`

const Label = styled.div`
  margin-bottom: 3px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 15px;
`

const StyledSelect = styled(Select)`
  .ant-select-selection-overflow-item {
    max-width: calc(100% - 55px);
  }
`
