import React, { useEffect, useMemo, useRef, useState } from 'react'
import { getOutlierAnalysis, OutlierResponse, OutlierType } from '../../model'
import {
  Container,
  Header,
  Title,
  Subtitle,
  SidePanel,
  Content,
} from './InsightDetail.styles'
import { primaryOrange } from 'assets/styles/variables'
import InsightCharts from './components/InsightCharts/InsightCharts'
import { Filters, ProjectState } from '../../../../types'
import { InsightAnalysis } from './components/InsightAnalysis/InsightAnalysis'
import { getOutlierTitle } from '../../utils'
import InsightSubtitle from './components/InsightSubtitle/InsightSubtitle'
import styled from 'styled-components'
import {
  ClockSVG,
  SubtitleArrowsSVG,
} from 'features/project/features/Insights/components/InsightsFeedSVGComponents/InsightsFeedSVGComponents'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { InsightStats } from 'features/project/features/Insights/components/InsightDetail/components/InsightStats/InsightStats'
import { TableOfContents } from 'features/project/features/Insights/components/InsightDetail/components/TableOfContents/TableOfContents'
import { ShareExport } from 'features/project/features/Insights/components/InsightDetail/components/ShareExport/ShareExport'
import { NotInterested } from 'features/project/features/Insights/components/InsightDetail/components/NotInterested/NotInterested'
import { useQuery } from 'react-query'
import { getFeedback } from 'features/project/features/feedback'
import useAutoInsights from 'features/project/features/Insights/hooks/useAutoInsights'
import { LoadingOutlined } from '@ant-design/icons'
import { DEFAULT_MODEL } from 'features/project/features/Insights/constants'
import { LogoSpinner } from 'components/Loading/LogoSpinner/LogoSpinner'
import { HardcodedFilterOptionsEnum } from 'features/project/features/filters/helpers'
import mixpanel from 'features/trackers/mixpanel'
import PageDurationTracker from 'components/PageDurationTracker/PageDurationTracker'

interface Props {
  outlierRecord: OutlierResponse & { type: string }
  filterValues: Filters
}

const activeModel = DEFAULT_MODEL //  model ?? DEFAULT_MODEL
export interface Section {
  id: string
  title: React.ReactNode
  subsections?: Array<{
    id: string
    title: React.ReactNode
  }>
}

const InsightDetail: React.FC<Props> = ({ outlierRecord, filterValues }) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const setIsAlertDrawerOpen = useProjectStore(
    (state: ProjectState) => state.setIsAlertDrawerOpen,
  )
  const setAlertRequest = useProjectStore(
    (state: ProjectState) => state.setAlertRequest,
  )

  const { outlierFilters } = useAutoInsights(outlierRecord, {
    values: outlierRecord.filter.criteria,
    searchQuery: outlierRecord.filter.search_terms,
    searchCondition: outlierRecord.filter.search_criteria,
  })

  const { data: outlier } = useQuery(
    ['analysis', projectId, activeModel, outlierRecord.uuid],
    () =>
      getOutlierAnalysis(
        projectId,
        outlierRecord,
        outlierRecord.filter,
        activeModel,
        //prompt
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!projectId && !!outlierRecord,
      retry: 3,
    },
  )

  // const needed for api
  const order = 'desc'
  const orderField = 'create_time'
  const page = 1

  // api
  const {
    data: postData,
    isFetching: postsFetching,
    isLoading: postsLoading,
  } = useQuery(
    [
      `feedback-posts_`,
      projectId,
      outlierFilters.values,
      outlierFilters.searchQuery,
      outlierFilters.searchCondition,
      order,
      orderField,
      page,
    ],
    async () => {
      const payload = {
        headers: {
          proj_uuid: projectId,
          page,
          order,
          order_field: orderField,
          page_size: 24,
        },
        body: {
          criteria: outlierFilters.values,
          search_terms: outlierFilters.searchQuery,
          search_criteria: outlierFilters.searchCondition,
        },
      }
      const { data } = await getFeedback(payload.headers, payload.body)
      return data
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      keepPreviousData: true,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId,
    },
  )

  // Sample sections data
  const sections: Section[] = useMemo(
    () => [
      {
        id: 'story',
        title: 'Outlier Summary',
      },
      {
        id: 'validation',
        title: 'Chart Data',
      },
      {
        id: 'analysis',
        title: 'Analysis',
        subsections: outlier?.analysis?.body.map((analysis, index) => ({
          id: `analysis-${index}`,
          title: analysis.title,
        })),
      },
      {
        id: 'reviews',
        title: (
          <div>
            Reviews&nbsp;
            {postData?.n_posts ? (
              `(${postData.n_posts})`
            ) : (
              <LoadingOutlined style={{ marginLeft: 5 }} />
            )}
          </div>
        ),
      },
    ],
    [outlier, postData?.n_posts],
  )

  const [activeSection, setActiveSection] = useState<string>('')
  const [activeSubsection, setActiveSubsection] = useState<string>('')
  const sectionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({})
  const imageRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)

  // Handle smooth scroll when clicking on TOC items
  const scrollToSection = (sectionId: string) => {
    const element = sectionRefs.current[sectionId]
    if (element && contentRef.current) {
      contentRef.current.scroll({
        left: 0,
        top: element.offsetTop - 310,
        behavior: 'smooth',
      })
    }
    mixpanel.track('insights feed', {
      action: 'table of contents',
      value: sectionId,
      outlier: getOutlierTitle(outlierRecord),
    })
  }

  // Update active section based on scroll position
  useEffect(() => {
    const container = contentRef.current
    if (!container) return

    const handleScroll = () => {
      const scrollPosition = container.scrollTop + 311 // Offset for better highlighting

      // Find the last section that has been scrolled past
      let currentSection = ''

      for (const section of sections) {
        const element = sectionRefs.current[section.id]
        if (element) {
          const { offsetTop } = element
          if (scrollPosition >= offsetTop) {
            currentSection = section.id
          }
        }

        if (section.subsections) {
          for (const subsection of section.subsections) {
            const element = sectionRefs.current[subsection.id]
            if (element) {
              const { offsetTop } = element
              if (scrollPosition >= offsetTop) {
                setActiveSubsection(subsection.id)
              }
            }
          }
        }
      }

      if (currentSection !== activeSection) {
        setActiveSection(currentSection)
      }
      if (
        !sections.find((section) => section.id === currentSection)?.subsections
      ) {
        setActiveSubsection('')
      }
    }

    container.addEventListener('scroll', handleScroll)
    // Initial check for active section
    handleScroll()

    return () => container.removeEventListener('scroll', handleScroll)
  }, [sections])

  const handleAlertClick = () => {
    const alertFilters: Filters = {
      values: outlierRecord.filter.criteria,
      searchQuery: outlierRecord.filter.search_terms,
      searchCondition: outlierRecord.filter.search_criteria,
    }

    alertFilters.values = alertFilters.values.filter(
      (el) => el.field !== 'create_time' && el.field !== 'score',
    )

    setAlertRequest(alertFilters)
    setIsAlertDrawerOpen(true)
    mixpanel.track('insights feed', {
      action: 'open alert',
    })
  }

  return (
    <Container>
      <PageDurationTracker pageName={'insight detail'} />
      {!outlier ? (
        <LoadingContainer>
          <DetailLoadingWrapper>
            <LogoSpinner height={200} />
            <LoaderTitle>Loading Insight</LoaderTitle>
          </DetailLoadingWrapper>
        </LoadingContainer>
      ) : (
        <>
          <Content ref={contentRef}>
            <ContentWrapper ref={imageRef}>
              <Section ref={(el) => (sectionRefs.current['story'] = el)}>
                <Header>
                  <Title>{getOutlierTitle(outlier)}</Title>
                </Header>
                <Subtitle>
                  <Arrows reverse={outlier.weighted_zscore < 0}>
                    <SubtitleArrowsSVG />
                  </Arrows>
                  <InsightSubtitle outlier={outlier}></InsightSubtitle>
                  <AlertContainer>
                    <Alert onClick={handleAlertClick}>
                      <div>SET ALERT</div>
                      <ClockSVG />
                    </Alert>
                  </AlertContainer>
                </Subtitle>
              </Section>
              <Section ref={(el) => (sectionRefs.current['validation'] = el)}>
                <Header>
                  <Title>Data & Chart Validation</Title>
                </Header>
                <ValidationContent>
                  <InsightCharts
                    outlier={outlier}
                    filterValues={filterValues}
                  />
                  <InsightStats outlier={outlier} />
                </ValidationContent>
              </Section>
              <Section ref={(el) => (sectionRefs.current['analysis'] = el)}>
                <Header></Header>
                <InsightAnalysis
                  key={outlier.uuid}
                  outlier={outlier}
                  sectionRefs={sectionRefs}
                />
              </Section>
            </ContentWrapper>
          </Content>
          <SidePanel>
            <ShareExport
              outlier={outlier}
              filterValues={filterValues}
              imageRef={imageRef}
            />
            <TableOfContents
              sections={sections}
              scrollToSection={scrollToSection}
              activeSection={activeSection}
              activeSubsection={activeSubsection}
            />
            <NotInterested outlier={outlier}></NotInterested>
          </SidePanel>
        </>
      )}
    </Container>
  )
}

export default InsightDetail

const TagWrapper = styled.div`
  margin-bottom: 5px;
`

const Tag = styled.span<{ background?: string; color?: string }>`
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
  color: ${({ color }) => color ?? '#28364F'};
  background: ${({ background }) => background ?? '#D2D3D8'};
  padding: 5px 7px;
  margin-bottom: 15px;

  border-radius: 5px;
`

const Section = styled.div`
  &:not(:last-child) {
    margin-bottom: 45px;
  }
`

const Arrows = styled.div<{ reverse: boolean }>`
  position: absolute;
  top: 50%;
  left: 16px;
  transform: ${({ reverse }) =>
    reverse ? 'translateY(-50%) rotate(180deg)' : 'translateY(-50%)'};
`

const AlertContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: 10px;
`

const Alert = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;

  padding: 10px;

  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);

  color: ${primaryOrange};
  background: #544554;

  border-radius: 10px;

  cursor: pointer;

  &:hover {
    background: #5f525f;
  }
  transition: all 0.15s ease-in-out;
`

const ValidationContent = styled.div`
  display: flex;
  gap: 10px;
  background: white;
  border-radius: 10px;
  padding: 27px 20px 10px 15px;

  @media screen and (max-width: 1400px) {
    gap: 20px;
    flex-direction: column;
  }
`

const ContentWrapper = styled.div`
  padding: 20px 27px;
`

const DetailLoadingWrapper = styled.div`
  margin-top: 50px;
`

const LoaderTitle = styled.div`
  text-align: center;
  font-size: var(--font-size-xxl);
  font-weight: 500;
  margin-top: 8px;
`

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
