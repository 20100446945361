import React, { useState } from 'react'
import mixpanel from '../../../../trackers/mixpanel'
import { useProjectStore } from '../../../projectStore/projectStore'
import { Filters, ProjectState } from '../../../types'
import { SearchPanel } from 'features/project/features/filters/SearchButton/components/SearchPanel/SearchPanel'
import { useClickOutside } from 'shared/hooks'
import styled from 'styled-components'
import {
  buttonBlue,
  cardBorder,
  filterButtonFontSize,
  lightBlueFontColor,
  navDefaultTextColor,
  pageBackgroundGrey,
} from 'assets/styles/variables'
import { CloseOutlined, SearchOutlined } from '@ant-design/icons'
import { useFeatureFlags } from 'features/project/hooks/useFeatureFlags'
import { SemanticSearchPanel } from 'features/project/features/filters/SearchButton/components/SemanticSearchPanel/SemanticSearchPanel'
import { SemanticSearchButton } from 'features/project/features/filters/SemanticSearchButton/SemanticSearchButton'

type Props = {
  filterValues: Filters
  comparativeIndex: number
  isSmall?: boolean
  updateLocalFilterValues?: (filterValues: Filters) => void
  isDashboardChart?: boolean
  isDashboardEdit?: boolean
  isAlertForm?: boolean
}

const MAX_PREVIEW_STRING_LENGTH = 25

export const SearchButton: React.FC<Props> = ({
  filterValues,
  comparativeIndex,
  isSmall,
  updateLocalFilterValues,
  isDashboardEdit,
  isDashboardChart,
  isAlertForm,
}) => {
  const details = useProjectStore((state: ProjectState) => state.details)
  const { isDashboard, isNetwork, isFeedback, isKeywords } = useProjectStore(
    (state: ProjectState) => state.route,
  )
  const setSearchQuery = useProjectStore(
    (state: ProjectState) => state.setSearchQuery,
  )
  const [isSearchPanelVisible, setSearchPanelVisible] = useState(false)

  const { data: feature_flags } = useFeatureFlags()

  const panelRef = React.createRef<HTMLDivElement>()
  useClickOutside(panelRef, (e) => {
    if (
      (e.target && e.target?.classList?.contains('search-button')) ||
      (e.path && e.path[1]?.id === 'search-button')
    )
      return
    setSearchPanelVisible(false)
  })

  const leftInset = () => {
    if (isKeywords) {
      return 590
    }
    if (isFeedback) {
      return 590
    }
    return isDashboardEdit ? 550 : isDashboardChart ? 450 : 600
  }

  const MAX_PREVIEW_STRING_LENGTH = isSmall ? 10 : 25

  const getPreviewLimit = (searchQuery: string[], isSmall?: boolean) => {
    if (isSmall || !searchQuery.length) return 1

    const totalLength = searchQuery
      .slice(0, 3)
      .reduce((sum, query) => sum + query.length, 0)

    if (totalLength <= MAX_PREVIEW_STRING_LENGTH) return 3
    if (
      searchQuery?.[2] &&
      totalLength - searchQuery?.[2].length <= MAX_PREVIEW_STRING_LENGTH
    )
      return 2
    return 1
  }

  const previewLimit = getPreviewLimit(filterValues.searchQuery, isSmall)

  const handleRemoveItem = (index: number) => {
    const newSearchQuery = filterValues.searchQuery.filter(
      (_, i) => i !== index,
    )
    if (updateLocalFilterValues) {
      updateLocalFilterValues({ ...filterValues, searchQuery: newSearchQuery })
    } else {
      setSearchQuery(comparativeIndex, newSearchQuery)
    }
  }

  const isSemanticSearchEnabled =
    details.clientSettings.has_vector_index &&
    feature_flags?.use_semantic_search

  if (isSemanticSearchEnabled && !isDashboardChart) {
    return (
      <SemanticSearchButton
        filterValues={filterValues}
        comparativeIndex={comparativeIndex}
        updateLocalFilterValues={updateLocalFilterValues}
        isAlertForm={isAlertForm}
      />
    )
  }

  return (
    <Wrapper
      className="search-button"
      style={{
        position: isDashboard ? 'static' : 'relative',
      }}
    >
      <Container
        className="search-button"
        onClick={() => {
          setSearchPanelVisible((prev) => !prev)
          mixpanel.track('filter panel open', {
            isDashboard,
            isNetwork,
            isFeedback,
            isKeywords,
          })
        }}
      >
        <Label className={'search-button'}>
          {isSmall ? (
            <span
              style={{ pointerEvents: 'none', fontSize: filterButtonFontSize }}
            >
              <SearchOutlined
                style={{
                  pointerEvents: 'none',
                  fontSize: filterButtonFontSize,
                }}
                size={10}
              />
            </span>
          ) : (
            <FullLabelContent>
              <SearchOutlined
                style={{
                  pointerEvents: 'none',
                  fontSize: 14,
                  color: '#D6E0ED',
                }}
                size={10}
              />
              <div>Search</div>
            </FullLabelContent>
          )}
        </Label>
        {/*<Count*/}
        {/*  count={filterValues.searchQuery?.length ?? 0}*/}
        {/*  className={'search-button'}*/}
        {/*>*/}
        {/*  <span*/}
        {/*    style={{ minWidth: 10, textAlign: 'center' }}*/}
        {/*    className={'search-button'}*/}
        {/*  >*/}
        {/*    {filterValues.searchQuery?.length ?? 0}*/}
        {/*  </span>*/}
        {/*</Count>*/}
      </Container>
      {!!filterValues.searchQuery.length && (
        <>
          {filterValues.searchQuery
            .slice(0, previewLimit)
            .map((searchQuery, index) => (
              <SearchedItem
                className={'search-button'}
                style={{ zIndex: 4 - index }}
                onClick={() => {
                  setSearchPanelVisible((prev) => !prev)
                  mixpanel.track('filter panel open', {
                    isDashboard,
                    isNetwork,
                    isFeedback,
                    isKeywords,
                  })
                }}
              >
                <SearchedItemText>{searchQuery}</SearchedItemText>
                <RemoveButton
                  onClick={(e) => {
                    handleRemoveItem(index)
                    e.stopPropagation()
                  }}
                >
                  <CloseOutlined />
                </RemoveButton>
              </SearchedItem>
            ))}
          {filterValues.searchQuery.length > previewLimit && (
            <SearchedItem
              className={'search-button'}
              style={{ zIndex: 4 - previewLimit }}
              onClick={() => {
                setSearchPanelVisible((prev) => !prev)
                mixpanel.track('filter panel open', {
                  isDashboard,
                  isNetwork,
                  isFeedback,
                  isKeywords,
                })
              }}
            >
              <div>...</div>
              {/*<RemoveButton>*/}
              {/*  <CloseOutlined />*/}
              {/*</RemoveButton>*/}
            </SearchedItem>
          )}
        </>
      )}

      {isSearchPanelVisible && (
        <>
          <SearchPanelWrapper
            ref={panelRef}
            comparativePanelsNumber={1}
            isComparative={false}
            dragged={false}
            isDashboardChart={!!isDashboardChart}
            leftInset={leftInset()}
            style={{
              // no clue why this is different for the two panels lol
              // the positioning requirements of this panel is truly bizzare
              top: isDashboardChart
                ? isSemanticSearchEnabled
                  ? '95px'
                  : '60px'
                : '45px',
              left: isDashboardChart ? '15px' : '5px',
            }}
          >
            {isSemanticSearchEnabled ? (
              <SemanticSearchPanel
                closePanel={() => setSearchPanelVisible(false)}
                comparativeIndex={comparativeIndex}
                filterValues={filterValues}
                updateLocalFilterValues={updateLocalFilterValues}
              />
            ) : (
              <SearchPanel
                closePanel={() => setSearchPanelVisible(false)}
                comparativeIndex={comparativeIndex}
                filterValues={filterValues}
                updateLocalFilterValues={updateLocalFilterValues}
              />
            )}
          </SearchPanelWrapper>
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const Container = styled.div<{ disabled?: boolean }>`
  font-size: ${filterButtonFontSize};
  border: 2px solid ${cardBorder};
  background: white;
  border-radius: 12px;
  display: flex;
  overflow: hidden;
  box-shadow: 0 0 5px 0 #00000026;
  cursor: pointer;
  transition: 0.2s ease;
  &:hover {
    border-color: ${navDefaultTextColor};
  }
  z-index: 5;
`

const Label = styled.div`
  display: flex;
  gap: 10px;
  padding: 8px 14px 8px;
  color: ${lightBlueFontColor};
`

const SearchPanelWrapper = styled.div<{
  isComparative: boolean
  comparativePanelsNumber: number
  dragged: boolean
  isDashboardChart: boolean
  leftInset: number
}>`
  overflow-y: hidden;
  background: #fff;
  position: absolute;
  left: 5px;
  top: 47px;
  z-index: 100;

  border-radius: 10px;
  border: 1px solid #e3e3e3;

  box-shadow: 0 4px 5px 0 #00000026;

  width: ${({ isDashboardChart, leftInset }) =>
    isDashboardChart ? 'calc(100% - 30px)' : `calc(100vw - ${leftInset}px)`};

  max-width: 550px;

  transform: ${({ dragged }) => (dragged ? 'none !important' : 'inherit')};
`

const FullLabelContent = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
`

const SearchedItem = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;

  font-size: var(--font-size-md);

  padding: 8px 14px 8px 34px;
  margin-left: -20px;

  border: 1px solid ${buttonBlue};
  border-radius: 10px;

  background: white;

  cursor: pointer;
`

const SearchedItemText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
`

const RemoveButton = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
  &:hover {
    color: grey;
  }
  transition: 0.2s ease;
`
