import { FilterOptionSelect } from 'features/project/features/filters/types'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'

const useHub = () => {
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )

  // populate a Select dropdown with filter type values
  const getSelectList = (field: string, useUuids?: boolean) => {
    return (
      defaultFilterList?.find((el) => el.field === field) as FilterOptionSelect
    )?.values.map((el) => ({
      value: useUuids ? (el.cluster_uuid ?? el.value) : el.value,
      label: el.value,
    }))
  }

  return {
    getSelectList,
  }
}

export default useHub
