import React, { useState } from 'react'
import mixpanel from '../../../../trackers/mixpanel'
import { useProjectStore } from '../../../projectStore/projectStore'
import { Filters, ProjectState } from '../../../types'
import { SearchPanel } from 'features/project/features/filters/SearchButton/components/SearchPanel/SearchPanel'
import { useClickOutside } from 'shared/hooks'
import styled from 'styled-components'
import {
  buttonBlue,
  buttonHover,
  cardBorder,
  filterButtonFontSize,
  lightBlueFontColor,
  navDefaultTextColor,
  pageBackgroundGrey,
  searchClearBackground,
  searchClearBackgroundHover,
  searchClearColor,
  searchTypeToggleBackground,
  searchTypeToggleColor,
} from 'assets/styles/variables'
import { CloseOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { useFeatureFlags } from 'features/project/hooks/useFeatureFlags'
import { SemanticSearchPanel } from 'features/project/features/filters/SearchButton/components/SemanticSearchPanel/SemanticSearchPanel'
import { Input, Select } from 'antd'
import { useSearchPanel } from 'features/project/features/filters/SearchButton/hooks/useSearchPanel'
import {
  BlueRadialSVG,
  BubblesSVG,
  EnterArrowSVG,
  OrangeRadialSVG,
} from 'features/project/features/filters/SemanticSearchButton/components/SVGComponents/SVGComponents'

const MAX_PREVIEW_STRING_LENGTH = 25

type Props = {
  filterValues: Filters
  comparativeIndex: number
  isSmall?: boolean
  updateLocalFilterValues?: (filterValues: Filters) => void
  isDashboardChart?: boolean
  isDashboardEdit?: boolean
  isAlertForm?: boolean
}

const searchTypeOptions = [
  {
    value: 'semantic',
    label: (
      <div>
        <span style={{ fontWeight: 600 }}>Smart</span> Search
      </div>
    ),
  },
  { value: 'keywords', label: 'Keywords' },
]

export const SemanticSearchButton: React.FC<Props> = ({
  filterValues,
  comparativeIndex,
  isSmall,
  updateLocalFilterValues,
  isDashboardEdit,
  isDashboardChart,
  isAlertForm,
}) => {
  const { isDashboard, isNetwork, isFeedback, isKeywords } = useProjectStore(
    (state: ProjectState) => state.route,
  )
  const setSearchQuery = useProjectStore(
    (state: ProjectState) => state.setSearchQuery,
  )

  const [isSearchPanelVisible, setSearchPanelVisible] = useState(false)
  const [isInputEditing, setIsInputEditing] = useState(false)

  const closePanel = () => {
    setSearchPanelVisible(false)
  }

  // hooks
  const searchPanelHook = useSearchPanel({
    filterValues,
    comparativeIndex,
    updateLocalFilterValues,
    closePanel,
  })

  const {
    isSemantic,
    setIsSemantic,
    value,
    handleSemanticSearch,
    handleSemanticPressEnter,
    handleSetValue,
    handleClear,
  } = searchPanelHook // may want to feed this to semanticsearchpanel

  const panelRef = React.createRef<HTMLDivElement>()
  useClickOutside(panelRef, (e) => {
    if (
      (e.target && e.target?.classList?.contains('search-button')) ||
      (e.path && e.path[1]?.id === 'search-button')
    )
      return
    setSearchPanelVisible(false)
  })

  const leftInset = () => {
    if (isKeywords) {
      return 590
    }
    if (isFeedback) {
      return 590
    }
    return isDashboardEdit ? 550 : isDashboardChart ? 450 : 600
  }

  const handleRemoveItem = (index: number) => {
    const newSearchQuery = filterValues.searchQuery.filter(
      (_, i) => i !== index,
    )
    if (updateLocalFilterValues) {
      updateLocalFilterValues({ ...filterValues, searchQuery: newSearchQuery })
    } else {
      setSearchQuery(comparativeIndex, newSearchQuery)
    }
  }

  const getPreviewLimit = (searchQuery: string[], isSmall?: boolean) => {
    if (isSmall || !searchQuery.length) return 1

    const totalLength = searchQuery
      .slice(0, 3)
      .reduce((sum, query) => sum + query.length, 0)

    if (totalLength <= MAX_PREVIEW_STRING_LENGTH) return 3
    if (
      searchQuery?.[2] &&
      totalLength - searchQuery?.[2].length <= MAX_PREVIEW_STRING_LENGTH
    )
      return 2
    return 1
  }

  const previewLimit = getPreviewLimit(filterValues.searchQuery, isSmall)

  return (
    <>
      <Container>
        <Left>
          <StyledSelect
            options={searchTypeOptions}
            // this is a little dirty but its a different input style than the original component
            // keeping until design is finalized
            value={isSemantic ? 'semantic' : 'keywords'}
            onChange={(value) => {
              setIsSemantic(value === 'semantic')
              if (value === 'keywords') {
                setSearchPanelVisible(true)
              }
            }}
            dropdownStyle={{
              minWidth: 120,
            }}
          ></StyledSelect>
        </Left>
        <Right>
          {isSemantic ? (
            <>
              <StyledInput
                value={value}
                onChange={handleSetValue}
                placeholder='e.g. "confusing instructions"'
                onPressEnter={(e) => {
                  handleSemanticPressEnter(e)
                  setIsInputEditing(false)
                }}
                onFocus={(e) => {
                  setIsInputEditing(true)
                }}
                onBlur={() => {
                  setTimeout(() => {
                    setIsInputEditing(false)
                  }, 250)
                  // in the alerts form, it makes sense to apply this without requiring an enter press
                  if (isAlertForm && value.length) {
                    handleSemanticSearch()
                  }
                }}
              ></StyledInput>

              {value && (
                <>
                  {isInputEditing ? (
                    <EnterButton onClick={handleSemanticSearch}>
                      <EnterArrowSVG />
                    </EnterButton>
                  ) : (
                    <ClearButton onClick={handleClear}>clear</ClearButton>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <AddKeywords onClick={() => setSearchPanelVisible(true)}>
                + Add
              </AddKeywords>
            </>
          )}
        </Right>

        {isSemantic && (
          <>
            <OrangeRadial>
              <OrangeRadialSVG />
            </OrangeRadial>
            <BlueRadial>
              <BlueRadialSVG />
            </BlueRadial>
            <Bubbles>
              <BubblesSVG />
            </Bubbles>
          </>
        )}
        {isSearchPanelVisible && (
          <>
            <SearchPanelWrapper
              ref={panelRef}
              comparativePanelsNumber={1}
              isComparative={false}
              dragged={false}
              isDashboardChart={!!isDashboardChart}
              leftInset={leftInset()}
              style={{
                top: isDashboardChart ? '60px' : '45px',
                left: isDashboardChart ? '15px' : '5px',
              }}
            >
              <SemanticSearchPanel
                closePanel={() => setSearchPanelVisible(false)}
                comparativeIndex={comparativeIndex}
                filterValues={filterValues}
                updateLocalFilterValues={updateLocalFilterValues}
                isKeywordsOnly
              />
            </SearchPanelWrapper>
          </>
        )}
      </Container>
      {!isSemantic && !!filterValues.searchQuery.length && (
        <>
          {filterValues.searchQuery
            .slice(0, previewLimit)
            .map((searchQuery, index) => (
              <SearchedItem
                className={'search-button'}
                style={{ zIndex: 4 - index }}
                onClick={() => {
                  setSearchPanelVisible((prev) => !prev)
                  mixpanel.track('filter panel open', {
                    isDashboard,
                    isNetwork,
                    isFeedback,
                    isKeywords,
                  })
                }}
              >
                <SearchedItemText>{searchQuery}</SearchedItemText>
                <RemoveButton
                  onClick={(e) => {
                    handleRemoveItem(index)
                    e.stopPropagation()
                  }}
                >
                  <CloseOutlined />
                </RemoveButton>
              </SearchedItem>
            ))}
          {filterValues.searchQuery.length > previewLimit && (
            <SearchedItem
              className={'search-button'}
              style={{ zIndex: 4 - previewLimit }}
              onClick={() => {
                setSearchPanelVisible((prev) => !prev)
                mixpanel.track('filter panel open', {
                  isDashboard,
                  isNetwork,
                  isFeedback,
                  isKeywords,
                })
              }}
            >
              <div>...</div>
              {/*<RemoveButton>*/}
              {/*  <CloseOutlined />*/}
              {/*</RemoveButton>*/}
            </SearchedItem>
          )}
        </>
      )}
    </>
  )
}

const Container = styled.div<{ disabled?: boolean }>`
  position: relative;
  font-size: ${filterButtonFontSize};
  border: 2px solid ${cardBorder};
  background: white;
  border-radius: 20px;
  display: flex;
  box-shadow: 0 0 5px 0 #00000026;
  //cursor: pointer;
  transition: 0.2s ease;

  &:hover {
    border-color: ${navDefaultTextColor};
  }

  z-index: 5;
  //min-width: 300px;
`

const Left = styled.div`
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
  background: ${searchTypeToggleBackground};
  z-index: 5;
`
const Right = styled.div`
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  background: white;
  z-index: 5;
`
const StyledSelect = styled(Select)`
  padding-left: 5px;
  .ant-select-selector {
    border: none !important;
    border-radius: 20px;
    background: ${searchTypeToggleBackground} !important;
    color: ${searchTypeToggleColor} !important;
    box-shadow: none !important;
  }
  .ant-select-arrow {
    color: ${searchTypeToggleColor} !important;
  }
`
const StyledInput = styled(Input)`
  min-width: 250px;
  border: none !important;
  font-size: 10px;
  padding-right: 70px;
  background: transparent;
  box-shadow: none !important;
`

const OrangeRadial = styled.div`
  overflow: hidden;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 80px;
  transform: translateY(-50%);
  z-index: -1;
  rotate: -2deg;
  //border-radius: 50%;
`

const BlueRadial = styled.div`
  //height: 50%;
  overflow: hidden;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 150px;
  transform: translateY(-50%);
  z-index: -1;
  //border-radius: 50%;
`
const Bubbles = styled.div`
  height: 50%;
  overflow: hidden;
  width: 100%;
  position: absolute;
  top: 2px;
  left: 300px;
  transform: translateY(-80%);
  z-index: -1;
  //border-radius: 50%;
`

const AddKeywords = styled.div`
  height: 100%;
  min-width: 30px;

  display: flex;
  align-items: center;
  padding: 0 10px;

  white-space: nowrap;

  color: white;
  background: ${buttonBlue};
  border-left: 2px solid ${cardBorder};

  cursor: pointer;

  &:hover {
    background: ${buttonHover};
  }
`

const Label = styled.div`
  display: flex;
  gap: 10px;
  padding: 8px 14px 8px;
  color: ${lightBlueFontColor};
`

const SearchPanelWrapper = styled.div<{
  isComparative: boolean
  comparativePanelsNumber: number
  dragged: boolean
  isDashboardChart: boolean
  leftInset: number
}>`
  overflow-y: hidden;
  background: #fff;
  position: absolute;
  left: 5px;
  top: 47px;
  z-index: 100;

  border-radius: 10px;
  border: 1px solid #e3e3e3;

  box-shadow: 0 4px 5px 0 #00000026;

  width: ${({ isDashboardChart, leftInset }) =>
    isDashboardChart ? 'calc(100% - 30px)' : `calc(100vw - ${leftInset}px)`};

  max-width: 550px;

  transform: ${({ dragged }) => (dragged ? 'none !important' : 'inherit')};
`

const FullLabelContent = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
`

const SearchedItem = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;

  font-size: var(--font-size-md);

  padding: 6px 14px 6px 34px;
  margin-left: -40px;

  border: 1px solid #dadfe9;
  border-radius: 10px;

  background: white;

  cursor: pointer;
`

const SearchedItemText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
`

const RemoveButton = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
  &:hover {
    color: grey;
  }
  transition: 0.2s ease;
`

const EnterButton = styled.div`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: ${buttonBlue};
  font-size: 8px;
  font-weight: 600;
  display: flex;
  align-items: center;

  &:hover {
    color: ${buttonHover};
  }
`
const ClearButton = styled.div`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  background: ${searchClearBackground};
  color: ${searchClearColor};
  font-size: 8px;
  font-weight: 600;
  padding: 4px 16px;
  border-radius: 14px;

  &:hover {
    background: ${searchClearBackgroundHover};
  }
`
