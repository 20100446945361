import React, { useMemo } from 'react'
import { OutlierType } from '../../../../model'
import { getOutlierCharts } from '../../../../utils'
import { Filters } from 'features/project/types'
import InsightChart from '../InsightChart/InsightChart'
import useAutoInsights from '../../../../hooks/useAutoInsights'
import styled from 'styled-components'

interface Props {
  outlier: OutlierType
  filterValues: Filters
}

const InsightCharts: React.FC<Props> = ({ outlier, filterValues }) => {
  const { chartFilters, themeFilters } = useAutoInsights(outlier, {
    values: outlier.filter.criteria,
    searchCondition: outlier.filter.search_criteria,
    searchQuery: outlier.filter.search_terms,
  })

  const charts = useMemo(
    () => getOutlierCharts(outlier, chartFilters, themeFilters),
    [outlier, getOutlierCharts],
  )

  return (
    <Container>
      {charts.map((chart, index) => (
        <ChartWrapper
          style={{
            minWidth: 250,
            flexGrow: 1,
            maxHeight: 400,
            height: 400,
          }}
        >
          <InsightChart chartItem={chart.chartItem} outlier={outlier} />
        </ChartWrapper>
      ))}
    </Container>
  )
}

export default InsightCharts

const Container = styled.div`
  border-radius: 10px;
  overflow: hidden;

  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex-grow: 1;
`

const ChartWrapper = styled.div``
