import { useQuery } from 'react-query'
import React from 'react'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'
import { getBookmarkUrlPost } from 'features/url-params/model'
import { CHARTS_URL } from 'features/project/utils'
import { Link } from 'react-router-dom'
import mixpanel from '../../../../../../../trackers/mixpanel'
import { mainText } from 'assets/styles/variables'
import { YogiButton } from 'components/UI/YogiButton'
import { OpenInNewOutlined } from '@mui/icons-material'
import { LoadingOutlined } from '@ant-design/icons'
import { Chart } from '../../../../../dashboard/components/Dashboard/Charts/chart'
import { OutlierType } from '../../../../model'
import { getOutlierTitle } from '../../../../utils'
import styled from 'styled-components'

type Props = {
  chartItem: any
  outlier: OutlierType
}

const InsightChart: React.FC<Props> = ({ chartItem, outlier }) => {
  // props
  const { filterValues } = chartItem

  // global state
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  // api
  const { data: linkData } = useQuery(
    ['getFilterHash', projectId, filterValues, chartItem.dashboardControls],
    () =>
      getBookmarkUrlPost(projectId, {
        page: `${CHARTS_URL}`,
        filterString: JSON.stringify({
          filters: [filterValues],
          pageControls: [chartItem.dashboardControls],
        }),
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!projectId && !!chartItem?.dashboardControls,
    },
  )

  const titleRef = React.useRef<HTMLDivElement>(null)

  return (
    <Container>
      <ChartTitle ref={titleRef}>
        {linkData ? (
          <StyledLink
            target={'_blank'}
            to={`${CHARTS_URL}?filters=${linkData}`}
            onClick={() => {
              mixpanel.track('insights feed', {
                action: 'open chart link',
                value: getOutlierTitle(outlier),
                chartId: chartItem.dashboardControls.chartId,
              })
            }}
          >
            {chartItem.title}
          </StyledLink>
        ) : (
          <div>{chartItem.title}</div>
        )}
        {linkData ? (
          <Link
            target={'_blank'}
            to={`${CHARTS_URL}?filters=${linkData}`}
            onClick={() => {
              mixpanel.track('insights feed', {
                action: 'open chart link',
                value: getOutlierTitle(outlier),
                chartId: chartItem.dashboardControls.chartId,
              })
            }}
            style={{
              color: mainText,
              marginTop: -5,
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              marginRight: 10,
            }}
          >
            <YogiButton
              type="dashed"
              style={{ display: 'flex', alignItems: 'center', gap: 5 }}
            >
              <OpenInNewOutlined fontSize={'inherit'} />
            </YogiButton>
          </Link>
        ) : (
          <LoadingOutlined />
        )}
      </ChartTitle>
      <ChartWrapper>
        <Chart
          key={JSON.stringify(filterValues)}
          filters={filterValues}
          chartId={chartItem.dashboardControls.chartId}
          tier1={chartItem.dashboardControls.tier1}
          comparativeIndex={0}
          isComparative={false}
          dashboardControls={chartItem.dashboardControls}
          item={chartItem}
          hideHeader
          priorityItem={outlier.context_with_value?.value}
        />
      </ChartWrapper>
    </Container>
  )
}

export default InsightChart

const StyledLink = styled(Link)`
  color: ${mainText}!important;
`
const Container = styled.div`
  height: 100%;
`

const ChartTitle = styled.div`
  font-weight: 500;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  //align-items: center;
  margin-top: 5px;
`

const ChartWrapper = styled.div`
  height: calc(100% - 30px);
`
