import React, { useState } from 'react'
import styled from 'styled-components'
import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'
import { FilterButton } from 'features/project/features/filters/FilterButton/FilterButton'
import { ChartSettings } from 'features/project/features/Charts/components/ChartSettings/ChartSettings'
import { Breakdown } from 'features/project/features/filters/Header/components/Breakdown/Breakdown'
import { ChartSelector } from 'features/project/features/filters/Header/components/ChartSelector/ChartSelector'
import { DatePanel } from 'features/project/features/filters/features/date-panel/date-panel'
import { SearchButton } from 'features/project/features/filters/SearchButton/SearchButton'
import { CompareMode } from './components/Compare/CompareMode'
import { Tooltip } from 'antd'
import {
  exportCardBackgroundHover,
  lightBlueFontColor,
  secondaryButtonBlue,
} from 'assets/styles/variables'
import { CheckOutlined } from '@ant-design/icons'
import { SuggestedViewsButton } from 'features/project/features/SuggestedViews/SuggestedViewsButton/SuggestedViewsButton'

type Props = {
  activePanel: number
}

export const Header = ({ activePanel }: Props) => {
  // global state
  const route = useProjectStore((state: ProjectState) => state.route)
  const updateDashboardControls = useProjectStore(
    (state: ProjectState) => state.updateDashboardControls,
  )
  const chartData = useProjectStore((state: ProjectState) => state.chartData)
  const dashboardControls = useProjectStore(
    (state: ProjectState) => state.dashboardControls[0],
  )
  const globalFilterValues = useProjectStore((state) => state.filters)
  const comparativePanelsNumber = useProjectStore(
    (state) => state.comparativePanelsNumber,
  )
  const setFilters = useProjectStore((state) => state.setFilters)

  const filterValues = globalFilterValues[activePanel]

  // local state
  const [isFilterPanelVisible, setFilterPanelVisible] = useState(false)

  const chartItem = chartData[0]

  const handleDashboardControls = (key: string, value: any) => {
    updateDashboardControls(0, key, value)
  }

  const isAllFiltersAligned = globalFilterValues.every((filter) => {
    return JSON.stringify(filter) === JSON.stringify(globalFilterValues[0])
  })

  if (!filterValues) {
    return <Wrapper />
  }

  return (
    <Wrapper>
      <Left>
        {route.isCharts && (
          <Breakdown
            chartItem={chartItem}
            tier1={dashboardControls.tier1}
            onChange={(value) => handleDashboardControls('tier1', value)}
          ></Breakdown>
        )}
        <FilterButton
          filterValues={filterValues}
          isFilterPanelVisible={isFilterPanelVisible}
          setFilterPanelVisible={setFilterPanelVisible}
          filterOptionsLoading={false}
          comparativeIndex={activePanel}
        />
        {route.isCharts && (
          <ChartSelector chartItem={chartItem}></ChartSelector>
        )}
        {!route.isKeywords && (
          <SearchButton
            // we want this component to reset when user clicks between compare panels
            key={activePanel}
            filterValues={filterValues}
            comparativeIndex={activePanel}
          ></SearchButton>
        )}
        {comparativePanelsNumber > 1 && (
          <Tooltip title="Copy Current Filters to Other Panels">
            <AlignFiltersButton
              onClick={() => {
                const tempFilterValues = []
                for (let i = 0; i < comparativePanelsNumber; i++) {
                  tempFilterValues.push(filterValues)
                }
                setFilters(tempFilterValues)
              }}
            >
              <div>Align Filters</div>
              {isAllFiltersAligned && <CheckOutlined />}
            </AlignFiltersButton>
          </Tooltip>
        )}
      </Left>
      <Right>
        {route.isCharts && <SuggestedViewsButton></SuggestedViewsButton>}
        <DatePanel comparativeIndex={activePanel} filterValues={filterValues} />
        <CompareMode></CompareMode>
        {route.isCharts && (
          <ChartSettings
            chartItem={chartItem}
            dashboardControls={dashboardControls}
            updateDashboardControls={handleDashboardControls}
          />
        )}
      </Right>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  position: relative;
`

const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`

const Right = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`

const AlignFiltersButton = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 6px 13px;

  font-size: var(--font-size-md);
  font-style: italic;

  border-radius: 10px;
  background: ${secondaryButtonBlue};
  color: ${lightBlueFontColor};

  cursor: pointer;

  white-space: nowrap;

  z-index: 5;

  &:hover {
    background: ${exportCardBackgroundHover};
  }
`
