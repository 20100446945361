import { Select } from 'antd'
import mixpanel from 'features/trackers/mixpanel'
import datasetGraphic from 'assets/images/empty/datasetGraphic.png'
import React, { useMemo } from 'react'
import {
  AppliedFilterOptionSelect,
  FilterOptionSelect,
} from 'features/project/features/filters/types'
import styled from 'styled-components'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { Filters, ProjectState } from 'features/project/types'
import {
  datasetSelectorBackground,
  orangeButton,
  orangeButtonHover,
} from 'assets/styles/variables'
import { DatasetSelector } from 'features/project/features/Home/components/DatasetSelector/DatasetSelector'
import GenericSvgIcon from 'components/GenericSvgIcon/GenericSvgIcon'
import ChartsSvgIcon from 'features/project/features/Charts/components/DatasetSelector/ChartsSvgIcon/ChartsSvgIcon'
import { getFromLS } from 'features/project/utils'

type Props = {
  comparativeIndex: number
  filterValues: Filters
}

export const DatasetSelectorView: React.FC<Props> = ({
  comparativeIndex,
  filterValues,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const updateFilterValues = useProjectStore(
    (state: ProjectState) => state.updateFilterValues,
  )
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )

  const selectedDataset = useMemo(
    () =>
      (
        filterValues?.values.find(
          (el) => el.field === 'proj_uuid',
        ) as AppliedFilterOptionSelect
      )?.values[0] ??
      [
        ...(
          defaultFilterList?.find(
            (el) => el.field === 'proj_uuid',
          ) as FilterOptionSelect
        )?.values,
      ]?.sort((a, b) => b.n_posts - a.n_posts)?.[0].cluster_uuid,
    [filterValues?.values, defaultFilterList],
  )

  const setDataset = (value: string) => {
    updateFilterValues(comparativeIndex, 'proj_uuid', [value])
    mixpanel.track('select dataset', {
      dataset: value,
    })
  }

  return (
    <Container>
      <DatasetWrapper>
        <GenericSvgIcon SvgComponent={ChartsSvgIcon} />
        <DatasetSelector
          dataset={selectedDataset}
          setDataset={setDataset}
          defaultEmpty
          comparativeIndex={comparativeIndex}
        />

        <StartButton onClick={() => setDataset(selectedDataset)}>
          Continue
        </StartButton>
      </DatasetWrapper>
    </Container>
  )
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 10px;
`

const DatasetWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 10%;
  //margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  gap: 40px;
  //min-height: inherit;
  //background: white;
  background: ${datasetSelectorBackground};
  overflow-x: auto;
`

const SelectorWrapper = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  //gap: 20px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: calc(var(--border-radius) * 2);
  background-color: rgba(243, 134, 105, 0.85);
`

const StartButton = styled.div`
  display: flex;
  justify-content: space-between;
  //align-items: center;
  border-radius: 10px;
  background: ${orangeButton};
  cursor: pointer;
  color: white;
  padding: 5px 20px;
  font-size: 14px;
  &:hover {
    background: ${orangeButtonHover};
  }
`
