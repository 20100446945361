import React, { useRef } from 'react'
import styled from 'styled-components'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'
import {
  darkBlue,
  exportCardBackground,
  exportCardBackgroundHover,
  lightBlueFontColor,
  mainText,
} from 'assets/styles/variables'
import { PostsEmpty } from 'components/PostsEmpty'
import _ from 'lodash'
import mixpanel from 'features/trackers/mixpanel'
import { SavedView } from 'features/project/features/saved-vews/types'
import { useQuery } from 'react-query'
import { getSavedViews } from 'features/project/features/saved-vews/model'
import { useSavedViews } from 'features/project/features/saved-vews/utils'

type Props = {}
export const SuggestedViewsDrawer: React.FC<Props> = ({}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const route = useProjectStore((state: ProjectState) => state.route)
  const isSuggestedViewsDrawerOpen = useProjectStore(
    (state: ProjectState) => state.isSuggestedViewsDrawerOpen,
  )
  const setIsSuggestedViewsDrawerOpen = useProjectStore(
    (state: ProjectState) => state.setIsSuggestedViewsDrawerOpen,
  )

  const { updateFilters } = useSavedViews()
  const ref = useRef<HTMLDivElement>(null)

  const { data: savedViews } = useQuery(
    ['saved-views', projectId],
    () => getSavedViews(projectId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )

  const isOpen = isSuggestedViewsDrawerOpen

  const allCategories: string[] = []
  const categories: string[] = []

  const suggestedViewsObj: Record<string, SavedView[]> = {}

  savedViews?.forEach((view) => {
    if (view.view_type) {
      if (!suggestedViewsObj[view.view_type]) {
        suggestedViewsObj[view.view_type] = []
        categories.push(view.view_type)
      }
      suggestedViewsObj[view.view_type].push(view)
    }
  })
  savedViews?.forEach((view) => {
    if (view.view_type) {
      if (!allCategories.includes(view.view_type)) {
        allCategories.push(view.view_type)
      }
    }
  })

  const otherViews = savedViews?.filter((view) => !view.author) ?? []

  return (
    <>
      <OverlayContainer
        isOpen={isOpen}
        isHome={route.isCompass}
        onClick={() => {
          setIsSuggestedViewsDrawerOpen(false)
        }}
      ></OverlayContainer>
      <Drawer ref={ref} isOpen={isOpen}>
        <Container>
          <Title style={{ marginBottom: '15px' }}>Suggested Views</Title>
          {(!otherViews.length ||
            Object.keys(suggestedViewsObj).length === 0) && (
            <EmptyContainer>
              <PostsEmpty>No Suggested Views...yet!</PostsEmpty>
            </EmptyContainer>
          )}
          <SuggestedContainer>
            {categories.map((el) => {
              return (
                <SuggestedSection>
                  <SectionHeader>
                    {_.startCase(_.camelCase(el)).replaceAll(' And ', ' & ')}
                  </SectionHeader>
                  <QuestionList>
                    {suggestedViewsObj[el].map((el, index) => (
                      <QuestionListItem key={index}>
                        <a
                          style={{
                            textDecoration: 'none',
                            color: mainText,
                          }}
                          href={`${window.location.origin}/project/${projectId}/${el.page}${el.search_url}`}
                          onClick={(e) => {
                            e.preventDefault()
                          }}
                        >
                          <QuestionCard
                            onClick={() => {
                              mixpanel.track('home saved view', {
                                action: 'load',
                                type: 'project',
                                value: el.name,
                              })
                              updateFilters(el)
                              setIsSuggestedViewsDrawerOpen(false)
                            }}
                          >
                            <QuestionHeader>
                              {/*<Compare style={{ color: secondaryRed }} />*/}

                              {/*<Icon>{suggestedIcons[el?.page || 'default']}</Icon>*/}
                              {/*<Icon>*/}
                              {/*  {suggestedIcons[el?.page || 'default']}*/}
                              {/*</Icon>*/}
                              <QuestionTitle>{el.name}</QuestionTitle>
                            </QuestionHeader>
                            {/*<QuestionText>{el.description}</QuestionText>*/}
                          </QuestionCard>
                        </a>
                      </QuestionListItem>
                    ))}
                  </QuestionList>
                </SuggestedSection>
              )
            })}
          </SuggestedContainer>
        </Container>
      </Drawer>
    </>
  )
}

const OverlayContainer = styled.div<{ isOpen: boolean; isHome: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  z-index: ${(props) => (props.isOpen ? 200 : -1)};
  height: 100vh;
  width: 100vw;
  transition: background-color 0.2s ease-in-out;
  background: ${(props) => (props.isOpen ? 'rgba(0, 0, 0, 0.2)' : 'none')};
`

const Drawer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  z-index: 201;
  height: 100vh;
  width: 655px;
  background: white;
  right: 0;
  top: 0;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  box-shadow: ${(props) =>
    props.isOpen
      ? '0px 0px 100px -20px rgba(50, 50, 93, 0.25), -10px 0px 60px -30px rgba(0, 0, 0, 0.3)'
      : 'none'};

  transform: ${(props) =>
    props.isOpen ? 'translateX(0%)' : 'translateX(100%)'};
  transition: 0.2s ease-in-out;
`

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 47px 20px 20px 28px;
  overflow: auto;
`

const Title = styled.div`
  font-size: 22px;
  font-weight: 600;
  margin-left: 16px;
`

const SectionHeader = styled.div`
  color: ${lightBlueFontColor};
  font-size: 14px;
  margin-bottom: 21px;
  margin-left: 16px;
`

const SuggestedContainer = styled.div`
  width: 100%;
  padding-right: 10px;
`
const SuggestedSection = styled.div`
  width: 100%;
  margin-bottom: 10px;
`

const QuestionList = styled.div`
  width: 100%;
  height: 450px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
`
const QuestionListItem = styled.div`
  width: 50%;
`
const QuestionCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: var(--font-size-xl);

  background: ${exportCardBackground};
  color: ${darkBlue};
  cursor: pointer;

  padding: 31px 25px 30px 30px;
  border-radius: 10px;

  //transition: all 0.2s ease;

  &:hover {
    font-weight: 600;
      padding-right:20px;
    background: ${exportCardBackgroundHover};
    color: ${lightBlueFontColor};
`
const QuestionHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`
const QuestionTitle = styled.div`
  display: flex;
  flex-basis: 90%;
`
const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: -15px;
`
