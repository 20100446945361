import { Checkbox, Tooltip } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { BarChart, ChartLegend as ChartLegendType, UnionChart } from '../types'
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons'
import _ from 'lodash'
import mixpanel from '../../../../trackers/mixpanel'
import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'
import YogiDivider from '../../../../../components/UI/YogiDivider'
import { ChartTypes } from '../utils'
import { disabledLegendItemColor } from 'assets/styles/variables'

type ChartLegendProps = {
  legend: Array<ChartLegendType>
  setHighlightedLegendItems?: (v: any) => void
  disabledLegendItems?: string[]
  setDisabledLegendItems?: (v: string[]) => void
  change?: (items: Array<ChartLegendType>) => void
  isHorizontal?: boolean
  expandedCategories: string[]
  currentChart: UnionChart
  updateDashboardControls: (field: string, value: any) => void
  tier1: string
  showCategories: boolean | undefined
  disableGrouping: boolean | undefined
}

const blockedValues = ['Fans / Attractors', 'Critics / Detractors']

export const ChartLegend: React.FC<ChartLegendProps> = React.memo(
  ({
    legend,
    isHorizontal,
    setHighlightedLegendItems,
    disabledLegendItems,
    setDisabledLegendItems,
    expandedCategories,
    currentChart,
    updateDashboardControls,
    tier1,
    showCategories,
    disableGrouping,
  }) => {
    const route = useProjectStore((state: ProjectState) => state.route)

    const [highlightData, setHighlightData] = useState<ChartLegendType[]>([])
    const [selectedItems, setSelectedItems] = useState<string[]>([])

    const isExpandCategoryType =
      currentChart.chart_type === ChartTypes.BAR ||
      currentChart.chart_type === ChartTypes.STACK_BAR ||
      currentChart.chart_type === ChartTypes.MATRIX

    const onChange = (changedItem: ChartLegendType) => {
      if (setDisabledLegendItems) {
        setDisabledLegendItems(
          disabledLegendItems?.includes(changedItem.label)
            ? [...disabledLegendItems?.filter((i) => i !== changedItem.label)]
            : [...(disabledLegendItems || []), changedItem.label],
        )
      }
      setHighlightData((prev) =>
        prev.filter((el) => el.color !== changedItem.color),
      )
    }

    const handleItemHover = useCallback(
      (item: ChartLegendType, isHovered: boolean) => {
        setHighlightData((prev) => {
          if (isHovered) {
            return [
              ...prev.filter((el) => el.label !== item.label),
              { ...item, hovered: true },
            ]
          } else {
            return prev.filter((el) => {
              return (
                el.label !== item.label || selectedItems.includes(item.label)
              )
            })
          }
        })
      },
      [selectedItems],
    )

    // can we memo this?
    useEffect(() => {
      if (setHighlightedLegendItems) {
        setHighlightedLegendItems(highlightData)
      }
    }, [highlightData.length])

    const parentsOnly = legend.filter((el) => el.children)

    // its fine
    const legendObj: any = {}
    parentsOnly.forEach((el) => {
      legendObj[el.label] = legend
        .filter(
          (item) =>
            //@ts-ignore
            currentChart.category_mapping?.[el.label]?.includes(item.label) ||
            item.label === el.label ||
            //@ts-ignore
            (currentChart.category_mapping?.[el.label]?.includes(
              item.label.split('Volume')[0].trim(),
            ) &&
              item.label.includes('Volume')),
        )
        .sort((a, b) => (expandedCategories.includes(a.label) ? -1 : 1))
        .concat(legend.filter((item) => item.label === el.label + ' Volume'))
    })

    let resortedLegend: any[] = legend
    if (expandedCategories?.length && parentsOnly?.length) {
      resortedLegend = []
      Object.keys(legendObj).forEach((key) => {
        resortedLegend = resortedLegend.concat(legendObj[key])
      })
    }

    const groupItems =
      (tier1 === 'Brand' || tier1 === 'Product') &&
      currentChart.chart_type !== ChartTypes.MATRIX
        ? showCategories
        : true

    let expandableCategories: string[] = []
    if ((currentChart as BarChart).category_mapping && groupItems) {
      Object.keys((currentChart as BarChart).category_mapping).forEach(
        (key) => {
          if (
            (((currentChart as BarChart).category_mapping[key].length > 0 &&
              tier1 !== 'Theme') ||
              ((currentChart as BarChart).category_mapping[key].length > 1 &&
                tier1 === 'Theme')) &&
            key !== 'Fans / Attractors' &&
            key !== 'Critics / Detractors'
            // !expandedCategories.includes(key)
          ) {
            if (currentChart.chart_type === ChartTypes.MATRIX) {
              expandableCategories.push(key.split(' - ')[0].trim())
            } else {
              expandableCategories.push(key)
            }
          }
        },
      )
    }
    expandableCategories = _.uniq(expandableCategories)

    const highlightedLabels = highlightData.map((el) => el.label)

    // different default setting for theme vs product and brand
    // Matrix only ever has Themes grouped, but can be viewed in Brand/Product lens,
    // so we treat all matrix as if tier1 = Theme
    const reactiveShowCategories =
      showCategories === undefined
        ? tier1 === 'Theme' || currentChart.chart_type === ChartTypes.MATRIX
        : showCategories

    const getGroupingLabel = () => {
      // we only support one dimension of grouping (themes) on charts with two lens dimensions
      if (
        currentChart.chart_type === ChartTypes.MATRIX ||
        (currentChart.chart_id.split('_')[0] === '9' &&
          currentChart.chart_id.split('_')[3] === 'categories' &&
          currentChart.other_charts?.length > 0)
      ) {
        return 'Group Themes'
      } else {
        return `Group ${tier1 ?? 'Brand'}s`
      }
    }

    return (
      <Wrapper>
        {!disableGrouping && (
          <>
            {currentChart.category_mapping && (
              <div
                style={{
                  width: '100%',
                }}
              >
                <div
                  style={{
                    marginBottom: 5,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Checkbox
                    checked={reactiveShowCategories}
                    onChange={(event) => {
                      mixpanel.track('chart config', {
                        action: 'group',
                        value: event.target.checked,
                        ...route,
                      })
                      updateDashboardControls(
                        'showCategories',
                        event.target.checked,
                      )
                    }}
                  >
                    {getGroupingLabel()}
                  </Checkbox>
                </div>
                <YogiDivider style={{ marginBottom: 5 }} />
              </div>
            )}
            {isExpandCategoryType &&
              !!expandableCategories.length &&
              reactiveShowCategories && (
                <Content>
                  {/*<YogiDivider style={{ marginBottom: 5 }} />*/}
                  <ReviewsControlTitle>
                    Expand {(currentChart as BarChart).x_title} Groups:
                  </ReviewsControlTitle>

                  <YogiDivider style={{ margin: '5px 0px' }} />
                  <Checkbox
                    checked={
                      expandedCategories?.length === expandableCategories.length
                    }
                    indeterminate={
                      expandedCategories?.length <
                        expandableCategories.length &&
                      expandedCategories?.length > 0
                    }
                    onChange={(event) => {
                      mixpanel.track('chart config', {
                        action: 'openCategory',
                        value: event.target.checked,
                        ...route,
                      })
                      if (event.target.checked) {
                        updateDashboardControls(
                          'expandedCategories',
                          expandableCategories,
                        )
                      } else {
                        updateDashboardControls('expandedCategories', [])
                      }
                    }}
                  >
                    Select All
                  </Checkbox>
                  <YogiDivider style={{ margin: '5px 0px' }} />
                  {expandableCategories.sort().map((item: any) => (
                    <Tooltip
                      title={(currentChart as BarChart).category_mapping[
                        item
                      ]?.map((el) => (
                        <div>- {el.split(':')[1]?.trim() ?? el}</div>
                      ))}
                      placement={'left'}
                    >
                      <Checkbox
                        checked={expandedCategories?.includes(item)}
                        onChange={(event) => {
                          mixpanel.track('chart config', {
                            action: 'openCategory',
                            value: event.target.checked,
                            ...route,
                          })
                          updateDashboardControls(
                            'expandedCategories',
                            expandedCategories?.includes(item)
                              ? expandedCategories?.filter(
                                  (i: any) => i !== item,
                                )
                              : [...(expandedCategories || []), item],
                          )
                        }}
                      >
                        {item}
                      </Checkbox>
                    </Tooltip>
                  ))}
                  <YogiDivider style={{ margin: '5px 0px' }} />
                </Content>
              )}
          </>
        )}

        {resortedLegend.map((item, index) => (
          <Tooltip
            title={
              !blockedValues.includes(item.label) && item.children?.length > 1
                ? item.children?.map(({ label }: any) => (
                    <div>- {label.split(':')[1]?.trim() ?? label}</div>
                  ))
                : ''
            }
            placement={'left'}
          >
            <ItemWrapper
              key={item.label}
              onClick={() => onChange(item)}
              onMouseEnter={() => handleItemHover(item, true)}
              onMouseLeave={() => handleItemHover(item, false)}
              style={{
                width: !isHorizontal ? '100%' : '',
                paddingLeft:
                  !isExpandCategoryType &&
                  showCategories &&
                  // @ts-ignore
                  currentChart.category_mapping &&
                  // @ts-ignore
                  !currentChart.category_mapping[item.label] &&
                  // @ts-ignore
                  !currentChart.category_mapping[
                    item.label.split('Volume')[0].trim()
                  ]
                    ? '17px'
                    : '',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '5px',
                  gap: 5,
                }}
              >
                <StyledCheckbox
                  color={item.color}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                  onChange={(e) => {
                    const isChecked = e.target.checked
                    if (isChecked) {
                      setSelectedItems((prev) => [...prev, item.label])
                    } else {
                      setSelectedItems((prev) =>
                        prev.filter((el) => el !== item.label),
                      )
                    }
                  }}
                />
                <Text
                  style={{
                    color: item.disabled ? disabledLegendItemColor : 'inherit',
                    fontWeight: highlightedLabels.includes(item.label)
                      ? 600
                      : 'normal',
                  }}
                >
                  {item.label}
                </Text>
              </div>
              {!blockedValues.includes(item.label) &&
                item.children &&
                ((item.children.length > 0 && tier1 !== 'Theme') ||
                  (item.children.length > 1 && tier1 === 'Theme')) && (
                  <div>
                    <Caret
                      onClick={() => {
                        updateDashboardControls(
                          'expandedCategories',
                          expandedCategories?.includes(item.label)
                            ? expandedCategories?.filter(
                                (el) => el !== item.label,
                              )
                            : [...(expandedCategories ?? []), item.label],
                        )
                        // if we are closing the caret, we want any children
                        // in the 'selected' state to be removed from the list
                        if (expandedCategories.includes(item.label)) {
                          setSelectedItems((prev) =>
                            prev.filter((el) =>
                              item.children
                                .map((child: any) => child.value)
                                .includes(el),
                            ),
                          )
                          handleItemHover(item, false)
                        }
                      }}
                      style={{
                        color: item.disabled
                          ? disabledLegendItemColor
                          : 'inherit',
                      }}
                    >
                      {expandedCategories?.includes(item.label) ? (
                        <CaretUpFilled />
                      ) : (
                        <CaretDownFilled />
                      )}
                    </Caret>
                  </div>
                )}
            </ItemWrapper>
          </Tooltip>
        ))}
      </Wrapper>
    )
  },
)

ChartLegend.displayName = 'ChartLegend'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  flex-wrap: wrap;
  height: fit-content;
  width: fit-content;
  max-width: fit-content;
  max-width: 100%;
`
const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  //margin-right: 10px;
  cursor: pointer;
  margin-bottom: 8px;
`
const Color = styled.div`
  //min-width: 20px;
  width: 15px;
  height: 15px;
`

const Text = styled.div`
  //&:hover {
  //  font-weight: 600;
  //}
`

const StyledCheckbox = styled(Checkbox)<{ color: string }>`
  .ant-checkbox-inner {
    background-color: ${(props) => props.color};
    border-radius: 1px;
    border-width: 0;
  }
`

const Caret = styled.div`
  display: flex;
  padding-right: 2px;
  cursor: pointer;
`
const ChildWrapper = styled.div`
  width: 100%;
  margin-left: 15px;
`
const ReviewsControlTitle = styled.div`
  //margin-bottom: 6px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .ant-select {
    .ant-select-selector {
      height: 40px;
      padding: 5px 10px;
    }
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }
`
