import { TIMESERIES_TYPE_NAME } from '../../../../constants'
import moment from 'moment/moment'
import { OutlierType } from '../../../../model'
import React from 'react'

type Props = {
  outlier: OutlierType
}

const QUARTER_COMPARISON_PERIOD_COUNT = 4
const DEFAULT_COMPARISON_PERIOD_COUNT = 6

const InsightSubtitle: React.FC<Props> = ({ outlier }) => {
  const getPreviousPeriodLabel = () => {
    if (outlier.aggregation === 'quarter') {
      return `the ${QUARTER_COMPARISON_PERIOD_COUNT} ${outlier.aggregation}s prior`
    }
    return `the ${DEFAULT_COMPARISON_PERIOD_COUNT} ${outlier.aggregation}s prior`
  }
  const getMetricLabel = () => {
    if (outlier.metric === 'score') {
      return 'rating'
    }
    return outlier.metric
  }
  if (outlier.type === TIMESERIES_TYPE_NAME) {
    return (
      <div>
        In the period between{' '}
        {outlier.date ? new Date(outlier.date).toDateString() : ''} -{' '}
        {moment(outlier.date)
          // @ts-ignore
          .add(1, outlier.aggregation)
          .toDate()
          .toDateString()}
        , the average {getMetricLabel()} for the selected data was{' '}
        {outlier.metric_score.toFixed(2)}, while the average {getMetricLabel()}{' '}
        of {getPreviousPeriodLabel()} was {outlier.mean_metric_score.toFixed(2)}
        .
      </div>
    )
  } else {
    return (
      <div>
        In the theme of <span style={{ fontWeight: 500 }}>{outlier.theme}</span>
        , {outlier.context_with_value?.value} has an{' '}
        <i>
          <u>
            average {getMetricLabel()} of {outlier.metric_score.toFixed(2)}
          </u>
        </i>
        , while the{' '}
        <i>
          <u>
            average {getMetricLabel()} of all{' '}
            {outlier.context_with_value?.context}s is{' '}
            {outlier.mean_metric_score.toFixed(2)}
          </u>
        </i>
        .
      </div>
    )
  }
}

export default InsightSubtitle
