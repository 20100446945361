import React, { useContext, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useProjectStore } from '../../projectStore/projectStore'
import { ProjectState } from '../../types'
import { Loader } from 'shared/components'
import { Navigation } from './components/Navigation/Navigation'
import { useFeatureFlags } from 'features/project/hooks/useFeatureFlags'
import { Settings } from './components/Settings/Settings'
import { pageBackgroundGrey } from 'assets/styles/variables'
import {
  AppliedFilterOptionSelect,
  FilterOptionSelect,
} from 'features/project/features/filters/types'
import { YogiButton } from 'components/UI/YogiButton'
import { toast } from 'react-toastify'
import { AuthContext } from 'features/auth'

type Props = { children: React.ReactNode }

export const ProjectLayout: React.FC<Props> = ({ children }) => {
  const details = useProjectStore((state: ProjectState) => state.details)
  const route = useProjectStore((state: ProjectState) => state.route)
  const filters = useProjectStore((state: ProjectState) => state.filters)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )
  const settings = details?.clientSettings?.project?.pages

  const { data: feature_flags } = useFeatureFlags()
  const authContext = useContext(AuthContext)

  const datasets = Array.from(
    new Set(
      filters.flatMap((filter) =>
        filter.values
          .filter((value) => value.field === 'proj_uuid')
          .flatMap(
            (value) => (value as AppliedFilterOptionSelect).values as string[],
          ),
      ),
    ),
  )

  const datasetName = useMemo(() => {
    if (!datasets?.length) {
      return ''
    }
    if (datasets.length === 1) {
      return (
        defaultFilterList.find(
          (el) => el.field === 'proj_uuid',
        ) as FilterOptionSelect
      )?.values.find((el) => el.cluster_uuid === datasets[0])?.value

      // datasets[0]
    } else {
      return 'Multiple Datasets'
    }
  }, [datasets, defaultFilterList])

  useEffect(() => {
    if ((window as any).$crisp) {
      ;(window as any).$crisp?.push(['do', 'chat:hide'])
    }
  }, [])
  ;(window as any).$crisp?.push([
    'on',
    'chat:closed',
    () => {
      ;(window as any).$crisp?.push(['do', 'chat:hide'])
    },
  ])

  if (!details || !settings || !feature_flags) return <Loader />

  return (
    <Wrapper>
      <NavBar>
        <Navigation />
        <Settings />
      </NavBar>
      <Content>
        {feature_flags?.copy_share_link && (
          <ShareButton>
            <YogiButton
              type={'primary'}
              style={{
                padding: '0px 8px',
                height: '29px',
                marginRight: '0px',
              }}
              onClick={() => {
                const searchParams = new URLSearchParams(location.search)
                searchParams.set('share_type', 'insights')
                searchParams.set('share_user', authContext.user.username)
                const url =
                  'https://app.meetyogi.com' +
                  window.location.pathname +
                  '?' +
                  searchParams.toString()
                navigator.clipboard
                  .writeText(url)
                  .then(() => toast.info('Copied to clipboard'))
              }}
            >
              Copy Share Link
            </YogiButton>
          </ShareButton>
        )}
        {!route.isCompass && (
          <Header>
            <HeaderText>
              {!route.isInsights && (
                <>
                  <ProjectTitle>
                    {details.title_override || details.title}
                  </ProjectTitle>
                  <DatasetTitle>{datasetName ?? ''}</DatasetTitle>
                </>
              )}
            </HeaderText>
          </Header>
        )}
        {children}
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  //position: relative;
  overflow: hidden;
  display: flex;
  height: 100vh;
  background: ${pageBackgroundGrey};
`

const NavBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`

const Content = styled.div`
  width: 100%;
  max-width: calc(100vw - 140px);
  margin-top: 35px;
`

const Header = styled.div`
  margin-left: 5px;
  height: 20px;
  position: relative;
`
const HeaderText = styled.div`
  display: flex;
  gap: 10px;
  top: -5px;
  position: absolute;
`

const ProjectTitle = styled.div`
  font-size: var(--font-size-md);
  font-weight: 600;
  height: 20px;
`

const DatasetTitle = styled.div`
  font-size: var(--font-size-md);
  font-weight: 600;
  height: 20px;
  color: #bcbfc7;
`

const ShareButton = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
`
