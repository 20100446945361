import { Filters, ProjectState } from 'features/project/types'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import mixpanel from 'features/trackers/mixpanel'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'

const SEMANTIC_LABEL = 'SEMANTIC'

type Props = {
  filterValues: Filters
  comparativeIndex: number
  updateLocalFilterValues?: (filterValues: Filters) => void
  closePanel: () => void
  setIsFilterUnapplied?: (isFilterUnapplied: boolean) => void
  isKeywordsOnly?: boolean
}

export const useSearchPanel = ({
  filterValues,
  comparativeIndex,
  updateLocalFilterValues,
  closePanel,
  setIsFilterUnapplied,
  isKeywordsOnly,
}: Props) => {
  const route = useProjectStore((state: ProjectState) => state.route)
  const setSearchQuery = useProjectStore(
    (state: ProjectState) => state.setSearchQuery,
  )
  const setSearchCondition = useProjectStore(
    (state: ProjectState) => state.setSearchCondition,
  )
  const updateSelectedOptionField = useProjectStore(
    (state: ProjectState) => state.updateSelectedOptionField,
  )
  const clearOpenedOptions = useProjectStore(
    (state: ProjectState) => state.clearOpenedOptions,
  )

  // local state
  const [isSemantic, setIsSemantic] = useState(
    !isKeywordsOnly &&
      (!filterValues.searchCondition.length ||
        filterValues.searchCondition.includes(SEMANTIC_LABEL)),
  )
  const [value, setValue] = useState<string>(
    isSemantic ? (filterValues?.searchQuery?.[0] ?? '') : '',
  )
  let [words, setWords] = useState<string[]>(
    isSemantic ? [] : filterValues.searchQuery,
  )
  const [joinValue, setJoinValue] = useState(
    filterValues.searchCondition.filter((el) => el !== SEMANTIC_LABEL)[0],
  )
  const [not, setNot] = useState(filterValues.searchCondition.includes('not'))

  // functions
  const setSearchQueryAndCondition = (
    newQuery: string[],
    newCondition: string[],
    comparativeIndex: number,
  ) => {
    if (updateLocalFilterValues) {
      updateLocalFilterValues({
        ...filterValues,
        searchQuery: newQuery,
        searchCondition: newCondition,
      })
    } else {
      setSearchQuery(comparativeIndex, newQuery)
      setSearchCondition(comparativeIndex, newCondition)
    }
  }

  const closeGlobalPanel = () => {
    if (!updateLocalFilterValues) {
      updateSelectedOptionField(comparativeIndex, '')
      clearOpenedOptions(comparativeIndex)
      closePanel()
    }
  }

  const handleSemanticSearch = () => {
    const searchCondition = [SEMANTIC_LABEL]
    setSearchQueryAndCondition([value], searchCondition, comparativeIndex)

    mixpanel.track('filter', {
      action: 'search',
      type: 'semantic',
      search_terms: words,
      search_criteria: searchCondition,
      n_terms: words.length,
      ...route,
    })

    closeGlobalPanel()
  }

  // apply the search inputs and conditions to the filter
  const handleSearch = () => {
    // if there is an unsubmitted value, add it to the list of words
    if (value.trim()) {
      words = Array.from(new Set([...words, value]))
      setValue('')
    }
    const searchCondition = joinValue ? [joinValue] : ['or']

    setSearchQueryAndCondition(words, searchCondition, comparativeIndex)

    mixpanel.track('filter', {
      action: 'search',
      search_terms: words,
      search_criteria: searchCondition,
      n_terms: words.length,
      ...route,
    })

    closeGlobalPanel()
  }

  const handleClear = () => {
    setSearchQueryAndCondition([], [], comparativeIndex)
    setValue('')

    closeGlobalPanel()
  }

  const handleSemanticPressEnter = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (value.length) {
      handleSemanticSearch()
    } else {
      handleClear()
    }
    e.stopPropagation()
    e.preventDefault()
  }

  const handleClassicPressEnter = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (value.length) {
      setWords(Array.from(new Set([...words, value])))
      setValue('')
    }
    e.stopPropagation()
    e.preventDefault()
  }

  const handleClassicKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.metaKey) {
      const joinedWords = (words || []).join(',')

      switch (event.key) {
        case 'c':
          await navigator.clipboard.writeText(joinedWords)
          break

        case 'x':
          await navigator.clipboard.writeText(joinedWords)
          setWords([])
          break

        case 'v':
          const result = await navigator.clipboard.readText()
          setWords(Array.from(new Set(words.concat(result.split(',')))))
          setValue('')
          break
      }
    }
  }

  const handleSetIsSemantic = () => {
    setIsSemantic(true)
  }

  const handleSetIsNotSemantic = () => {
    setIsSemantic(false)
  }

  const handleSetValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const handleSetJoin = (value: string) => {
    setJoinValue(value)
  }

  const handleSetNot = (e: CheckboxChangeEvent) => {
    setNot(e.target.checked)
  }

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText((words || []).join(','))
    toast.info('Copied to clipboard')
  }

  // effects
  useEffect(() => {
    const isEqual = _.isEqual(filterValues.searchQuery, words) && !value.length
    setIsFilterUnapplied && setIsFilterUnapplied(!isEqual)
  }, [filterValues.searchQuery, words, value])

  return {
    isSemantic,
    setIsSemantic,
    value,
    words,
    joinValue,
    not,
    handleSemanticPressEnter,
    handleClassicPressEnter,
    handleClassicKeyDown,
    handleSetIsSemantic,
    handleSetIsNotSemantic,
    handleSetValue,
    handleSetJoin,
    handleSetNot,
    handleSearch,
    handleClear,
    handleCopyToClipboard,
    handleSemanticSearch,
    setWords,
  }
}
