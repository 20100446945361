import ChartsSvgIcon from 'features/project/partials/ProjectLayout/components/Navigation/components/ChartsSvgIcon/ChartsSvgIcon'
import {
  ALERTS_URL,
  AUTO_INSIGHTS_URL,
  CATALOG_URL,
  CHARTS_URL,
  DASHBOARD_URL,
  FEEDBACK_URL,
  INSIGHTS_URL,
  KEYWORDS_URL,
  PDP_URL,
  RECOMMENDATIONS_URL,
  SAVED_VIEWS_URL,
} from 'features/project/utils'
import DashboardsSvgIcon from 'features/project/partials/ProjectLayout/components/Navigation/components/DashboardsSvgIcon/DashboardsSvgIcon'
import AutoInsightsSvgIcon from 'features/project/partials/ProjectLayout/components/Navigation/components/AutoInsightsSvgIcon/AutoInsightsSvgIcon'
import FeedbackSvgIcon from 'features/project/partials/ProjectLayout/components/Navigation/components/FeedbackSvgIcon/FeedbackSvgIcon'
import KeywordsSvgIcon from 'features/project/partials/ProjectLayout/components/Navigation/components/KeywordsSvgIcon/KeywordsSvgIcon'
import CatalogSvgIcon from 'features/project/partials/ProjectLayout/components/Navigation/components/CatalogSvgIcon/CatalogSvgIcon'
import PDPInsightsSvgIcon from 'features/project/partials/ProjectLayout/components/Navigation/components/PDPInsightsSvgIcon/PDPInsightsSvgIcon'
import AlertsSvgIcon from 'features/project/partials/ProjectLayout/components/Navigation/components/AlertsSvgIcon/AlertsSvgIcon'
import { FeatureFlagsResponse } from 'features/url-params/model'
import SavedSvgIcon from 'features/project/partials/ProjectLayout/components/Navigation/components/SavedSvgIcon/SavedSvgIcon'
import { BulbOutlined } from '@ant-design/icons'
import React from 'react'

type NavigationItem = {
  title: string
  svgComponent: React.FC
  url: string
  enabled: boolean
}

type Props = {
  feature_flags: FeatureFlagsResponse | undefined
  pageSettings: any
  isYogiUser: boolean
}

export const getNavigationItems = ({
  feature_flags,
  pageSettings,
  isYogiUser,
}: Props): NavigationItem[] => [
  {
    title: pageSettings?.dashboard?.alias?.toLowerCase() || 'charts',
    svgComponent: ChartsSvgIcon,
    url: CHARTS_URL,
    enabled: !pageSettings.dashboard.hidden,
  },
  {
    title:
      pageSettings?.custom_dashboards?.alias?.toLowerCase() || 'dashboards',
    svgComponent: DashboardsSvgIcon,
    url: DASHBOARD_URL,
    enabled: !pageSettings.custom_dashboards?.hidden,
  },
  {
    title: pageSettings?.auto_insights?.alias?.toLowerCase() || 'insights feed',
    svgComponent: AutoInsightsSvgIcon,
    url: INSIGHTS_URL,
    enabled:
      !pageSettings.auto_insights?.hidden &&
      (!!feature_flags?.automated_insights || isYogiUser),
  },
  {
    title: pageSettings?.feedback?.alias?.toLowerCase() || 'feedback',
    svgComponent: FeedbackSvgIcon,
    url: FEEDBACK_URL,
    enabled: !pageSettings.feedback?.hidden,
  },
  {
    title: pageSettings?.keywords?.alias?.toLowerCase() || 'keywords',
    svgComponent: KeywordsSvgIcon,
    url: KEYWORDS_URL,
    enabled: !pageSettings.keywords?.hidden,
  },
  {
    title: pageSettings?.catalog?.alias?.toLowerCase() || 'catalog',
    svgComponent: CatalogSvgIcon,
    url: CATALOG_URL,
    enabled: !pageSettings.catalog?.hidden,
  },
  {
    title: pageSettings?.pdp?.alias?.toLowerCase() || 'PDP insights',
    svgComponent: PDPInsightsSvgIcon,
    url: PDP_URL,
    enabled: !pageSettings.pdp?.hidden,
  },
  {
    title: pageSettings?.alerts?.alias?.toLowerCase() || 'alerts',
    svgComponent: AlertsSvgIcon,
    url: ALERTS_URL,
    enabled: !pageSettings.alerts?.hidden,
  },
  {
    title: pageSettings?.saved_views?.alias?.toLowerCase() || 'saved',
    svgComponent: SavedSvgIcon,
    url: SAVED_VIEWS_URL,
    enabled: !pageSettings.saved_views?.hidden,
  },
  {
    title: pageSettings?.recommendations?.alias?.toLowerCase() || 'saved',
    svgComponent: BulbOutlined,
    url: RECOMMENDATIONS_URL,
    enabled: !pageSettings.recommendations?.hidden,
  },
]
