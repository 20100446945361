import CatalogListItem from 'features/project/features/catalog/Components/CatalogList/components/CatalogListItem/CatalogListItem'
import { VirtuosoGrid } from 'react-virtuoso'
import React from 'react'
import { Grid } from '@mui/material'
import { FocusListOption } from '../../CatalogList'
import styled from 'styled-components'

type Props = {
  focusListData: FocusListOption[]
  selectedLens: string | null
  selectedFocus: string | null
  setSelectedFocus: (value: string) => void
  tabIndex: number
  setTabIndex: (value: number) => void
}

const VirtuosoGridComponent: React.FC<Props> = ({
  focusListData,
  selectedLens,
  selectedFocus,
  setSelectedFocus,
  tabIndex,
  setTabIndex,
}: any) => {
  const GridItem = ({ children, ...props }: any) => {
    const focus = children
    if (!focus) return <div>Loading</div>
    return (
      <ListItemWrapper {...props}>
        <CatalogListItem
          key={`${selectedLens}_${focus.value}_item`}
          item={focus}
          category={selectedLens}
          title={focus.value}
          reviewCount={focus.n_posts}
          identifiers={focus.identifiers}
          productCount={focus.product_count}
          imageUrl={focus.image_url}
          onSelect={setSelectedFocus}
          selectedItem={selectedFocus}
        />
      </ListItemWrapper>
    )
  }

  if (focusListData.length < 5000) {
    return (
      <FocusListWrapper>
        {focusListData?.map((focus: FocusListOption, index: number) => (
          <CatalogListItem
            key={`${selectedLens}_${focus.value}_item`}
            item={focus}
            category={selectedLens}
            title={focus.value}
            reviewCount={focus.n_posts}
            identifiers={focus.identifiers}
            productCount={focus.product_count}
            imageUrl={focus.image_url}
            onSelect={setSelectedFocus}
            selectedItem={selectedFocus}
          />
        ))}
      </FocusListWrapper>
    )
  }

  return (
    <VirtuosoGrid
      totalCount={focusListData.length}
      overscan={200}
      components={{
        Item: GridItem,
        List: React.forwardRef(({ style, children }, listRef) => (
          <div
            ref={listRef}
            style={{
              ...style,
              display: 'flex',
              flexWrap: 'wrap',
              gap: 16,
              // gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
              // gap: '16px',
              padding: 20,
              // marginTop: -30,
            }}
          >
            {children}
          </div>
        )),
      }}
      itemContent={(index) => focusListData[index]}
      style={{ height: '100%', width: '100%' }}
    />
  )
}

export default VirtuosoGridComponent

const ListItemWrapper = styled.div`
  //flex-basis: 100%;

  height: 150px;
  flex-grow: 1;

  @media (max-width: 1200px) {
    flex-basis: 40%;
  }
  @media (max-width: 900px) {
    flex-basis: 100%;
  }
`

const FocusListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
