import { create } from 'zustand'
import { produce } from 'immer'
import { ExportData, Filters, ProjectDetails, ProjectState } from '../types'
import {
  CountriesResponse,
  DashboardChartResponse,
  UnionChart,
} from 'features/project/features/Charts/types'
import _ from 'lodash'
import { enableMapSet } from 'immer'
import { detailsDefault } from './defaults'
import {
  AppliedFilterOption,
  AppliedFilterOptionSelect,
  FilterOption,
} from '../features/filters/types'
import {
  formatDateToUTCEndOfDay,
  formatDateToUTCMidnight,
} from '../features/filters/helpers'
import { NotificationsResponse } from '../features/notifications/model'
import moment from 'moment/moment'
import { DateRange } from '../features/dashboard/components/Dashboard/components/DashboardDateSelect'
import {
  mergeObjects,
  removeAtIndex,
} from 'features/project/projectStore/utils'
import { DEFAULT_CHART_FONT_SIZE } from 'features/project/utils'
import { boolean } from 'yup'

enableMapSet()

export const defaultDashboardControls = {
  chartId: null,
  tier1: null,
  tier2: null,
  tier3: null,
  legend: true,
  fullAxis: true,
  hasLabels: true,
  hasOverlay: false,
  treeView: true,
  origin: true,
  labels: true,
  trendlines: false,
  shadedZones: true,
  sortedByCategory: false,
  isAlignScales: true,
  isAlignOrder: true,
  multiChart: false,
  fontSize: DEFAULT_CHART_FONT_SIZE,
  timeInterval: null,
  reviewsCount: 0,
  postType: 'post',
  interval: null,
  isExpandChart: false,
  tableSort: [],
  order: null,
  isDelta: false,
  pageSize: undefined,
  showPercentChange: true,
  wrapLabels: false,
  maxAxesLength: 30,
  isUnlinked: false,
  matrixScaleVolume: false,
  matrixSort: false,
  matrixSortAxis: undefined,
  matrixSortValue: undefined,
  matrixSpectrumColors: false,
  matrixFlipAxis: false,
  expandedCategories: [],
  hideParentCategories: false,
  hideXAxis: false,
  hideYAxis: false,
  comparePeriod: '',
  // this needs to start as undefined - different default state in different lenses
  showCategories: undefined,
}

export const initialState = {
  hasLoadedFilters: false,
  hasLoadedControls: false,
  isHashing: false,
  // TODO this will become clientId, projectId will be a list of projects
  projectId: '',
  details: detailsDefault,
  countries: null,
  selectedCountry: [],
  isComparative: false,
  comparativePanelsNumber: 1,
  route: {
    isSource: false,
    isNetwork: false,
    isProduct: false,
    isKeywords: false,
    isSettings: false,
    isFeedback: false,
    isCharts: false,
    isComparative: false,
    isRecommendations: false,
    isSavedViews: false,
    isInsights: false,
    isCompass: false,
    isAlerts: false,
    isDashboard: false,
    isFocus: false,
    isPDP: false,
    isGemini: false,
    isAutomated: false,
    isFindings: false,
    isAutoInsights: false,
  },
  globalControls: {},
  dashboardControls: [defaultDashboardControls],
  showSelectDataset: true,
  summaryIsOpen: false,
  summaryRequest: null,
  summaryDebounce: false,
  isAlertDrawerOpen: false,
  alertRequest: undefined,
  isExportDrawerOpen: false,
  exportData: undefined,
  isExporting: false,
  isSuggestedViewsDrawerOpen: false,
  localNotifications: [],
  notificationsIsOpen: false,
  chartData: [],
  currentChartList: [],
  orderedTableData: [],
  isAlignFilters: false,
  // Filter Values
  filters: [
    {
      values: [
        {
          field: 'create_time',
          value_type: 0,
          max: formatDateToUTCEndOfDay(moment()).unix(),
          min: formatDateToUTCMidnight(moment().subtract(1, 'year')).unix(),
        },
      ],
      searchQuery: [],
      searchCondition: [],
    },
  ],
  // Filter List
  filterList: [[]],
  defaultFilterList: [],
  openedOptions: [[]],
  selectedOptionField: [''],
  // FEEDBACK
  feedbackControls: [
    {
      order: 'desc',
      orderField: 'create_time',
    },
  ],
  // Network
  networkControls: [{ clusterField: '' }],
  // Keywords
  keywordsControls: [
    {
      searchTerm: '',
      windowSize: 1,
      stopWords: [],
      currentPhrase: '',
    },
  ],
  // Filter Presets
  filterPresets: [],
  relativeDateFilterIdx: [-1],
  isHyperCopy: false,
  dateWindows: {},
}

export const useProjectStore = create<ProjectState>()(
  // devtools(
  // https://github.com/pmndrs/zustand/blob/main/docs/integrations/persisting-store-data.md#qa
  // persist(
  (set) => ({
    ...initialState,
    setHasLoadedFilters: (hasLoadedFilters: any) =>
      set(
        produce((state: ProjectState) => {
          state.hasLoadedFilters = hasLoadedFilters
        }),
      ),
    setHasLoadedControls: (hasLoadedControls: any) =>
      set(
        produce((state: ProjectState) => {
          state.hasLoadedControls = hasLoadedControls
        }),
      ),
    setIsHashing: (isHashing: boolean) =>
      set(
        produce((state: ProjectState) => {
          state.isHashing = isHashing
        }),
      ),
    setProjectId: (projectId: any) =>
      set(
        produce((state: ProjectState) => {
          state.projectId = projectId
        }),
      ),
    setDetails: (details: any) =>
      set(
        produce((state: ProjectState) => {
          state.details = details
        }),
      ),
    setCountries: (countries: CountriesResponse) =>
      set(
        produce((state: ProjectState) => {
          state.countries = countries
        }),
      ),
    setSelectedCountry: (country: string[]) =>
      set(
        produce((state: ProjectState) => {
          state.selectedCountry = country
        }),
      ),
    setIsComparative: (isComparative: boolean) =>
      set(
        produce((state: ProjectState) => {
          state.isComparative = isComparative
        }),
      ),
    setComparativePanelsNumber: (count: any) =>
      set(
        produce((state: ProjectState) => {
          state.comparativePanelsNumber = count
          if (count > 1) {
            state.isComparative = true
          } else {
            state.isComparative = false
          }
        }),
      ),
    setRoute: (route: any) =>
      set(
        produce((state: ProjectState) => {
          state.route = route
        }),
      ),
    setDashboardControls: (values: Record<string, any>[]) =>
      set(
        produce((state: ProjectState) => {
          state.dashboardControls = values
        }),
      ),
    updateDashboardControls: (
      comparativeIndex: number,
      field: string,
      value: any,
    ) =>
      set(
        produce((state: ProjectState) => {
          state.dashboardControls[comparativeIndex][_.camelCase(field)] = value
        }),
      ),
    setSummaryIsOpen: (summaryIsOpen: boolean) =>
      set(
        produce((state: ProjectState) => {
          state.summaryIsOpen = summaryIsOpen
        }),
      ),
    setSummaryRequest: (summaryRequest: any) =>
      set(
        produce((state: ProjectState) => {
          state.summaryRequest = summaryRequest
        }),
      ),
    setSummaryDebounce: (summaryDebounce: boolean) =>
      set(
        produce((state: ProjectState) => {
          state.summaryDebounce = summaryDebounce
        }),
      ),
    setIsAlertDrawerOpen: (isAlertDrawerOpen: boolean) =>
      set(
        produce((state: ProjectState) => {
          state.isAlertDrawerOpen = isAlertDrawerOpen
        }),
      ),
    setAlertRequest: (alertRequest: any) =>
      set(
        produce((state: ProjectState) => {
          state.alertRequest = alertRequest
        }),
      ),
    setIsExportDrawerOpen: (isExportDrawerOpen: boolean) =>
      set(
        produce((state: ProjectState) => {
          state.isExportDrawerOpen = isExportDrawerOpen
        }),
      ),
    setExportData: (exportData: ExportData) =>
      set(
        produce((state: ProjectState) => {
          state.exportData = exportData
        }),
      ),
    setIsExporting: (isExporting: boolean) =>
      set(
        produce((state: ProjectState) => {
          state.isExporting = isExporting
        }),
      ),
    setLocalNotifications: (notifications: NotificationsResponse[]) =>
      set(
        produce((state: ProjectState) => {
          state.localNotifications = notifications
        }),
      ),
    setNotificationsIsOpen: (notificationsIsOpen: boolean) =>
      set(
        produce((state: ProjectState) => {
          state.notificationsIsOpen = notificationsIsOpen
        }),
      ),
    setChartData: (value: DashboardChartResponse[]) =>
      set(
        produce((state: ProjectState) => {
          state.chartData = value
        }),
      ),
    updateChartData: (
      comparativeIndex: number,
      value: DashboardChartResponse,
    ) =>
      set(
        produce((state: ProjectState) => {
          state.chartData[comparativeIndex] = value
        }),
      ),
    setCurrentChartList: (value: UnionChart[]) =>
      set(
        produce((state: ProjectState) => {
          state.currentChartList = value
        }),
      ),
    updateCurrentChartList: (comparativeIndex: number, value: any) =>
      set(
        produce((state: ProjectState) => {
          state.currentChartList[comparativeIndex] = value
        }),
      ),
    setOrderedTableData: (value: any) =>
      set(
        produce((state: ProjectState) => {
          state.orderedTableData = value
        }),
      ),
    setIsAlignFilters: (isAlignFilters: any) =>
      set(
        produce((state: ProjectState) => {
          state.isAlignFilters = isAlignFilters
        }),
      ),
    setFilters: (filters: Array<Filters>) =>
      set(
        produce((state: ProjectState) => {
          state.filters = filters
        }),
      ),
    updateFilters: (comparativeIndex: number, value: Filters) =>
      set(
        produce((state: ProjectState) => {
          state.filters[comparativeIndex] = value
        }),
      ),
    setFilterValues: (
      comparativeIndex: number,
      values: Array<AppliedFilterOption>,
    ) => {
      set(
        produce((state: ProjectState) => {
          state.filters[comparativeIndex].values = values
        }),
      )
    },
    updateFilterValues: (
      comparativeIndex: number,
      field: string,
      values: any,
    ) =>
      set(
        produce((state: ProjectState) => {
          const oldValue = state.filters[comparativeIndex].values.find(
            (el: any) => el.field === field,
          )
          if (oldValue) {
            ;(oldValue as AppliedFilterOptionSelect).values = values
          } else {
            state.filters[comparativeIndex].values.push({ field, values })
          }
        }),
      ),
    clearFilter: (comparativeIndex: number, field: string) =>
      set(
        produce((state: ProjectState) => {
          state.filters[comparativeIndex].values = state.filters[
            comparativeIndex
          ].values.filter((el: any) => el.field !== field)
        }),
      ),
    setSearchQuery: (comparativeIndex: number, values: string[]) =>
      set(
        produce((state: ProjectState) => {
          state.filters[comparativeIndex].searchQuery = values
        }),
      ),
    setSearchCondition: (comparativeIndex: number, values: string[]) =>
      set(
        produce((state: ProjectState) => {
          state.filters[comparativeIndex].searchCondition = values
        }),
      ),
    setFilterList: (values: any) =>
      set(
        produce((state: ProjectState) => {
          state.filterList = values
        }),
      ),
    setFilterListByIndex: (comparativeIndex: number, values: FilterOption[]) =>
      set(
        produce((state: ProjectState) => {
          let temp = state.filterList[comparativeIndex]
          if (
            temp.length > 0 &&
            state.openedOptions[comparativeIndex]?.length
          ) {
            temp.forEach((el, index) => {
              temp[index] = { ...el, ...values[index] }
            })
            state.filterList[comparativeIndex] = temp
          } else {
            state.filterList[comparativeIndex] = values
          }
        }),
      ),
    resetFilterListByIndex: (comparativeIndex: number) =>
      set(
        produce((state: ProjectState) => {
          state.filterList[comparativeIndex] = []
        }),
      ),
    setDefaultFilterList: (values: Array<FilterOption>) =>
      set(
        produce((state: ProjectState) => {
          state.defaultFilterList = values
        }),
      ),
    setOpenedOptions: (values: string[][]) =>
      set(
        produce((state: ProjectState) => {
          state.openedOptions = values
        }),
      ),
    updateOpenedOptions: (comparativeIndex: number, value: string) =>
      set(
        produce((state: ProjectState) => {
          if (!state.openedOptions[comparativeIndex].includes(value)) {
            state.openedOptions[comparativeIndex].push(value)
          }
        }),
      ),
    clearOpenedOptions: (comparativeIndex: number) =>
      set(
        produce((state: ProjectState) => {
          state.openedOptions[comparativeIndex] = []
        }),
      ),
    setSelectedOptionField: (values: string[]) =>
      set(
        produce((state: ProjectState) => {
          state.selectedOptionField = values
        }),
      ),
    updateSelectedOptionField: (comparativeIndex: number, value: string) =>
      set(
        produce((state: ProjectState) => {
          state.selectedOptionField[comparativeIndex] = value
        }),
      ),
    setFeedbackControls: (values: any) =>
      set(
        produce((state: ProjectState) => {
          state.feedbackControls = values
        }),
      ),
    updateFeedbackControls: (
      comparativeIndex: number,
      field: string,
      value: any,
    ) =>
      set(
        produce((state: ProjectState) => {
          state.feedbackControls[comparativeIndex][_.camelCase(field)] = value
        }),
      ),
    setNetworkControls: (values: Record<string, any>[]) =>
      set(
        produce((state: ProjectState) => {
          state.networkControls = values
        }),
      ),
    updateNetworkControls: (
      comparativeIndex: number,
      field: string,
      value: any,
    ) =>
      set(
        produce((state: ProjectState) => {
          state.networkControls[comparativeIndex][_.camelCase(field)] = value
        }),
      ),
    setKeywordsControls: (value: any) =>
      set(
        produce((state: ProjectState) => {
          state.keywordsControls = value
        }),
      ),
    updateKeywordsControls: (
      comparativeIndex: number,
      field: string,
      value: any,
    ) =>
      set(
        produce((state: ProjectState) => {
          state.keywordsControls[comparativeIndex][_.camelCase(field)] = value
        }),
      ),
    setFilterPresets: (filterPresets: any) =>
      set(
        produce((state: ProjectState) => {
          state.filterPresets = filterPresets
        }),
      ),
    setRelativeDateFilterIdx: (relativeDateFilterIdx: any) =>
      set(
        produce((state: ProjectState) => {
          state.relativeDateFilterIdx = relativeDateFilterIdx
        }),
      ),
    setIsHyperCopy: (isHyperCopy: boolean) =>
      set(
        produce((state: ProjectState) => {
          state.isHyperCopy = isHyperCopy
        }),
      ),
    updateRelativeDateFilterIdx: (
      comparativeIndex: number,
      relativeDateFilterIdx: any,
    ) =>
      set(
        produce((state: ProjectState) => {
          state.relativeDateFilterIdx[comparativeIndex] = relativeDateFilterIdx
        }),
      ),
    reset: () => {
      set(initialState)
    },
    resetFilters: (projectId: string, details: ProjectDetails) => {
      set(initialState)
      set(
        produce((state: ProjectState) => {
          state.projectId = projectId
          state.details = details
        }),
      )
    },
    setDateWindows: (dateWindows: Record<string, DateRange[]>) =>
      set(
        produce((state: any) => {
          state.dateWindows = dateWindows
        }),
      ),
    addPanel: () =>
      set(
        produce((state: any) => {
          state.filters.push(initialState.filters[0])
          state.filterList.push([])
          state.relativeDateFilterIdx.push(state.relativeDateFilterIdx[0])

          // increment other lists
          state.dashboardControls.push(initialState.dashboardControls[0])
          state.networkControls.push(initialState.networkControls[0])
          state.keywordsControls.push(initialState.keywordsControls[0])
          state.feedbackControls.push(initialState.feedbackControls[0])
          state.openedOptions.push(initialState.openedOptions[0])
          state.selectedOptionField.push(initialState.selectedOptionField[0])
          state.currentChartList.push(initialState.currentChartList[0])
          // state.chartData.push(initialState.chartData)

          if (!state.isComparative) {
            state.dashboardControls[0].legend = false
            state.isComparative = true
          }

          state.comparativePanelsNumber += 1
        }),
      ),
    removePanel: (index: number) =>
      set(
        produce((state: any) => {
          state.comparativePanelsNumber -= 1
          state.filters = removeAtIndex(state.filters, index)
          state.filterList = removeAtIndex(state.filterList, index)
          state.relativeDateFilterIdx = removeAtIndex(
            state.relativeDateFilterIdx,
            index,
          )

          if (index === 0) {
            const newZeroIndex = mergeObjects(
              { ...state.dashboardControls[0] },
              { ...state.dashboardControls[1] },
            )
            state.dashboardControls = removeAtIndex(
              state.dashboardControls,
              index,
            )
            state.dashboardControls[0] = newZeroIndex
          } else {
            state.dashboardControls = removeAtIndex(
              state.dashboardControls,
              index,
            )
          }

          state.networkControls = removeAtIndex(state.networkControls, index)
          state.keywordsControls = removeAtIndex(state.keywordsControls, index)
          state.feedbackControls = removeAtIndex(state.feedbackControls, index)
          state.openedOptions = removeAtIndex(state.openedOptions, index)
          state.selectedOptionField = removeAtIndex(
            state.selectedOptionField,
            index,
          )
          state.chartData = removeAtIndex(state.chartData, index)
          state.currentChartList = removeAtIndex(state.currentChartList, index)

          if (state.comparativePanelsNumber === 2) {
            state.dashboardControls[0].legend = true
            state.isComparative = false
          }
        }),
      ),
    setIsSuggestedViewsDrawerOpen: (isSuggestedViewsDrawerOpen: boolean) =>
      set(
        produce((state: ProjectState) => {
          state.isSuggestedViewsDrawerOpen = isSuggestedViewsDrawerOpen
        }),
      ),
  }),
  //   {
  //     name: 'project-storage',
  //   }
  // )
)
