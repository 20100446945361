import React, { useState } from 'react'
import { Button, Popover } from 'antd'
import { useQuery } from 'react-query'
import { LoadingOutlined } from '@ant-design/icons'
import {
  initialState,
  useProjectStore,
} from '../../../../features/project/projectStore/projectStore'
import { Filters, ProjectState } from '../../../../features/project/types'
import { getSemanticReferences } from '../../model'
import { secondaryOrange } from '../../../../assets/styles/variables'
import SemanticReviewCarousel from '../../../SemanticReviewCarousel/SemanticReviewCarousel'
import {
  CitationBubble,
  Container,
  NumberLink,
  Spacer,
} from './KeyPhrase.styles'
import { getBookmarkUrlPost } from 'features/url-params/model'
import {
  CHARTS_URL,
  FEEDBACK_URL,
  getCurrentRouteName,
} from 'features/project/utils'
import { useFeatureFlags } from 'features/project/hooks/useFeatureFlags'
import mixpanel from 'features/trackers/mixpanel'

type Props = {
  phrase: string
  brands?: string[]
  products?: string[]
  themes?: string[]
  limit?: number
  threshold?: number
  style?: React.CSSProperties
  filterValues: Filters
}

const KeyPhrase: React.FC<Props> = ({
  phrase,
  brands,
  products,
  themes,
  limit,
  threshold,
  style,
  filterValues,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const route = useProjectStore((state: ProjectState) => state.route)

  // hooks
  const { data: feature_flags } = useFeatureFlags()

  const {
    data: semanticReferences,
    isLoading,
    refetch,
  } = useQuery(
    [
      'semanticReferences',
      projectId,
      phrase,
      brands,
      products,
      themes,
      limit,
      threshold,
    ],
    () =>
      getSemanticReferences(
        projectId,
        phrase,
        brands,
        products,
        themes,
        limit,
        threshold,
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!projectId && feature_flags?.semantic_search_proj,
      retry: false,
      onSuccess: (data) => {
        // if (data.length === 0 && dynamicThreshold > 0.1) {
        //   setDynamicThreshold((prev) => prev - 0.025)
        // }
      },
    },
  )

  const searchFilters = { ...filterValues }
  searchFilters.searchCondition = ['SEMANTIC']
  searchFilters.searchQuery = []

  const openFeedback = async (e: React.MouseEvent) => {
    e.preventDefault()
    document.body.style.cursor = 'wait'
    const filters = {
      values: filterValues.values,
      searchQuery: [phrase],
      searchCondition: ['SEMANTIC'],
    }
    const pageControls = initialState.feedbackControls
    const body = {
      filters: [filters],
      pageControls,
    }
    const page = 'feedback'
    const filterHash = await getBookmarkUrlPost(projectId, {
      page,
      filterString: JSON.stringify(body),
    })

    const url = `${window.location.origin}/project/${projectId}/${page}?filters=${filterHash}`

    mixpanel.track('insights feed', {
      action: 'keyword',
      value: phrase,
      ...route,
    })
    window.open(url, '')
    document.body.style.cursor = ''
  }

  // useEffect(() => {
  //   if (
  //     semanticReferences?.length === 0 &&
  //     dynamicThreshold > 0.1 &&
  //     !isLoading
  //   ) {
  //     console.log()
  //     setDynamicThreshold((prev) => prev - 0.01)
  //   }
  // }, [semanticReferences])

  // console.log(semanticReferences)

  const references = semanticReferences ?? []
  // semanticReferences?.filter((el) => el.score > dynamicThreshold) ?? []
  const numReferences = references.length

  // there is an additional configuration that has to take place for this type of semantic search support to work
  if (!feature_flags?.semantic_search_proj) {
    return (
      <Container style={{ ...style, paddingRight: 0 }} onClick={openFeedback}>
        {phrase}
      </Container>
    )
  }
  return (
    <Popover
      // title={
      //   <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      //     <div>{phrase}</div>
      //     <Button onClick={() => refetch()}>Refresh</Button>
      //   </div>
      // }
      content={
        <SemanticReviewCarousel
          phrase={phrase}
          products={products ?? []}
          themes={themes ?? []}
          references={references}
        />
        // <PhraseContent
        //   key={'phrase'}
        //   phrase={phrase}
        //   references={references}
        //   refetch={refetch}
        // />
      }
      overlayInnerStyle={{ borderRadius: 10, minWidth: 500, maxWidth: 500 }}
      // check assets/styles/base.css for the popoverNoPadding class
      // was the only way to overwrite some build in padding
      overlayClassName={'popoverNoPadding'}
    >
      <Container style={{ ...style }} onClick={openFeedback}>
        {phrase}
        <Citation count={numReferences} isLoading={isLoading} />
      </Container>
    </Popover>
  )
}

const PhraseContent: React.FC<{
  phrase: string
  references: any[]
  refetch: any
}> = ({ phrase, references, refetch }) => {
  const [index, setIndex] = useState(0)

  const reference = references[index]

  const highlightSentence = (body: string, text: string) => {
    return body
      .split(text)
      .join(`<span style="background-color: ${secondaryOrange}">${text}</span>`)
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        justifyContent: 'space-between',
      }}
    >
      {/*TODO highlight sentence*/}
      <div
        style={{
          minWidth: 500,
          maxWidth: 500,
          maxHeight: 150,
          minHeight: 150,
          overflow: 'auto',
          fontSize: 16,
        }}
      >
        {reference && (
          <>
            <p>{reference?.text}</p>
            {/*<p*/}
            {/*  dangerouslySetInnerHTML={{*/}
            {/*    __html: highlightSentence(reference?.text, phrase),*/}
            {/*  }}*/}
            {/*></p>*/}
            {reference?.sentiment && <p>Sentiment: {reference.sentiment}</p>}
          </>
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          onClick={() =>
            setIndex((index - 1 + references.length) % references.length)
          }
        >
          Previous
        </Button>
        <div>
          {index + 1} / {references.length}
        </div>
        <Button onClick={() => setIndex((index + 1) % references.length)}>
          Next
        </Button>
      </div>
    </div>
  )
}

const Citation: React.FC<{ count: number; isLoading: boolean }> = ({
  count,
  isLoading,
}) => {
  return (
    <Spacer
      style={{ display: 'inline-block', width: '14px', position: 'relative' }}
    >
      <CitationBubble>
        <NumberLink>
          {isLoading ? (
            <LoadingOutlined style={{ fontSize: 'inherit' }} />
          ) : (
            count
          )}
        </NumberLink>
      </CitationBubble>
    </Spacer>
  )
}

export default KeyPhrase
