import { ChartData, ChartOptions } from 'chart.js'
import Chart from 'chart.js/auto'
import {
  BarChart,
  BubbleChart,
  ChartLegend,
  StackBarChart,
  UnionChart,
  ValuesChart,
} from './types'
import { AppliedFilterOption } from '../filters/types'
import { Filters } from '../../types'
import React from 'react'
import _ from 'lodash'
import html2canvas from 'html2canvas'
import logo from 'assets/images/logos/Peach_Horizontal.png'
import { toast } from 'react-toastify'
import { ThemeDrivers } from 'features/project/features/Charts/components/ExportDrawer/types'
import { DRIVER_EXPORT_LIMIT } from 'features/project/features/Charts/components/ExportDrawer/constants'
import pluralize from 'pluralize'

const DEFAULT_CATEGORY_KEY = 'category'

export enum ChartTypes {
  BAR = 'bar_charts',
  BUBBLE = 'bubble_charts',
  LINE = 'line_charts',
  STACK_BAR = 'stack_bar_charts',
  PIE = 'pie_charts',
  NUMERICAL = 'numerical_charts',
  TABLE = 'table_charts',
  MATRIX = 'matrix_charts',
}

export const matrixChartIds = [
  '9_3_18_rating',
  '9_3_19_sentiment',
  '9_3_40_rating',
  '9_3_41_sentiment',
  '5_3_18_rating',
  '5_3_19_sentiment',
  '6_3_40_rating',
  '6_3_41_sentiment',
]

export function isValuableChart(chart: UnionChart): chart is ValuesChart {
  return (chart as ValuesChart).values !== undefined
}

export function areLabelsDisplayed(chart: UnionChart) {
  if ((chart as StackBarChart).orientation === 'horizontal') {
    return (chart as StackBarChart).values.length < 20
  } else if ((chart as StackBarChart).orientation === 'vertical') {
    return (chart as StackBarChart).values.length < 12
  } else {
    return true
  }
}

export function updateChartConfig(
  dataObject: ChartData,
  legend: ChartLegend[] | null,
  optionsObject: ChartOptions,
) {
  // todo maybe we insert subthemes here
  const data = {
    ...dataObject,
    datasets: dataObject?.datasets?.filter((dataset) => {
      return legend?.some(
        (legendItem) =>
          !legendItem.disabled && dataset.label === legendItem.label,
      )
    }),
  }
  const options = {
    ...optionsObject,
    plugins: {
      ...optionsObject?.plugins,
      annotation: {
        ...optionsObject?.plugins?.annotation,
        annotations: (
          optionsObject?.plugins?.annotation?.annotations as any[]
        )?.filter((annotationItem) => {
          return legend?.some(
            (legendItem) =>
              !annotationItem.id.includes('lobf') ||
              !annotationItem.category ||
              (!legendItem.disabled &&
                annotationItem.category === legendItem.label),
          )
        }),
      },
    },
  }
  return { data, options }
}

export function updateBarChartConfig(
  dataObject: ChartData,
  legend: ChartLegend[] | null,
  optionsObject: ChartOptions,
) {
  let indexArray: number[] = []
  if (dataObject && dataObject.datasets) {
    let result = { ...dataObject, datasets: [...dataObject.datasets!] }
    result!.datasets[1] = { ...dataObject.datasets[1] }
    result!.datasets[2] = { ...dataObject.datasets[2] }
    if (typeof dataObject.datasets[1]?.backgroundColor === 'string') {
    } else {
      ;(dataObject.datasets[1]?.backgroundColor as string[])?.forEach(
        (color, colorIndex) => {
          const isFound = legend?.some(
            (legendItem) => !legendItem.disabled && color === legendItem.color,
          )
          if (isFound) {
            indexArray.push(colorIndex)
          }
        },
      )
      result.datasets[1].data = (dataObject.datasets[1]?.data as number[])?.map(
        (item, itemIndex) =>
          indexArray?.some((indexItem) => indexItem === itemIndex) ? item : 0,
      )
      if (result.datasets[2].label === 'Overlay') {
        result.datasets[2].data = (
          dataObject.datasets[2]?.data as number[]
        )?.map((item, itemIndex) =>
          indexArray?.some((indexItem) => indexItem === itemIndex) ? item : 0,
        )
      }
    }

    return { data: result, options: optionsObject }
  }
  return { data: dataObject, options: optionsObject }
}

export function updateMatrixChartConfig(
  dataObject: ChartData,
  legend: ChartLegend[] | null,
  optionsObject: ChartOptions,
) {
  const newDataObject = _.cloneDeep(dataObject)
  const omittedLegendColors =
    legend?.filter((el) => el.disabled).map((el) => el.color) ?? []
  let data = newDataObject.datasets[0].data
  newDataObject.datasets[0].data = data.filter(
    // @ts-ignore
    (el) => !omittedLegendColors.includes(el?.color),
  )

  return { data: newDataObject, options: optionsObject }
}

export function updatePieChartConfig(
  dataObject: ChartData,
  legend: ChartLegend[] | null,
  optionsObject: ChartOptions,
  treeView: boolean,
) {
  if (dataObject && dataObject.datasets) {
    const result = {
      datasets: [
        {
          ...(dataObject.datasets as any)[0],
          data: (dataObject.datasets[0].data as number[]).filter(
            (item, dataIndex) => {
              return legend?.some(
                (legendItem, legendIndex) =>
                  dataIndex === legendIndex && !legendItem.disabled,
              )
            },
          ),
          backgroundColor: !treeView
            ? (dataObject.datasets[0].backgroundColor as string[])?.filter(
                (item, dataIndex) => {
                  return legend?.some(
                    (legendItem, legendIndex) =>
                      dataIndex === legendIndex && !legendItem.disabled,
                  )
                },
              )
            : dataObject.datasets[0].backgroundColor,
        },
      ],
      labels: dataObject?.labels?.filter((label: any) => {
        return legend?.some(
          (legendItem) => label === legendItem.label && !legendItem.disabled,
        )
      }),
    }
    if (treeView) {
      result.datasets[0].tree = (dataObject?.datasets as any)[0].tree?.filter(
        (item: any, dataIndex: any) => {
          return legend?.some(
            (legendItem, legendIndex) =>
              dataIndex === legendIndex && !legendItem.disabled,
          )
        },
      )
    }
    return { data: result, options: optionsObject }
  }
  return { data: dataObject, options: optionsObject }
}

export const highlightHoveredData = (
  chartAPI: Chart | null,
  currentChart: UnionChart | any,
  highlightedLegendItems: any[],
  treeView: boolean,
) => {
  const chartType = currentChart?.chart_type
  if (chartAPI) {
    if (chartType === ChartTypes.BUBBLE || chartType === ChartTypes.LINE) {
      const datasets = chartAPI.data.datasets
      if (highlightedLegendItems.length) {
        const selectedItems = datasets.filter((item) =>
          highlightedLegendItems.map((el) => el.label).includes(item.label),
        )
        const selectedIndexes: number[] = datasets.reduce(function (
          a: number[],
          e,
          i,
        ) {
          if (highlightedLegendItems.map((el) => el.label).includes(e.label))
            a.push(i)
          return a
        }, [])
        if (selectedItems.length) {
          datasets.forEach((item) => {
            if (!selectedItems.map((el) => el.label).includes(item.label)) {
              const offset = (item.backgroundColor as string).includes(', 0.25')
                ? 7
                : (item.backgroundColor as string).includes(', 0.1')
                  ? 6
                  : 1
              item.backgroundColor =
                (item.backgroundColor as string).slice(0, -offset) + ', 0.1)'
              const borderOffset = (item.borderColor as string).includes(
                ', 0.1',
              )
                ? 6
                : 1
              item.borderColor =
                (item.borderColor as string).slice(0, -borderOffset) + ', 0.1)'
            } else {
              const offset = (item.backgroundColor as string).includes(', 0.25')
                ? 7
                : (item.backgroundColor as string).includes(', 0.1')
                  ? 6
                  : 1
              item.backgroundColor =
                (item.backgroundColor as string).slice(0, -offset) + ', 0.25)'
              const borderOffset = (item.borderColor as string).includes(
                ', 0.1',
              )
                ? 6
                : 1
              item.borderColor =
                (item.borderColor as string).slice(0, -borderOffset) + ')'
            }
            if (chartAPI?.options?.plugins?.datalabels) {
              if (selectedIndexes.length) {
                chartAPI.options.plugins.datalabels.color = (context: any) => {
                  // Replace 'indexToBold' with the index of the dataset you want to bold
                  if (selectedIndexes.includes(context.datasetIndex)) {
                    return 'black'
                  }
                  return 'lightgrey'
                }
              }
            }
          })
        }
      } else {
        datasets.forEach((item) => {
          if (item.backgroundColor?.toString().includes(', 0.1')) {
            item.backgroundColor =
              item.backgroundColor.toString().slice(0, -6) + ', 0.25)'
          }
          if (item.borderColor?.toString().includes(', 0.1')) {
            item.borderColor = item.borderColor.toString().slice(0, -6) + ')'
          }
        })
        if (chartAPI?.options?.plugins?.datalabels) {
          chartAPI.options.plugins.datalabels.color = 'black'
        }
      }
    }
    if (chartType === ChartTypes.STACK_BAR) {
      const datasets = chartAPI.data.datasets
      if (highlightedLegendItems.length) {
        const selectedItems = datasets.filter((item) =>
          highlightedLegendItems.map((el) => el.label).includes(item.label),
        )
        if (selectedItems.length) {
          datasets.forEach((item) => {
            if (!selectedItems.map((el) => el.label).includes(item.label)) {
              const offset = (item.backgroundColor as string).includes(', 0.1')
                ? 6
                : 1
              item.backgroundColor =
                item.backgroundColor?.toString().slice(0, -offset) + ', 0.1)'
            } else {
              if (item.backgroundColor?.toString().includes(', 0.1')) {
                item.backgroundColor =
                  item.backgroundColor.toString().slice(0, -6) + ')'
              }
            }
          })
        }
      } else {
        datasets.forEach((item) => {
          if (item.backgroundColor?.toString().includes(', 0.1')) {
            item.backgroundColor =
              item.backgroundColor.toString().slice(0, -6) + ')'
          }
        })
      }
    }
    if (chartType === ChartTypes.BAR) {
      const datasets = chartAPI.data.datasets[1]
      if (highlightedLegendItems.length) {
        datasets.backgroundColor = (datasets.backgroundColor as string[]).map(
          (item) => {
            if (!item) return item
            const offset = item.includes(', 0.1') ? 6 : 1
            const newItem = !highlightedLegendItems
              .map((el) => el.color)
              .includes(item.slice(0, -offset) + ')')
              ? item.slice(0, -offset) + ', 0.1)'
              : item.slice(0, -offset) + ')'
            return newItem
          },
        )
      } else {
        if (!!datasets && typeof datasets?.backgroundColor !== 'string') {
          datasets.backgroundColor = (
            datasets.backgroundColor as string[]
          )?.map((item) =>
            item.includes(', 0.1') ? (item = item.slice(0, -6) + ')') : item,
          )
        }
      }
    }
    if (chartType === ChartTypes.PIE && !treeView) {
      const datasets = chartAPI.data.datasets[0]
      const highlightedLabels = highlightedLegendItems.map((el) => el.label)
      if (highlightedLegendItems.length) {
        chartAPI.data.labels?.forEach((el, index) => {
          if (highlightedLabels.includes(el)) {
            const item = (datasets.backgroundColor as string[])?.[index]
            if (!item) return item
            const offset = item.includes(', 0.1') ? 6 : 1
            ;(datasets.backgroundColor as string[])[index] =
              item.slice(0, -offset) + ')'
          } else {
            const item = (datasets.backgroundColor as string[])?.[index]
            const offset = item.includes(', 0.1') ? 6 : 1
            ;(datasets.backgroundColor as string[])[index] =
              item.slice(0, -offset) + ', 0.1)'
          }
        })
      } else {
        try {
          datasets.backgroundColor = (datasets.backgroundColor as string[]).map(
            (item) =>
              item.includes(', 0.1') ? (item = item.slice(0, -6) + ')') : item,
          )
        } catch (e) {}
      }
    }
    if (chartType === ChartTypes.PIE && treeView) {
      // @ts-ignore
      const datasets = chartAPI.data.datasets[0]?.tree
      if (!datasets) return
      if (highlightedLegendItems.length) {
        // TODO new typescript broke this EFE-97
        const selectedItems = datasets.filter((item: any) =>
          highlightedLegendItems.map((el) => el.label).includes(item.title),
        )
        if (selectedItems.length) {
          // @ts-ignore
          datasets.forEach((item) => {
            if (
              !selectedItems.map((el: any) => el.title).includes(item.title)
            ) {
              const offset = (item.color as string).includes(', 0.1') ? 6 : 1
              item.color = item.color?.toString().slice(0, -offset) + ', 0.1)'
            } else {
              if (item.color?.toString().includes(', 0.1')) {
                item.color = item.color.toString().slice(0, -6) + ')'
              }
            }
          })
        } else {
          datasets.forEach((item: any) => {
            if (item.color?.toString().includes(', 0.1')) {
              item.color = item.color.toString().slice(0, -6) + ')'
            }
          })
        }
      } else {
        datasets.forEach((item: any) => {
          if (item.color?.toString().includes(', 0.1')) {
            item.color = item.color.toString().slice(0, -6) + ')'
          }
        })
      }
    }
    if (chartType === ChartTypes.MATRIX) {
      const datasets = chartAPI.data.datasets[0].data
      if (highlightedLegendItems.length) {
        const selectedItems = datasets.filter((item: any) =>
          highlightedLegendItems.map((el) => el.color).includes(item.color),
        )
        if (selectedItems.length) {
          datasets.forEach((item: any) => {
            if (
              !selectedItems.map((el: any) => el.color).includes(item.color)
            ) {
              const offset = (item.color as string).includes(', 0.1') ? 6 : 1
              item.color = item.color?.toString().slice(0, -offset) + ', 0.1)'
            } else {
              if (item.color?.toString().includes(', 0.1')) {
                item.color = item.color.toString().slice(0, -6) + ')'
              }
            }
          })
        }
      } else {
        datasets.forEach((item: any) => {
          if (item.color?.toString().includes(', 0.1')) {
            item.color = item.color.toString().slice(0, -6) + ')'
          }
        })
      }
    }
  }
  chartAPI?.update()
}

export const alignScales = (
  currentChart: UnionChart | any,
  currentChartList: any,
  // TODO should these be dashboardControls + comparativeIndex?
  dashboardControls: any,
  comparativeIndex: number,
  disableGrouping: boolean,
) => {
  if (currentChartList.some((item: any) => !item?.values)) {
    return currentChart
  }

  let currentChartCopy = JSON.parse(JSON.stringify(currentChart))

  if (currentChartCopy.chart_type === ChartTypes.BAR) {
    const minValue = Math.min(
      ...currentChartList.map((item: any) => item.min_value),
    )
    const maxValue = Math.max(
      ...currentChartList.map((item: any) => item.max_value),
    )

    return {
      ...currentChartCopy,
      min_value: minValue,
      max_value: maxValue,
    }
  } else if (currentChartCopy.chart_type === ChartTypes.BUBBLE) {
    currentChartCopy.shaded_zones?.forEach((item: any) => (item.visible = true))

    const currentValuesList = currentChartList.map(
      (item: any, index: number) => {
        return getGroupedChartValues2(
          item,
          dashboardControls[index]?.expandedCategories,
          dashboardControls[0]?.tier1,
          dashboardControls[index]?.showCategories,
          disableGrouping,
        )
      },
    )

    const xValues = currentValuesList
      ?.flat()
      ?.map((element: any) => element[currentChart.x_key])
      .flat()
    const yValues = currentValuesList
      ?.flat()
      ?.map((element: any) => element[currentChart.y_key])
      .flat()

    let minXValue: number | undefined = Math.min(...xValues)
    let maxXValue: number | undefined = Math.max(...xValues)
    let minYValue: number | undefined = Math.min(...yValues)
    let maxYValue: number | undefined = Math.max(...yValues)

    let xOffset = (maxXValue - minXValue) / 4
    let yOffset = (maxYValue - minYValue) / 4

    // todo i hate this
    let finalMinXValue =
      minXValue - xOffset === maxXValue + xOffset
        ? undefined
        : minXValue - xOffset
    let finalMaxXValue =
      minXValue - xOffset === maxXValue + xOffset
        ? undefined
        : maxXValue + xOffset

    let finalMinYValue =
      minYValue - yOffset === maxYValue + yOffset
        ? undefined
        : minYValue - yOffset
    let finalMaxYValue =
      minYValue - yOffset === maxYValue + yOffset
        ? undefined
        : maxYValue + yOffset

    if (
      currentChartCopy.x_key === 'rating' ||
      currentChartCopy.x_key === 'volume'
    ) {
      finalMinXValue = finalMinXValue && finalMinXValue < 0 ? 0 : finalMinXValue
    }
    if (currentChartCopy.x_key === 'sentiment') {
      finalMinXValue =
        !finalMinXValue || (finalMinXValue && finalMinXValue) < -1
          ? -1
          : finalMinXValue
      finalMaxXValue =
        !finalMaxXValue || (finalMaxXValue && finalMaxXValue) > 1
          ? 1
          : finalMaxXValue
    }
    if (
      currentChartCopy.y_key === 'rating' ||
      currentChartCopy.y_key === 'volume'
    ) {
      finalMinYValue = finalMinYValue && finalMinYValue < 0 ? 0 : finalMinYValue
    }
    if (currentChartCopy.y_key === 'rating') {
      finalMaxYValue =
        !finalMaxYValue || (finalMaxYValue && finalMaxYValue > 5)
          ? 5
          : finalMaxYValue
    }

    return {
      ...currentChartCopy,
      min_x: finalMinXValue,
      max_x: finalMaxXValue,
      min_y: finalMinYValue,
      max_y: finalMaxYValue,
    }
  } else if (currentChart.chart_type === ChartTypes.LINE) {
    // TODO - do we need to pull the expandedcategories per list index?

    const currentValuesList = currentChartList.map(
      (item: any, index: number) => {
        return getGroupedChartValues2(
          item,
          dashboardControls[index]?.expandedCategories,
          dashboardControls[0]?.tier1,
          dashboardControls[index]?.showCategories,
          disableGrouping,
        )
      },
    )

    const currentChartValues = getGroupedChartValues2(
      currentChart,
      dashboardControls[comparativeIndex]?.expandedCategories,
      dashboardControls[0]?.tier1,
      dashboardControls[comparativeIndex]?.showCategories,
      disableGrouping,
    )

    const xValues = currentValuesList
      ?.flat()
      ?.map((element: any) => {
        return {
          label: element[currentChart.x_key],
          value: element.x_value_time,
        }
      })
      .flat()

    const uniqueXValues = xValues
      .filter(
        (a: any, i: number) =>
          xValues.findIndex((s: any) => a.label === s.label) === i,
      )
      .sort((a: any, b: any) => a.value - b.value)
      .map((item: any) => item.label)
    const yValues = currentValuesList
      ?.flat()
      ?.map((element: any) => element[currentChart.y_key])
      .flat()
    let newValues: any = []
    uniqueXValues.forEach((item: any) => {
      const foundElements = currentChartValues.filter(
        (element: any) => element.x_value === item,
      )
      if (foundElements.length) {
        newValues.push(...foundElements)
      } else {
        newValues.push({
          category: '',
          x_value: item,
          color: 'rgb(0, 0, 0)',
        })
      }
    })
    const minYValue = Math.min(...yValues)
    const maxYValue = Math.max(...yValues)

    const zValues = currentValuesList
      ?.flat()
      ?.map((element: any) => element[currentChart.z_key])
      .flat()

    const minZValue = Math.min(...zValues)
    const maxZValue = Math.max(...zValues)
    // updateChart({
    //   ...currentChart,
    //   values: newValues,
    //   min_y: minYValue,
    //   max_y: maxYValue,
    //   min_z: minZValue,
    //   max_z: maxZValue,
    // })

    return {
      ...currentChart,
      values: newValues,
      min_y: minYValue,
      max_y: maxYValue,
      min_z: minZValue,
      max_z: maxZValue,
    }
  } else if (currentChart.chart_type === ChartTypes.STACK_BAR) {
    const currentValuesList = currentChartList.map(
      (item: any, index: number) => {
        return getGroupedChartValues2(
          item,
          dashboardControls[index]?.expandedCategories,
          dashboardControls[0]?.tier1,
          dashboardControls[index]?.showCategories,
          disableGrouping,
        )
      },
    )

    const maxValue = Math.max(
      ...currentValuesList?.flat()?.map((element: any) => {
        let volume = 0
        element?.stacked_categories?.forEach((category: any) => {
          volume += category.volume
        })
        return volume
      }),
    )

    return {
      ...currentChart,
      max_value: maxValue,
    }
  }
}

export const alignOrder = (
  currentChart: UnionChart | any,
  currentChartList: any,
  comparativeIndex: number,
  expandedCategories: string[],
  order: string,
  sortedByCategory: boolean,
  showCategories: boolean,
  tier1: string,
  disableBrandGrouping: boolean,
  dashboardControls: any[],
) => {
  if (currentChart.chart_type === 'table_charts') {
    // DO NOTHING
    // Aligning functionality was written into ../features/table-chart.tsx
    return currentChart
  } else {
    if (!currentChart.values.length) {
      return currentChart
    }
    let curValues = getChartValues(
      currentChart,
      expandedCategories ?? [],
      sortedByCategory,
      order ?? 'desc',
      tier1,
      showCategories,
      disableBrandGrouping,
    )
    if (
      !currentChartList ||
      !currentChartList.length ||
      currentChartList.includes(undefined)
    )
      return { ...currentChart, values: curValues }

    const yValues = currentChartList
      .map((chart: any, index: number) => {
        return getChartValues(
          chart,
          dashboardControls[index]?.expandedCategories,
          dashboardControls[index]?.sortedByCategory,
          dashboardControls[index]?.order,
          dashboardControls[0]?.tier1,
          dashboardControls[index]?.showCategories,
          disableBrandGrouping,
        )
      })
      .flat()

    if (yValues.includes(null)) return currentChart

    const comparativeField =
      yValues.length && yValues[0]?.category ? 'category' : 'group'
    const uniqueYValues = yValues.filter(
      (a: any, i: number) =>
        yValues.findIndex(
          (s: any) => a[comparativeField] === s[comparativeField],
        ) === i,
    )

    const newValues = (uniqueYValues as any).map((item: any) => {
      const foundItem = curValues?.find(
        (element: any) =>
          element[comparativeField] === item[comparativeField] &&
          !element.hidden,
      )

      return foundItem
        ? { ...foundItem, hidden: false }
        : { ...item, hidden: true }
    })

    return {
      ...currentChart,
      values: newValues,
    }
  }
}

export function sortLegend(legend: ChartLegend[], type: string) {
  return type === 'asc'
    ? legend.sort((a: ChartLegend, b: ChartLegend) =>
        a.label?.toString().toLowerCase() > b.label?.toString().toLowerCase()
          ? 1
          : -1,
      )
    : type === 'desc'
      ? legend.sort((a: ChartLegend, b: ChartLegend) =>
          a.label?.toString().toLowerCase() < b.label?.toString().toLowerCase()
            ? 1
            : -1,
        )
      : legend
}

export function setDefaultFontSize(size: string | undefined) {
  if (size) {
    ;(Chart.defaults.font as any).size = +fontSizeDict[size]?.slice(0, -2)
  }
}

const isDST = (d: Date) => {
  let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset()
  let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset()
  return Math.max(jan, jul) !== d.getTimezoneOffset()
}
export const getAdjustedDateRangeString = (
  range:
    | {
        min: number
        max: number
      }
    | undefined,
  useDash?: boolean,
) => {
  if (!range) return ''
  return `${new Date(
    range.min * 1000 +
      (new Date().getTimezoneOffset() +
        (isDST(new Date(range.min * 1000)) ? 60 : 0)) *
        60000,
  ).toLocaleDateString()} ${useDash ? '-' : 'to'} ${new Date(
    range.max * 1000,
  ).toLocaleDateString()}`
}

export const fontSizeDict: any = {
  extra_small: '8px',
  small: '10px',
  medium: '14px',
  large: '16px',
  extra_large: '18px',
}
export function splitLabelForChart(label: string, lineLimit: number) {
  const words = label.split(' ')
  const lines = []

  let line = ''
  let currentWordIdx = 0

  while (currentWordIdx < words.length) {
    if (line.length + words[currentWordIdx].length < lineLimit) {
      line += `${words[currentWordIdx]} `
      currentWordIdx++

      if (currentWordIdx === words.length) {
        lines.push(line)
      }
    } else {
      if (line.length) {
        lines.push(line)
        line = ''
      }

      if (words[currentWordIdx].length >= lineLimit) {
        lines.push(words[currentWordIdx].trim())
        currentWordIdx++
      }
    }
  }

  return lines
}
export function splitLabelIntoChunks(label: string, maxChunkLength: number) {
  if (label.length < maxChunkLength) return label
  const words = label.split(' ')
  const chunks = []
  let currentChunk = words[0]

  words.slice(1).forEach((word) => {
    if (currentChunk.length + word.length + 1 <= maxChunkLength) {
      currentChunk += ' ' + word
    } else {
      chunks.push(currentChunk)
      currentChunk = word
    }
  })

  // Push the last chunk
  chunks.push(currentChunk)

  return chunks
}
export const buildSummaryRequest = (
  currentChart: UnionChart,
  projectId: string,
  filters: Filters,
  tier1: string,
  tier1Value: string,
  tier2Value: any,
  tier3Value: any,
  additional_filters: AppliedFilterOption[],
  tier1Label: string,
) => {
  const additionalFields = additional_filters.map((el) => el.field)
  const customTier =
    currentChart.chart_type === ChartTypes.PIE ? 'Sentiment' : 'Theme'
  const payload = {
    headers: {
      proj_uuid: projectId,
    },
    body: {
      chart_id: currentChart.chart_id,
      // TODO this might cause problems someday
      tier1: tier1,
      tier1_value: tier1Value,
      tier1_label: tier1Label,
      tier2_value: tier2Value,
      tier3_value: tier3Value,
      search_terms: filters.searchQuery,
      search_criteria: filters.searchCondition,
      criteria: [
        ...filters.values.filter(
          (el: any) => !additionalFields.includes(el.field),
        ),
        ...additional_filters,
      ],
    },
  }
  return payload
}

const excludedColumns: string[] = [
  'color',
  'type_',
  'is_competitor',
  'x_value_time',
  'z_value_color',
]

export async function downloadChartAsCSV(
  chart: UnionChart,
  chartValues: any[],
  themeDriversExtension?: () => Promise<ThemeDrivers>,
) {
  if (!('values' in chart)) {
    return
  }

  let categoryKey = (chart as BubbleChart).legend_title || DEFAULT_CATEGORY_KEY

  if (
    chart.chart_type === ChartTypes.BAR ||
    chart.chart_type === ChartTypes.STACK_BAR
  ) {
    categoryKey = (chart as BarChart).x_title
  }

  let values: Record<string, any>[] = chartValues

  if (!!themeDriversExtension && values.length >= DRIVER_EXPORT_LIMIT) {
    return false
  }

  if (chart.chart_type === ChartTypes.STACK_BAR) {
    values = chartValues
      .map((el) => {
        return el.stacked_categories.map((category: any) => {
          const newObj: any = {}
          newObj[categoryKey] = el.category
          newObj[
            pluralize(
              (chart as StackBarChart).chart_id.split('_')[
                chart.chart_id.split('_').length - 1
              ],
              1,
            )
          ] = category.category
          Object.keys(category).forEach((key) => {
            if (key === 'stacked_categories') {
              Object.keys(el[key]).forEach((hoverKey) => {
                newObj[hoverKey] = el[key][hoverKey]
              })
            } else {
              if (key !== 'category') {
                newObj[key] = category[key]
              }
            }
          })
          return newObj
        })
      })
      .flat()
  }

  values = values.map((el) => {
    const obj: any = {}
    // TODO update names of columns
    Object.keys(el).forEach((key) => {
      if (key === 'hover') {
        Object.keys(el[key]).forEach((hoverKey) => {
          obj[hoverKey] = el[key][hoverKey]
        })
      } else if (!excludedColumns.includes(key) && !!el[key]) {
        if (key === 'category') {
          obj[categoryKey] = el[key]
        } else if (key === 'x_value') {
          // @ts-ignore
          obj[chart.x_title] = el[key]
        } else if (key === 'y_value') {
          //@ts-ignore
          obj[chart.y_title] = el[key]
        } else if (key === 'z_value') {
          //@ts-ignore
          obj[chart.z_title] = el[key]
        } else {
          obj[key] = el[key]
        }
      }
    })
    return obj
  })

  if (themeDriversExtension) {
    const drivers = await themeDriversExtension()
    values = values.map((el) => {
      let driverInfo = {}
      if (drivers[el[categoryKey]]) {
        drivers[el[categoryKey]].overall_themes?.forEach(
          (driver: any, index: number) => {
            driverInfo = Object.assign({}, driverInfo, {
              [`theme_${index + 1}`]: driver.theme,
              [`theme_${index + 1}_sentiment`]: driver.avg_sentiment,
              [`theme_${index + 1}_sentences`]: driver.mentions_count,
              [`theme_${index + 1}_percent_volume`]:
                driver.mentions_percent + '%',
            })
          },
        )
      }
      return { ...el, ...driverInfo }
    })
  }

  let csvContent = ''
  csvContent +=
    Object.keys(values[0])
      .sort((a, b) => {
        if (a === categoryKey) {
          return -1
        } else if (b === categoryKey) {
          return 1
        }
        return 0
      })
      .map(
        (el) =>
          `"${el.includes('#') || el.includes('%') ? el : _.startCase(el)}"`,
      )
      .join(',') + '\n'
  csvContent += values
    .map((el) => {
      return (
        Object.keys(el)
          .sort((a, b) => {
            if (a === categoryKey) {
              return -1
            } else if (b === categoryKey) {
              return 1
            }
            return 0
          })
          .map((key) =>
            !(typeof el[key] === 'object') ? `"${el[key]}"` : 'object',
          )
          // .filter((el) => !!el)
          .join(',')
      )
    })
    .join('\n')

  // Step 3: Create a Blob
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

  // Step 4: Create a Download Link
  const link = document.createElement('a')
  link.setAttribute('href', URL.createObjectURL(blob))
  link.setAttribute('download', `${chart.title}.csv`)
  document.body.appendChild(link) // Required for Firefox

  // Step 5: Trigger the Download
  link.click()

  // Step 6: Cleanup
  document.body.removeChild(link)

  toast.success('CSV Downloaded')
  return true
}

export const onCopyChart = async (
  wrapperRef: React.RefObject<HTMLDivElement | HTMLCanvasElement>,
  chartWrapperRef: React.RefObject<HTMLDivElement>,
  title?: string,
  isPrint?: boolean,
  contentType?: string,
) => {
  if (wrapperRef.current) {
    const parentElement = wrapperRef.current.parentElement
    if (parentElement) {
      // setTimeout(async () => {
      if (chartWrapperRef.current && wrapperRef.current) {
        let canvas

        canvas = await html2canvas(wrapperRef.current, {
          scrollY: -window.scrollY,
          scale: 2,
        })

        var tempCanvas = document.createElement('canvas')
        var tempCtx = tempCanvas.getContext('2d')
        const text = 'Yogi'

        if (tempCtx) {
          let cw: number, ch: number
          cw = tempCanvas.width = canvas.width
          ch = tempCanvas.height = canvas.height
          tempCtx.drawImage(canvas, 0, 0)
          tempCtx.font = '24px verdana'
          let textWidth = tempCtx.measureText(text).width
          tempCtx.globalAlpha = 0.5
          // // bottom left
          // tempCtx.fillStyle = 'white'
          // tempCtx.fillText(text, 0 + textWidth + 100, ch - 20)
          // tempCtx.fillStyle = 'black'
          // tempCtx.fillText(text, 0 + textWidth + 100 + 2, ch - 20 + 2)
          // // top middle
          // tempCtx.fillStyle = 'white'
          // tempCtx.fillText(text, cw - 150, 70)
          // tempCtx.fillStyle = 'black'
          // tempCtx.fillText(text, cw - 148, 72)

          // let img = new Image(5, 5)
          // img.onload = function () {
          //   // @ts-ignore
          //   tempCtx!.drawImage(img, cw / 2, ch / 2)
          // }
          // img.src = logo

          // TODO enable logo - has new styling in it
          const logoImg = document.createElement('img')
          logoImg.height = 50
          logoImg.width = 50
          logoImg.style.position = 'absolute'
          logoImg.style.left = '50%'
          logoImg.style.top = '0px'
          // logoImg.style.opacity = '.5'

          logoImg.onload = function () {
            // tempCtx!.drawImage(logoImg, cw / 2 - 174, -10, 174, 75)

            tempCanvas.toBlob(function (blob) {
              const item = new ClipboardItem({ 'image/png': blob ?? '' })

              if (isPrint && blob) {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = (title ?? 'download') + '.png'
                link.click()
                return
              }
              navigator.clipboard.write([item])
            })
          }
          logoImg.src = logo
        }
        toast.success(
          isPrint
            ? `${contentType ?? 'Chart'} Downloaded`
            : `${contentType ?? 'Chart'} copied to clipboard`,
        )

        return true
      }
      // }, 10)
    }
  }
}

export const getChartValues = (
  chart: any,
  expandedCategories: string[],
  sortedByCategory: boolean,
  order: string,
  tier1: string,
  showCategories?: boolean,
  disableGrouping?: boolean,
) => {
  const type1Charts = [ChartTypes.BAR, ChartTypes.MATRIX, ChartTypes.STACK_BAR]
  if (type1Charts.includes(chart?.chart_type)) {
    return getGroupedChartValues(
      chart,
      expandedCategories,
      sortedByCategory,
      tier1,
      order,
      showCategories,
      disableGrouping,
    )
  } else {
    return getGroupedChartValues2(
      chart,
      expandedCategories,
      tier1,
      showCategories,
      disableGrouping,
    )
  }
}

// get values for Bar, Stack Bar, Matrix
export const getGroupedChartValues = (
  chart: any,
  expandedCategories: string[],
  sortedByCategory: boolean,
  tier1: string,
  order: string,
  showCategories?: boolean,
  disableGrouping?: boolean,
) => {
  if (!chart?.values) return []

  let values = chart.values

  if (!values) return values
  // if disable and chart is brand lens

  // different default setting for theme vs product and brand
  // Matrix only ever has Themes grouped, but can be viewed in Brand/Product lens,
  // so we treat all matrix as if tier1 = Theme
  const reactiveShowMyBrands =
    chart.category_mapping &&
    (showCategories === undefined
      ? tier1 === 'Theme' || chart.chart_type === ChartTypes.MATRIX
      : showCategories)
  if (disableGrouping || !reactiveShowMyBrands) {
    if (order === 'asc') {
      values = [...values].reverse()
    }
    return values
  }

  const includeParentCategories = !(
    chart.chart_type === ChartTypes.BAR ||
    chart.chart_type === ChartTypes.STACK_BAR ||
    chart.chart_type === ChartTypes.MATRIX
  )

  values = chart.parent_values

  // janky workaround for matrix % Volume by Theme
  if (chart.chart_type === ChartTypes.MATRIX && chart.parent_values) {
    values = chart.parent_values.map((el: any) => {
      const topTheme = el.category.split(' - ')[0]
      const matchingParents = Object.keys(chart.category_mapping).filter(
        (key) => topTheme === key.split(' - ')[0],
      )
      let maxSubthemes = 1
      matchingParents.forEach((parent) => {
        maxSubthemes = Math.max(
          maxSubthemes,
          chart.category_mapping[parent].length,
        )
      })
      return {
        ...el,
        hover: {
          ...el.hover,
          '% Volume by Theme': el.hover['% Volume by Theme'] / maxSubthemes,
        },
      }
    })
  }

  if (expandedCategories?.length && chart.category_mapping) {
    values = []
    let parentValues = chart.parent_values ?? []
    expandedCategories.forEach((category) => {
      if (chart.chart_type === ChartTypes.MATRIX) {
        const theme = category
        values = values.concat(
          chart.values.filter(
            (el: any) => el.category.split(' - ')[0].split(': ')[0] === theme,
          ),
        )
        parentValues = parentValues.filter(
          (el: any) => !el.category.includes(category),
        )
      } else {
        let childValues = chart.category_mapping[category]
        if (childValues) {
          values = values.concat(
            chart.values.filter((item: any) =>
              childValues.includes(item.category),
            ),
          )
          if (!includeParentCategories) {
            parentValues = parentValues.filter(
              (el: any) => el.category !== category,
            )
          }
        }
      }
    })
    values = [...values, ...parentValues]
  } else if (!chart.category_mapping) {
    values = chart.values
  }

  // Not sure why we have to do this additional sort step here, but it works soo
  values = [...values].sort((a, b) => {
    const sortField = a.sentiment ? 'sentiment' : a.rating ? 'rating' : 'volume'
    return b[sortField] - a[sortField]
  })

  if (chart.chart_type === ChartTypes.TABLE) {
    // do nothing
  } else if (sortedByCategory) {
    values = [...values].sort((a, b) => {
      const sortField = a.category ? 'category' : 'group'
      return a[sortField].toString().toLowerCase() >
        b[sortField].toString().toLowerCase()
        ? 1
        : a[sortField].toString().toLowerCase() <
            b[sortField].toString().toLowerCase()
          ? -1
          : 0
    })
  } else {
    values = [...values].sort((a: any, b: any) => {
      const sortField = a.sentiment
        ? 'sentiment'
        : a.rating
          ? 'rating'
          : 'volume'
      if (sortField === 'volume') {
        return (
          b.stacked_categories?.reduce(
            (acc: number, el: any) => acc + el.volume,
            0,
          ) -
          a.stacked_categories?.reduce(
            (acc: number, el: any) => acc + el.volume,
            0,
          )
        )
      }

      return b[sortField] - a[sortField]
    })
  }

  if (order === 'asc') {
    values = [...values].reverse()
  }
  return values
}

export const getOpenChildLabels = (
  currentChart: ValuesChart,
  expandedCategories: string[],
): string[] => {
  // for each expanded category, push the child labels into a list
  let openChildLabels: string[] = []
  if (currentChart.category_mapping && expandedCategories?.length) {
    expandedCategories?.forEach((category) => {
      openChildLabels = openChildLabels.concat(
        currentChart.category_mapping[category] ?? [],
      )
      openChildLabels = openChildLabels.concat(
        currentChart.category_mapping[category]?.map(
          (item) => item + ' Volume',
        ) ?? [],
      )
    })
  }
  return openChildLabels
}

// get values for not (Bar, Stack Bar, Matrix)
// type return is the values type for each chart type
export const getGroupedChartValues2 = (
  currentChart: ValuesChart,
  expandedCategories: string[],
  tier1: string,
  showCategories?: boolean,
  disableGrouping?: boolean,
): any[] => {
  if (!currentChart.values) return []
  // for each expanded category, push the child labels into a list
  const openChildLabels = getOpenChildLabels(currentChart, expandedCategories)

  let childValues = currentChart.values.filter((el) =>
    openChildLabels.includes(el.category),
  )

  // different default setting for theme vs product and brand
  const reactiveShowMyBrands =
    currentChart.category_mapping &&
    (showCategories === undefined ? tier1 === 'Theme' : showCategories)

  let values =
    currentChart.parent_values && reactiveShowMyBrands
      ? [...childValues, ...(currentChart.parent_values ?? [])]
      : currentChart.values

  if (disableGrouping) {
    values = currentChart.values
  }
  return values
}

export function alignRows(firstList: any, secondList: any) {
  const firstListMap = new Map()
  firstList.forEach((row: any) => {
    firstListMap.set(row.category, row)
  })

  secondList.sort((a: any, b: any) => {
    const aRow = firstListMap.get(a.category)
    const bRow = firstListMap.get(b.category)

    if (!aRow && !bRow) return 0
    if (!aRow) return 1
    if (!bRow) return -1

    return (
      Array.from(firstListMap.values()).indexOf(aRow) -
      Array.from(firstListMap.values()).indexOf(bRow)
    )
  })

  // sort subRows
  secondList.forEach((el: any) => {
    if (el.subRows?.length) {
      el.subRows = alignRows(
        firstListMap.get(el.category)?.subRows ?? [],
        el.subRows,
      )
    }
  })

  return secondList
}

export const interpolateSentimentColor = (
  value: number,
  isUnfocused: boolean,
): string => {
  const colorYellow = { r: 240, g: 228, b: 66 }
  const colorGreen = { r: 0, g: 158, b: 115 }
  const colorRed = { r: 213, g: 94, b: 0 }
  value = Math.max(-1, Math.min(value, 1))

  let r: number, g: number, b: number

  if (value < 0) {
    // Interpolate between red and yellow
    r = colorRed.r + (colorYellow.r - colorRed.r) * (value + 1)
    g = colorRed.g + (colorYellow.g - colorRed.g) * (value + 1)
    b = colorRed.b + (colorYellow.b - colorRed.b) * (value + 1)
  } else {
    // Interpolate between yellow and green
    r = colorYellow.r + (colorGreen.r - colorYellow.r) * value
    g = colorYellow.g + (colorGreen.g - colorYellow.g) * value
    b = colorYellow.b + (colorGreen.b - colorYellow.b) * value
  }

  return `rgb(${Math.round(r)}, ${Math.round(g)}, ${Math.round(b)}${
    isUnfocused ? ', 0.1' : ''
  })`
}

export const interpolateRatingColor = (
  value: number,
  isUnfocused: boolean,
): string => {
  const colorGreen = { r: 0, g: 158, b: 115 }
  const colorLightGreen = { r: 127, g: 198, b: 92 }
  const colorYellow = { r: 240, g: 228, b: 66 }
  const colorOrange = { r: 233, g: 162, b: 7 }
  const colorRed = { r: 213, g: 94, b: 0 }
  value = Math.max(0, Math.min(value, 5))

  let r: number, g: number, b: number
  let segment: number = Math.floor(value) // Determine the whole number part
  let interpolationFactor: number

  if (value < 1.5) {
    // Interpolate between colorRed and colorOrange
    interpolationFactor = value / 1.5
    r = colorRed.r + (colorOrange.r - colorRed.r) * interpolationFactor
    g = colorRed.g + (colorOrange.g - colorRed.g) * interpolationFactor
    b = colorRed.b + (colorOrange.b - colorRed.b) * interpolationFactor
  } else if (value < 2.5) {
    // Interpolate between colorOrange and colorYellow
    interpolationFactor = value - 1.5
    r = colorOrange.r + (colorYellow.r - colorOrange.r) * interpolationFactor
    g = colorOrange.g + (colorYellow.g - colorOrange.g) * interpolationFactor
    b = colorOrange.b + (colorYellow.b - colorOrange.b) * interpolationFactor
  } else if (value < 3.5) {
    // Interpolate between colorYellow and colorLightGreen
    interpolationFactor = value - 2.5
    r =
      colorYellow.r + (colorLightGreen.r - colorYellow.r) * interpolationFactor
    g =
      colorYellow.g + (colorLightGreen.g - colorYellow.g) * interpolationFactor
    b =
      colorYellow.b + (colorLightGreen.b - colorYellow.b) * interpolationFactor
  } else if (value < 5) {
    // Interpolate between colorLightGreen and colorGreen
    interpolationFactor = (value - 3.5) / 1.5
    r =
      colorLightGreen.r +
      (colorGreen.r - colorLightGreen.r) * interpolationFactor
    g =
      colorLightGreen.g +
      (colorGreen.g - colorLightGreen.g) * interpolationFactor
    b =
      colorLightGreen.b +
      (colorGreen.b - colorLightGreen.b) * interpolationFactor
  } else {
    // Value is at or above 4.5, use color5
    return `rgb(${colorGreen.r}, ${colorGreen.g}, ${colorGreen.b})`
  }

  return `rgb(${Math.round(r)}, ${Math.round(g)}, ${Math.round(b)}${
    isUnfocused ? ', 0.1' : ''
  })`
}

export const filterExpandedCategories = (
  currentChart: UnionChart,
  expandedCategories: string[],
) => {
  if (!currentChart?.category_mapping) return expandedCategories
  if ('category_mapping' in currentChart) {
    const expanded = new Set(expandedCategories)

    let filtered = Object.keys(currentChart.category_mapping).filter(
      (el) => expanded.has(el) || expanded.has(el.split(': ')[1]),
    )
    if (matrixChartIds.includes(currentChart.chart_id)) {
      const matrixKeys = Object.keys(currentChart.category_mapping).map((el) =>
        el.split(' - ')[0].trim(),
      )
      filtered = _.uniq(
        matrixKeys.filter(
          (el) => expanded.has(el) || expanded.has(el.split(': ')[1]),
        ),
      )
    }
    return filtered
  }
  return expandedCategories
}

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

const quarters = ['Q1', 'Q2', 'Q3', 'Q4']

export const getComparedTimePeriod = (
  currentPeriodLabel: string,
  comparePeriod?: string,
  timeInterval?: string,
) => {
  if (!currentPeriodLabel) return currentPeriodLabel

  if (timeInterval === 'year') {
    // period format is YYYY
    return `${Number(currentPeriodLabel) - 1}`
  } else if (timeInterval === 'quarter') {
    // period format is YYYY Q#
    if (comparePeriod === 'year') {
      const splitLabel = currentPeriodLabel.split(' ')
      const newYear = Number(splitLabel[0]) - 1
      return `${newYear} ${splitLabel[1]}`
    } else if (comparePeriod === 'half') {
      const splitLabel = currentPeriodLabel.split(' ')
      const year = Number(splitLabel[0])
      const quarter = splitLabel[1]
      if (quarter === 'Q1') {
        return `${year - 1} Q3`
      } else if (quarter === 'Q2') {
        return `${year - 1} Q4`
      } else {
        const quarterIndex = quarters.indexOf(quarter)
        return `${year} ${quarters[quarterIndex - 1]}`
      }
    } else if (comparePeriod === 'quarter') {
      const splitLabel = currentPeriodLabel.split(' ')
      const year = Number(splitLabel[0])
      const quarter = splitLabel[1]
      if (quarter === 'Q1') {
        return `${year - 1} Q4`
      } else {
        const quarterIndex = quarters.indexOf(quarter)
        return `${year} ${quarters[quarterIndex - 1]}`
      }
    }
  } else if (timeInterval === 'month' || timeInterval === null) {
    // period format is MMM 'YY
    if (comparePeriod === 'year') {
      const splitLabel = currentPeriodLabel.split(" '")
      const newYear = Number(splitLabel[1]) - 1
      return splitLabel[0] + " '" + newYear
    } else if (comparePeriod === 'half') {
      const splitLabel = currentPeriodLabel.split(" '")
      const month = splitLabel[0]
      const year = Number(splitLabel[1])
      const monthIndex = months.indexOf(month)
      if (monthIndex < 6) {
        return `${months[monthIndex + 6]} '${year - 1}`
      }
      return `${months[monthIndex - 6]} '${year}`
    } else if (comparePeriod === 'quarter') {
      const splitLabel = currentPeriodLabel.split(" '")
      const month = splitLabel[0]
      const year = Number(splitLabel[1])
      const monthIndex = months.indexOf(month)
      if (monthIndex < 3) {
        return `${months[monthIndex + 9]} '${year - 1}`
      }
      return `${months[monthIndex - 3]} '${year}`
    } else if (comparePeriod === 'month') {
      const splitLabel = currentPeriodLabel.split(" '")
      const month = splitLabel[0]
      const year = Number(splitLabel[1])
      const monthIndex = months.indexOf(month)
      if (monthIndex === 0) {
        return `${months[11]} '${year - 1}`
      }
      return `${months[monthIndex - 1]} '${year}`
    }
  } else {
    // period format is MM/DD/YYYY
    if (comparePeriod === 'year') {
      const splitLabel = currentPeriodLabel.split('/')
      const month = Number(splitLabel[0])
      const day = Number(splitLabel[1])
      const year = Number(splitLabel[2])
      const date = new Date(year, month - 1, day)
      date.setFullYear(date.getFullYear() - 1)
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    } else if (comparePeriod === 'quarter') {
      const splitLabel = currentPeriodLabel.split('/')
      const month = Number(splitLabel[0])
      const day = Number(splitLabel[1])
      const year = Number(splitLabel[2])
      const date = new Date(year, month - 1, day)
      date.setMonth(date.getMonth() - 3)
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    } else if (comparePeriod === 'month') {
      const splitLabel = currentPeriodLabel.split('/')
      const month = Number(splitLabel[0])
      const day = Number(splitLabel[1])
      const year = Number(splitLabel[2])
      const date = new Date(year, month - 1, day)
      date.setMonth(date.getMonth() - 1)
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    } else if (comparePeriod === 'week') {
      const splitLabel = currentPeriodLabel.split('/')
      const month = Number(splitLabel[0])
      const day = Number(splitLabel[1])
      const year = Number(splitLabel[2])
      const date = new Date(year, month - 1, day)
      date.setDate(date.getDate() - 7)
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    }
  }

  return currentPeriodLabel
}
