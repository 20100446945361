// SVGComponents.tsx
import React from 'react'

export const OrangeRadialSVG: React.FC = () => (
  <svg
    width="211"
    height="88"
    viewBox="0 0 211 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="9.10547"
      width="204"
      height="53"
      transform="rotate(9.89277 9.10547 0)"
      fill="url(#paint0_radial_2072_2697)"
    />
    <defs>
      <radialGradient
        id="paint0_radial_2072_2697"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(111.105 26.5) scale(102 26.5)"
      >
        <stop stopColor="#FE7056" />
        <stop offset="1" stopColor="#FF7055" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
)

export const BlueRadialSVG: React.FC = () => (
  <svg
    width="234"
    height="53"
    viewBox="0 0 234 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="234" height="53" fill="url(#paint0_radial_2072_2696)" />
    <defs>
      <radialGradient
        id="paint0_radial_2072_2696"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(117 26.5) scale(117 26.5)"
      >
        <stop stopColor="#5072A4" />
        <stop offset="1" stopColor="#5072A4" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
)

export const BubblesSVG: React.FC = () => (
  <svg
    width="29"
    height="26"
    viewBox="0 0 29 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5" filter="url(#filter0_d_2072_2698)">
      <circle
        cx="13.4775"
        cy="5.04632"
        r="3.78096"
        transform="rotate(25.6913 13.4775 5.04632)"
        fill="#FF7055"
      />
    </g>
    <g opacity="0.3" filter="url(#filter1_d_2072_2698)">
      <ellipse
        cx="10.7336"
        cy="11.5339"
        rx="5.98898"
        ry="5.39008"
        transform="rotate(25.6913 10.7336 11.5339)"
        fill="#5072A4"
      />
    </g>
    <g opacity="0.5" filter="url(#filter2_d_2072_2698)">
      <circle
        cx="19.0812"
        cy="9.80988"
        r="5.46139"
        transform="rotate(25.6913 19.0812 9.80988)"
        fill="#28364F"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2072_2698"
        x="5.6958"
        y="1.2644"
        width="15.5635"
        height="15.5637"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2072_2698"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2072_2698"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_2072_2698"
        x="0.851074"
        y="6.02515"
        width="19.7651"
        height="19.0173"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2072_2698"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2072_2698"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_2072_2698"
        x="9.61865"
        y="4.34717"
        width="18.9253"
        height="18.9253"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2072_2698"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2072_2698"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)

export const EnterArrowSVG: React.FC = () => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7368 0.666667V3.33333H2.41895L4.68 0.94L3.78947 0L0 4L3.78947 8L4.68 7.06L2.41895 4.66667H12V0.666667H10.7368Z"
      fill="currentColor"
    />
  </svg>
)
