import React, { useEffect, useMemo, useState } from 'react'
import { DashboardChartResponse } from 'features/project/features/Charts/types'
import {
  initialState,
  useProjectStore,
} from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'
import { useFeatureFlags } from 'features/project/hooks/useFeatureFlags'
import useResizeObserver from 'use-resize-observer'
import {
  AppliedFilterOptionSelect,
  FilterOptionSelect,
} from 'features/project/features/filters/types'
import _ from 'lodash'
import { emptyFilters } from 'features/project/utils'
import { useChartData } from 'features/project/hooks/useChartData'
import { HeaderFilterPanel } from 'features/project/features/filters'
import Superset from 'components/Superset/Superset'
import { isInitialFlow } from 'features/home/utils'
import { Loader } from 'shared/components'
import {
  Chart,
  ChartWrapper,
} from 'features/project/features/Charts/components/Chart/chart'
import { Fetcher } from 'shared/components/fetcher'
import styled from 'styled-components'
import { buttonBlue, cardBorder, panelHover } from 'assets/styles/variables'
import { DatasetSelectorView } from 'features/project/features/Charts/components/DatasetSelector/DatasetSelector'

type Props = {
  comparativeIndex: number
  data: DashboardChartResponse
  isMultiFetching: boolean
  isActive: boolean
  setActivePanel: (index: number) => void
  headerHeight: number
}

export const Panel: React.FC<Props> = ({
  comparativeIndex,
  isMultiFetching,
  isActive,
  setActivePanel,
  headerHeight,
}) => {
  const details = useProjectStore((state: ProjectState) => state.details)
  const comparativePanelsNumber = useProjectStore(
    (state: ProjectState) => state.comparativePanelsNumber,
  )
  const filterValues = useProjectStore(
    (state: ProjectState) => state.filters[comparativeIndex],
  )
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )
  const isComparative = useProjectStore(
    (state: ProjectState) => state.isComparative,
  )
  const updateCurrentChartList = useProjectStore(
    (state: ProjectState) => state.updateCurrentChartList,
  )
  const hasLoadedFilters = useProjectStore(
    (state: ProjectState) => state.hasLoadedFilters,
  )
  const clearOpenedOptions = useProjectStore(
    (state: ProjectState) => state.clearOpenedOptions,
  )
  const updateDashboardControls = useProjectStore(
    (state) => state.updateDashboardControls,
  )
  const updateChartData = useProjectStore((state) => state.updateChartData)
  const { tier1, chartId, postType, timeInterval, reviewsCount } =
    useProjectStore((state) => state.dashboardControls[0])

  const { data: feature_flags } = useFeatureFlags()

  const { ref: legendRef, width: legendWidth } =
    useResizeObserver<HTMLDivElement>()

  const { ref: splitRef, width: splitWidth } =
    useResizeObserver<HTMLDivElement>()

  const datasetOptions = useMemo(() => {
    return [
      ...((
        defaultFilterList.find(
          (el) => el.field === 'proj_uuid',
        ) as FilterOptionSelect
      )?.values.map((el) => ({ value: el.cluster_uuid, label: el.value })) ||
        []),
      { value: '', label: 'All' },
    ]
  }, [defaultFilterList])

  const [showSelectDataset, setShowSelectDataset] = useState(
    datasetOptions.length > 1 &&
      (_.isEqual(filterValues, initialState.filters[0]) ||
        _.isEqual(filterValues, emptyFilters)),
  )

  const selectedDataset = useMemo(
    () =>
      (
        filterValues?.values.find(
          (el) => el.field === 'proj_uuid',
        ) as AppliedFilterOptionSelect
      )?.values[0],
    [filterValues?.values],
  )
  useEffect(() => {
    if (selectedDataset) {
      setShowSelectDataset(false)
    }
  }, [selectedDataset])
  useEffect(() => {
    if (
      !(
        _.isEqual(filterValues, initialState.filters[0]) ||
        _.isEqual(filterValues, emptyFilters)
      )
    ) {
      setShowSelectDataset(false)
    }
  }, [filterValues])

  const { data: dashboardData, isFetching } = useChartData(
    comparativeIndex,
    filterValues,
    tier1,
    chartId,
    postType,
    timeInterval,
    reviewsCount,
    showSelectDataset && datasetOptions.length > 1,
  )

  const [initialLegendWidthPercent, setInitialLegendWidthPercent] = useState(0)

  useEffect(() => {
    if (isFetching) {
      setInitialLegendWidthPercent(
        legendWidth ? (legendWidth / (splitWidth || 1)) * 100 : 0,
      )
    }
  }, [isFetching])

  const data = dashboardData

  useEffect(() => {
    if (dashboardData) {
      updateChartData(comparativeIndex, dashboardData)
      if (comparativeIndex === 0) {
        if (!chartId) {
          updateDashboardControls(
            0,
            'chartId',
            dashboardData.charts[0].chart_id,
          )
        }
        // some options, such as brand, do not hold their lens value in the charts list. so we use this function to update the UI with the lens returned from the charts response
        // this causes a double fetch, TODO how to optimize here
        // https://linear.app/yo-yo-gi/issue/EFE-107/header-chart-list-some-dont-have-a-field-associated-with-them-to
        if (!tier1) {
          const value =
            dashboardData.tier1.options[dashboardData.tier1.default].name
          if (value) {
            updateDashboardControls(0, 'tier1', value)
          }
        }
      }
      updateCurrentChartList(comparativeIndex, dashboardData.charts[0])
    } else {
      updateCurrentChartList(comparativeIndex, undefined)
    }
  }, [dashboardData])

  // Whenever a filter is applied, opened options should be reset
  useEffect(() => {
    clearOpenedOptions(comparativeIndex)
  }, [filterValues])

  const setExpandedCategories = (categories: string[] | undefined) => {
    updateDashboardControls(comparativeIndex, 'expandedCategories', categories)
  }

  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (initialized) {
      setExpandedCategories([])
    } else {
      setInitialized(true)
    }
  }, [tier1])

  const showSupersetDatasetSelector =
    feature_flags?.superset &&
    !filterValues.values.find((el) => el.field === 'proj_uuid')

  if (showSupersetDatasetSelector) {
    return (
      <>
        <ChartsContainer
          style={{ maxHeight: `calc(100vh - 120px)` }}
          comparativePanelsNumber={comparativePanelsNumber}
          isActive={isActive}
        >
          <Superset comparativeIndex={comparativeIndex} />
        </ChartsContainer>
      </>
    )
  }

  if (
    isInitialFlow(details?.state) ||
    !defaultFilterList.length ||
    !hasLoadedFilters
  ) {
    return (
      <>
        <Loader />
        <LoaderTitle>{`Loading ${details?.clientSettings.project.pages.dashboard.alias} Page`}</LoaderTitle>
      </>
    )
  }

  return (
    <ChartsContainer
      comparativePanelsNumber={comparativePanelsNumber}
      isActive={isActive}
      onClick={() => setActivePanel(comparativeIndex)}
    >
      <ChartsWrapper
        chartType={data?.charts[0]?.chart_type}
        chartCount={data?.charts?.length || 1}
      >
        {showSelectDataset ? (
          <DatasetSelectorView
            comparativeIndex={comparativeIndex}
            filterValues={filterValues}
          />
        ) : (
          <>
            {!data ? (
              <ChartWrapper />
            ) : (
              <>
                {data?.charts?.map((chart) => (
                  <Chart
                    key={chart.chart_id}
                    filters={filterValues}
                    chart={chart}
                    comparativeIndex={comparativeIndex}
                    isComparative={isComparative}
                    legendRef={legendRef}
                    splitRef={splitRef}
                    initialLegendWidthPercent={initialLegendWidthPercent}
                    headerHeight={headerHeight}
                  />
                ))}
              </>
            )}
          </>
        )}
      </ChartsWrapper>
      {(isFetching || isMultiFetching) && (
        <LoadingLayer>
          <Fetcher />
        </LoadingLayer>
      )}
    </ChartsContainer>
  )
}

const LoadingLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 80px;
  background: rgba(255, 255, 255, 0.5);
`

const LoaderTitle = styled.div`
  text-align: center;
  font-size: var(--font-size-xxl);
  font-weight: 500;
`

export const ChartsContainer = styled.div<{
  comparativePanelsNumber: number
  isActive: boolean
}>`
  overflow: hidden;
  position: relative;
  flex: 1;
  overflow-y: auto;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  border: 2px solid ${cardBorder};
  border-radius: 10px;

  box-shadow: 0 4px 10px 0 #00000026;
  border-color: ${({ isActive }) => (isActive ? buttonBlue : cardBorder)};
  &:hover {
    border-color: ${({ comparativePanelsNumber, isActive }) =>
      comparativePanelsNumber > 1 && !isActive
        ? panelHover
        : isActive
          ? buttonBlue
          : cardBorder};
  }
`

export const ChartsWrapper = styled.div<{
  chartType?: string
  chartCount: number
}>`
  height: ${({ chartType }) =>
    chartType === 'numerical_charts' ? 'auto' : '100%'};
  grid-gap: 10px;
  display: ${({ chartType }) =>
    chartType === 'numerical_charts' ? 'flex' : 'block'};
  flex-direction: ${({ chartType }) =>
    chartType === 'numerical_charts' ? 'column' : 'row'};
  grid-template-columns: ${({ chartCount }) =>
    chartCount > 2 ? '1fr 1fr' : '1fr'};
`
