import React, { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useQuery, useQueryClient } from 'react-query'
import { useProjectStore } from '../../../../projectStore/projectStore'
import { fetchSharedUsers } from '../../../share/model'
import {
  AlertBody,
  AlertResponse,
  AlertType,
  saveAlert,
  updateAlert,
} from '../../model'
import mixpanel from '../../../../../trackers/mixpanel'
import AlertDefinition from './components/AlertDefinition'
import AlertCriteria from './components/AlertCriteria'
import AlertRecipients from './components/AlertRecipients'
import AlertName from './components/AlertName'
import { CreateAlertProps } from './types'
import {
  Container,
  Form,
  FormWrapper,
  Body,
  Footer,
  Section,
  SubmitButton,
  Overlay,
  StyledInputNumber,
  MinReviewWrapper,
} from './CreateAlert.styles'
import _, { debounce } from 'lodash'
import { Filters } from '../../../../types'
import { emptyFilters } from '../../../../utils'
import { conditionOptionsList, defaultValues } from '../../constants'
import { Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { DrawerLoader } from 'components/Loading/DrawerLoader/DrawerLoader'
import { InnerTitle } from 'components/InnerTitle'
import { HardcodedFilterOptionsEnum } from 'features/project/features/filters/helpers'

const CreateAlert: React.FC<CreateAlertProps> = ({
  alertData = { alert: undefined, owners: [], viewers: [] },
  setIsOpen,
  alertRequest,
}) => {
  const { alert, owners, viewers } = alertData
  const projectId = useProjectStore((state) => state.projectId)
  const details = useProjectStore((state) => state.details)
  const route = useProjectStore((state) => state.route)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [filterValues, setFilterValues] = useState<Filters>(
    alert
      ? {
          values: alert.filters,
          searchQuery: alert.search_terms,
          searchCondition: alert.search_criteria,
        }
      : alertRequest || _.cloneDeep(emptyFilters),
  )
  const [selectedTriggerCategory, setSelectedTriggerCategory] = useState(
    conditionOptionsList.find((el) => el.value === alert?.trigger_type)?.type ??
      'outlier',
  )
  const [recipients, setRecipients] = useState<string[]>(
    viewers.map((el) => el.email),
  )
  const isPublicViewer = details?.role === 'public_viewer'

  const queryClient = useQueryClient()

  const { data: sharedUsers } = useQuery(
    `shared-users-${projectId}`,
    () => fetchSharedUsers(projectId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId && !isPublicViewer,
    },
  )

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
    trigger,
  } = useForm<AlertType>({
    defaultValues: alert || defaultValues,
  })

  const onSubmit = async (data: AlertType) => {
    setIsSubmitting(true)
    const alertData: AlertBody = {
      ...data,
      search_terms: filterValues.searchQuery,
      search_criteria: filterValues.searchCondition,
      filters: filterValues.values.filter(
        (el) => el.field !== HardcodedFilterOptionsEnum.CREATE_TIME,
      ),
      shared_users: recipients,
      status: 'Live',
    }
    let message = 'Alert Created'
    try {
      let upsertedAlert: AlertResponse
      if (alert) {
        mixpanel.track('alerts', {
          action: 'edit',
          type: 'submit',
          value: 'new',
          ...route,
        })
        mixpanel.track('alerts', { action: 'update', value: alert?.id })
        message = 'Alert Updated'
        upsertedAlert = await updateAlert(projectId, { ...alert, ...alertData })
      } else {
        mixpanel.track('alerts', {
          action: 'create',
          type: 'submit',
          value: 'new',
          ...route,
        })
        upsertedAlert = await saveAlert(projectId, alertData)
      }
      queryClient.setQueryData(
        ['alerts', projectId],
        (oldData: AlertResponse[] | undefined) => {
          if (!oldData) return [upsertedAlert]

          const filteredData = oldData.filter((alert) => {
            return alert.alert.id !== upsertedAlert.alert.id
          })
          return [...filteredData, upsertedAlert]
        },
      )
      setIsSubmitting(false)
      setIsOpen(false)
      reset()
      setFilterValues(_.cloneDeep(emptyFilters))
      setRecipients([])
      // setSelectedTriggerCategory('outlier')
      toast.success(message)
    } catch (e) {
      toast.error(`Error ${alert ? 'updating' : 'creating'} alert`)
    }
  }

  const debouncedMixpanelTrack = useCallback(
    debounce((action, value) => {
      mixpanel.track('alerts', {
        action: action,
        value: value,
        proj_uuid: projectId,
      })
    }, 1000),
    [projectId],
  )

  return (
    <Container>
      {/*<Header>*/}
      {/*  <div*/}
      {/*    style={{*/}
      {/*      display: 'flex',*/}
      {/*      justifyContent: 'space-between',*/}
      {/*      alignItems: 'flex-start',*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Title level={4}>*/}
      {/*      {alertData.alert ? 'Edit Alert' : 'Create Alert'}*/}
      {/*    </Title>*/}
      {/*    <Title*/}
      {/*      level={4}*/}
      {/*      onClick={() => {*/}
      {/*        // TODO reset form if edit?*/}
      {/*        setIsOpen(false)*/}
      {/*        handleResetForm()*/}
      {/*      }}*/}
      {/*      style={{ fontWeight: 600, cursor: 'pointer', marginTop: 0 }}*/}
      {/*    >*/}
      {/*      X*/}
      {/*    </Title>*/}
      {/*  </div>*/}
      {/*</Header>*/}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormWrapper>
          <Body>
            <AlertDefinition
              filterValues={filterValues}
              setFilterValues={setFilterValues}
            />
            <AlertCriteria
              alert={alert}
              control={control}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              selectedTriggerCategory={selectedTriggerCategory}
              setSelectedTriggerCategory={setSelectedTriggerCategory}
              trigger={trigger}
            />
            <MinReviewWrapper>
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  color: 'rgba(0, 0, 0, .5)',
                }}
              >
                <div style={{ marginRight: 'auto' }}>Minimum Review Count</div>
                <Tooltip title="Alerts won't trigger if the number of reviews in period are lower than this number">
                  <a>
                    <InfoCircleOutlined
                      style={{ marginTop: 3, cursor: 'pointer' }}
                    />
                  </a>
                </Tooltip>
              </div>
              <Controller
                name="reviews_filter"
                control={control}
                render={({ field }) => (
                  // @ts-ignore - something about antd doesnt play nice with react-hook-form
                  <StyledInputNumber
                    {...field}
                    onChange={(e: any) => {
                      field.onChange(e)
                      debouncedMixpanelTrack('minimum review count', e)
                    }}
                    min={0}
                  />
                )}
              />
            </MinReviewWrapper>
          </Body>
          <Footer>
            <AlertName control={control} errors={errors} />
            <AlertRecipients
              recipients={recipients}
              setRecipients={setRecipients}
              sharedUsers={sharedUsers}
              owners={owners}
            />
            <Section
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                width: '100%',
              }}
            >
              <SubmitButton type={'submit'}>
                {alertData.alert ? 'Update' : 'Create'} Alert
              </SubmitButton>
            </Section>
          </Footer>
        </FormWrapper>
      </Form>
      {isSubmitting && (
        <Overlay>
          <DrawerLoader></DrawerLoader>
          <InnerTitle>{alert ? 'Updating' : 'Creating'} Alert...</InnerTitle>
        </Overlay>
      )}
    </Container>
  )
}

export default CreateAlert
