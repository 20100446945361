import {
  AppliedFilterOption,
  AppliedFilterOptionRange,
  AppliedFilterOptionSelect,
  ApplyFilterPayload,
  ApplyGroupFilterPayload,
  FilterOption,
  FilterOptionSelect,
  FilterOptionValue,
  FilterTypes,
} from '../../../../../../../types'
import Divider from 'components/UI/YogiDivider'
import { Checkbox, Radio, Slider, Spin } from 'antd'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { StyledSpin } from '../../FilterPanelSelector.styles'
import NestedThemes from '../../../NestedThemes/NestedThemes'
import VirtualList from '../VirtualList/VirtualList'
import ratingsAndReviews from 'assets/svg/RatingFilter/ReviewsAndRatings.svg'
import reviewOnly from 'assets/svg/RatingFilter/ReviewOnly.svg'
import ratingOnly from 'assets/svg/RatingFilter/RatingOnly.svg'
import { generateSliderMarks } from '../../../../../../../helpers'
import { Filters, ProjectState } from '../../../../../../../../../types'
import { checkIfFilterSelected, checkIfGroupFilterSelected } from '../../utils'
import { secondaryNavy } from 'assets/styles/variables'
import { useProjectStore } from '../../../../../../../../../projectStore/projectStore'
import FreeInput from './components/FreeInput/FreeInput'
import { useFeatureFlags } from 'features/project/hooks/useFeatureFlags'

const NULL_FIELD = 'Null'

type Props = {
  option: FilterOption
  search: string
  currentAppliedFilter: AppliedFilterOption | undefined
  apply: (value: ApplyFilterPayload['value']) => void
  applySelect: (value: ApplyGroupFilterPayload['value']) => void
  applyFreeInput: (value: ApplyGroupFilterPayload['value']) => void
  filterValues: Filters
  floatValues: boolean
  parentFilters?: Filters
  isUnlinked?: boolean
  propOption?: FilterOption
  isLoading: boolean
}

export const SelectorBody: React.FC<Props> = ({
  option,
  search,
  currentAppliedFilter,
  apply,
  applySelect,
  applyFreeInput,
  filterValues,
  floatValues,
  parentFilters,
  isUnlinked,
  propOption,
  isLoading,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)

  const { data: feature_flags } = useFeatureFlags()

  const dedupedList: FilterOptionValue[] = useMemo(() => {
    const dedupedValues: any = {}

    ;(option as FilterOptionSelect)?.values?.forEach((item: any) => {
      if (!dedupedValues[item.value]) {
        dedupedValues[item.value] = { n_posts: 0, identifiers: [] }
      }
      dedupedValues[item.value].n_posts += item.n_posts
      dedupedValues[item.value].identifiers.push(
        item.cluster_uuid ?? item.value,
      )
    })

    return (
      Object.keys(dedupedValues)
        .map((key) => ({
          value: key,
          n_posts: dedupedValues[key].n_posts,
          identifiers: dedupedValues[key].identifiers,
        }))
        // this is for the case where we have a parent filter selected and we want to limit the child filter to those options
        .filter((el) => {
          if (isUnlinked || !parentFilters) return true
          const parentFilter = parentFilters?.values.find(
            (parentFilter) => parentFilter.field === propOption?.field,
          ) as AppliedFilterOptionSelect | undefined
          // TODO any changes needed for window / non select filters?
          return !!(
            !parentFilter?.values ||
            // or parent filter includes this option
            parentFilter?.values?.includes(el?.value) ||
            // or current filter includes this option (useful for when adding link to filter that is outside parent, so you can see it to remove it)
            (
              currentAppliedFilter as AppliedFilterOptionSelect
            )?.values?.includes(el?.value) ||
            el.identifiers?.some((element: string) =>
              parentFilter.values.includes(element),
            )
          )
        })
    )
  }, [option, currentAppliedFilter])

  const checkedItems = useMemo(
    () =>
      dedupedList?.filter((item) =>
        checkIfGroupFilterSelected.checkbox(
          currentAppliedFilter as AppliedFilterOptionSelect,
          item.identifiers,
        ),
      ),
    [dedupedList, currentAppliedFilter, checkIfGroupFilterSelected],
  )

  const uncheckedItems = useMemo(
    () =>
      dedupedList?.filter(
        (item) =>
          !checkIfGroupFilterSelected.checkbox(
            currentAppliedFilter as AppliedFilterOptionSelect,
            item.identifiers,
          ),
      ),
    [dedupedList, currentAppliedFilter, checkIfGroupFilterSelected],
  )

  const filteredItems = useMemo(
    () =>
      uncheckedItems
        ?.filter((item) => item.n_posts !== 0)
        .sort((a, b) => b.n_posts - a.n_posts),
    [uncheckedItems],
  )

  const unfilteredItems = useMemo(
    () => uncheckedItems?.filter((item) => item.n_posts === 0),
    [uncheckedItems],
  )

  // Sometimes there can be filter values in a filter that were renamed or deleted. This function checks if the filter values in the filter are not in the filter options list
  const hasDeprecatedFilterValue = (
    filterValues: Filters,
    filterOption: FilterOptionSelect,
  ) => {
    return (
      filterValues.values.find(
        (el) => el.field === option.field,
      ) as AppliedFilterOptionSelect
    )?.values.some(
      (el) =>
        !filterOption.values
          .map((el) => el.cluster_uuid ?? el.value)
          .includes(el),
    )
  }

  const hasDeletedFilter =
    option.filter_type === FilterTypes.SELECT &&
    hasDeprecatedFilterValue(filterValues, option as FilterOptionSelect)
  const isSampleDash = details?.title?.includes('[Sample]')
  const isLLMDash = !!details.clientSettings['llm-dash']

  const disabled =
    feature_flags?.superset &&
    option.field === 'proj_uuid' &&
    checkedItems?.length >= 5

  return (
    <Option>
      {!option.filter_type && (
        <SpinWrapper>
          <StyledSpin />
        </SpinWrapper>
      )}
      {option.filter_type === FilterTypes.SELECT && (
        <>
          <Option>
            {option.field === `sentiment` && (
              <span style={{ marginBottom: '10px' }}>
                <Radio.Group
                  value={((currentAppliedFilter as AppliedFilterOptionSelect)
                    ?.values
                    ? (
                        currentAppliedFilter as AppliedFilterOptionSelect
                      ).values.includes('0')
                      ? '0'
                      : '1'
                    : '1'
                  ).toString()}
                  onChange={function (e: any) {
                    if (
                      !(
                        currentAppliedFilter as AppliedFilterOptionSelect
                      )?.values.includes('0') ||
                      e.target.value === '1'
                    ) {
                      apply('0')
                    }
                  }}
                  style={{ width: '100%' }}
                >
                  <RadioOption
                    selected={
                      ((currentAppliedFilter as AppliedFilterOptionSelect)
                        ?.values
                        ? (
                            currentAppliedFilter as AppliedFilterOptionSelect
                          ).values.includes('0')
                          ? '0'
                          : '1'
                        : '1'
                      ).toString() === '1'
                    }
                    value="1"
                  >
                    Sentence Sentiment
                  </RadioOption>
                  <RadioOption
                    selected={
                      ((currentAppliedFilter as AppliedFilterOptionSelect)
                        ?.values
                        ? (
                            currentAppliedFilter as AppliedFilterOptionSelect
                          ).values.includes('0')
                          ? '0'
                          : '1'
                        : '1'
                      ).toString() === '0'
                    }
                    value="0"
                  >
                    Review Sentiment
                  </RadioOption>
                </Radio.Group>
              </span>
            )}
            {option.field === 'theme' && (isSampleDash || isLLMDash) ? (
              <div style={{ marginTop: '10px' }}>
                <NestedThemes
                  themes={dedupedList.filter(
                    ({ value }) =>
                      value.toLowerCase().indexOf(search.toLowerCase()) !== -1,
                  )}
                  floatValues={floatValues}
                  applySelect={applySelect}
                  currentAppliedFilter={currentAppliedFilter}
                  isLoading={isLoading}
                />
              </div>
            ) : (
              <div style={{ marginTop: '10px' }}>
                {option.values.length > 0 ? (
                  <>
                    {(!!checkedItems.length || hasDeletedFilter) && (
                      <>
                        <OptionSelectLabel>
                          Applied ({checkedItems.length})
                        </OptionSelectLabel>
                        <VirtualList
                          items={checkedItems.filter(
                            ({ value }) =>
                              value
                                .toLowerCase()
                                .indexOf(search.toLowerCase()) !== -1,
                          )}
                          currentAppliedFilter={currentAppliedFilter}
                          applySelect={applySelect}
                          isLoading={isLoading}
                        ></VirtualList>
                        {/* How we can visualize items in the filter that are not in the filter list (deprecated values on saved views etc)*/}
                        {hasDeletedFilter && (
                          <>
                            <VirtualList
                              items={(
                                filterValues.values.find(
                                  (el) => el.field === option.field,
                                ) as AppliedFilterOptionSelect
                              )?.values
                                .filter(
                                  (el) =>
                                    !option.values
                                      .map((el) => el.cluster_uuid ?? el.value)
                                      .includes(el),
                                )
                                .map((value) => ({
                                  value: '[Deleted Value]',
                                  n_posts: 0,
                                  identifiers: [value],
                                }))}
                              currentAppliedFilter={currentAppliedFilter}
                              applySelect={applySelect}
                              isLoading={isLoading}
                            ></VirtualList>
                            <div style={{ padding: '0px 20px' }}>
                              * Deleted Values occur from project updates and
                              may cause issues creating charts. Removing the
                              Deleted Value is recommended.
                            </div>
                          </>
                        )}
                        <Divider />
                      </>
                    )}

                    <OptionSelectLabel style={{ paddingTop: '10px' }}>
                      Unselected ({option?.values.length - checkedItems.length})
                    </OptionSelectLabel>
                    {floatValues ? (
                      <>
                        <VirtualList
                          items={filteredItems.filter(
                            ({ value }) =>
                              value
                                .toLowerCase()
                                .indexOf(search.toLowerCase()) !== -1,
                          )}
                          currentAppliedFilter={currentAppliedFilter}
                          applySelect={applySelect}
                          disabled={disabled}
                          isLoading={isLoading}
                        ></VirtualList>
                        <VirtualList
                          items={unfilteredItems.filter(
                            ({ value }) =>
                              value
                                .toLowerCase()
                                .indexOf(search.toLowerCase()) !== -1,
                          )}
                          currentAppliedFilter={currentAppliedFilter}
                          applySelect={applySelect}
                          disabled={disabled}
                          isLoading={isLoading}
                        ></VirtualList>
                      </>
                    ) : (
                      <>
                        <VirtualList
                          items={uncheckedItems.filter(
                            ({ value }) =>
                              value
                                .toLowerCase()
                                .indexOf(search.toLowerCase()) !== -1,
                          )}
                          currentAppliedFilter={currentAppliedFilter}
                          applySelect={applySelect}
                          disabled={disabled}
                          isLoading={isLoading}
                        ></VirtualList>
                      </>
                    )}
                  </>
                ) : (
                  <FreeInput
                    applyFreeInput={applyFreeInput}
                    currentAppliedFilter={currentAppliedFilter}
                  />
                )}
              </div>
            )}
          </Option>
        </>
      )}
      {option.filter_type === FilterTypes.WINDOW && (
        <Option>
          {option.min === undefined ? (
            <SpinWrapper>
              <Spin />
            </SpinWrapper>
          ) : (
            <>
              {option.field === `score` && (
                <span>
                  <Radio.Group
                    disabled={
                      checkIfFilterSelected.checkbox(
                        currentAppliedFilter as AppliedFilterOptionSelect,
                        NULL_FIELD,
                      ) || option.min === undefined
                    }
                    value={((currentAppliedFilter as AppliedFilterOptionRange)
                      ?.value_type
                      ? (currentAppliedFilter as AppliedFilterOptionRange)
                          .value_type
                      : 0
                    ).toString()}
                    onChange={function (e: any) {
                      apply({
                        min: (currentAppliedFilter as AppliedFilterOptionRange)
                          ?.min
                          ? (currentAppliedFilter as AppliedFilterOptionRange)
                              ?.min
                          : option?.cur_min || option.min,
                        max: (currentAppliedFilter as AppliedFilterOptionRange)
                          ?.max
                          ? (currentAppliedFilter as AppliedFilterOptionRange)
                              ?.max
                          : option?.cur_max || option.max,
                        value_type: parseInt(e.target.value),
                      })
                    }}
                    style={{ width: '100%' }}
                  >
                    <RadioOption
                      selected={
                        ((currentAppliedFilter as AppliedFilterOptionRange)
                          ?.value_type
                          ? (currentAppliedFilter as AppliedFilterOptionRange)
                              .value_type
                          : 0
                        ).toString() === '0'
                      }
                      value="0"
                    >
                      Reviews & Ratings
                      {((currentAppliedFilter as AppliedFilterOptionRange)
                        ?.value_type
                        ? (currentAppliedFilter as AppliedFilterOptionRange)
                            .value_type
                        : 0
                      ).toString() === '0' && (
                        <div>
                          <p style={{ fontWeight: 400 }}>
                            We’ll show you reviews with ratings, reviews without
                            ratings, and ratings without reviews.
                          </p>
                          <div style={{ display: 'flex', gap: 10 }}>
                            <img
                              alt={'card displaying ratings and reviews'}
                              src={ratingsAndReviews}
                            />
                            <img
                              alt={'card displaying reviews only'}
                              src={reviewOnly}
                            />
                            <img
                              alt={'card displaying ratings only'}
                              src={ratingOnly}
                            />
                          </div>
                        </div>
                      )}
                    </RadioOption>
                    <RadioOption
                      selected={
                        ((currentAppliedFilter as AppliedFilterOptionRange)
                          ?.value_type
                          ? (currentAppliedFilter as AppliedFilterOptionRange)
                              .value_type
                          : 0
                        ).toString() === '1'
                      }
                      value="1"
                    >
                      Reviews Only
                      {((currentAppliedFilter as AppliedFilterOptionRange)
                        ?.value_type
                        ? (currentAppliedFilter as AppliedFilterOptionRange)
                            .value_type
                        : 0
                      ).toString() === '1' && (
                        <div>
                          <p style={{ fontWeight: 400 }}>
                            We’ll show you reviews with ratings and reviews
                            without ratings, but won’t show ratings without
                            reviews.
                          </p>
                          <div style={{ display: 'flex', gap: 10 }}>
                            <img
                              alt={'card displaying ratings and reviews'}
                              src={ratingsAndReviews}
                            />
                            <img
                              alt={'card displaying reviews only'}
                              src={reviewOnly}
                            />
                          </div>
                        </div>
                      )}
                    </RadioOption>
                    <RadioOption
                      selected={
                        ((currentAppliedFilter as AppliedFilterOptionRange)
                          ?.value_type
                          ? (currentAppliedFilter as AppliedFilterOptionRange)
                              .value_type
                          : 0
                        ).toString() === '2'
                      }
                      value="2"
                    >
                      Ratings Only
                      {((currentAppliedFilter as AppliedFilterOptionRange)
                        ?.value_type
                        ? (currentAppliedFilter as AppliedFilterOptionRange)
                            .value_type
                        : 0
                      ).toString() === '2' && (
                        <div>
                          <p style={{ fontWeight: 400 }}>
                            We’ll show you ratings without reviews.
                          </p>
                          <div style={{ display: 'flex', gap: 10 }}>
                            {/*<img src={ratingsAndReviews} />*/}
                            <img
                              alt={'card displaying ratings only'}
                              src={ratingOnly}
                            />
                          </div>
                        </div>
                      )}
                    </RadioOption>
                  </Radio.Group>{' '}
                </span>
              )}
              <StyledSlider
                max={option.max}
                min={option.min}
                step={option.step}
                included={true}
                // marks={generateSliderMarks(option.min, option.max, option.step)}
                value={
                  checkIfFilterSelected.slider(
                    currentAppliedFilter as AppliedFilterOptionRange,
                  ) ||
                  (option.cur_min && option.cur_max
                    ? [option.cur_min, option.cur_max]
                    : [1, 5])
                }
                onChange={(values) => {
                  if (
                    !checkIfFilterSelected.checkbox(
                      currentAppliedFilter as AppliedFilterOptionSelect,
                      NULL_FIELD,
                    )
                  ) {
                    apply({
                      min: values[0],
                      max: values[1],
                      value_type: (
                        currentAppliedFilter as AppliedFilterOptionRange
                      )?.value_type
                        ? (currentAppliedFilter as AppliedFilterOptionRange)
                            ?.value_type
                        : 0,
                    })
                  }
                }}
                range
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  margin: '0px 40px 0px 30px',
                }}
              >
                <span>{option.min}</span> <span>{option.max}</span>
              </div>
              {option.Null > 0 && (
                <StyledCheckbox
                  key="Null"
                  checked={checkIfFilterSelected.checkbox(
                    currentAppliedFilter as AppliedFilterOptionSelect,
                    NULL_FIELD,
                  )}
                  onChange={() => apply(NULL_FIELD)}
                >
                  {option.field === 'score'
                    ? 'Reviews with no star rating'
                    : 'No Value'}{' '}
                  ({option.Null})
                </StyledCheckbox>
              )}
            </>
          )}
        </Option>
      )}
    </Option>
  )
}
export default SelectorBody

const Option = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  //margin-bottom: var(--default-padding-half);
  overflow: auto;
  font-size: var(--font-size-md);

  //&:first-child {
  //  padding-top: calc(var(--default-padding-half) / 2);
  //}
  //
  //&:last-child {
  //  padding-bottom: calc(var(--default-padding-half) / 2);
  //}
`
const StyledCheckbox = styled(Checkbox)`
  margin: 0 !important;
  display: flex;
  padding: 3px 20px;
  --antd-wave-shadow-color: var(--secondary-navy);
`
const SpinWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: calc(var(--default-padding-double) * 2);
`
const StyledSlider = styled(Slider)`
  margin: 20px 40px 10px;

  .ant-slider-rail {
    height: 3px;
  }

  .ant-slider-track {
    height: 3px;
    background-color: ${secondaryNavy};
  }

  .ant-slider:hover .ant-slider-track {
    background-color: ${secondaryNavy};
  }

  .ant-slider:hover .ant-slider-handle {
    border-color: ${secondaryNavy};
  }

  .ant-slider-handle {
    width: 22px;
    height: 22px;
    //margin-top: -9px;
    border-width: 1px;
    border-color: ${secondaryNavy};

    // &::before {
    //   content: '|||';
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   width: 20px;
    //   height: 18px;
    //   text-align: center;
    //   font-size: var(--font-size-md);
    //   transition: color 0.1s linear;
    //   color: ${secondaryNavy};
    //   margin-top: -2px;
    //   margin-left: -2px;
    //   z-index: 10;
    //
    //   &:hover {
    //     background-color: #91d5ff;
    //   }
    // }
  }

  &:hover {
    .ant-slider-handle::before {
      color: ${secondaryNavy};
    }
  }
`
const RadioOption = styled(Radio)<{ selected: boolean }>`
  cursor: pointer;
  user-select: none;
  padding: 12px 20px 12px 24px;
  background: ${(props) => (props.selected ? '#e7edf0' : '#fff')};
  font-weight: ${(props) => (props.selected ? '600' : '400')};
  font-size: var(--font-size-md);
  border-bottom: 1px solid #e7edf0;
  display: flex;

  &:hover {
    background: #e7edf0;
  }
`

const OptionSelectLabel = styled.div`
  padding: 0 20px 5px;
  /* Small Italic */
  font-family: 'Poppins', serif;
  font-style: italic;
  font-weight: 400;
  font-size: var(--font-size-md);
  line-height: 130%;
  /* identical to box height, or 16px */

  /* Dark Gray */

  color: #8a8c8f;
`
