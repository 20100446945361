import styled from 'styled-components'
import {
  lightBlueFontColor,
  selectableCardBackgroundNotSelected,
  selectableCardBackgroundSelected,
  selectableCardBorderNotSelected,
  selectableCardBorderSelected,
} from 'assets/styles/variables'

export const Container = styled.div<{ isSelected: boolean }>`
  flex-basis: calc(50% - 8px);
  //flex-grow: 1;
  padding: 20px;
  background-color: ${selectableCardBackgroundNotSelected};
  border: 2px solid ${selectableCardBorderNotSelected};
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 340px;

  &:hover {
    border-color: ${selectableCardBorderSelected};
    background-color: ${selectableCardBackgroundSelected};
  }

  transition: all 0.15s ease-in-out;
`

export const Header = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  color: ${({ isSelected }) =>
    isSelected ? lightBlueFontColor : lightBlueFontColor};
`

export const Title = styled.div`
  font-size: 22px;
  font-weight: 600;
  //line-height: 1.75;
  line-height: 30px;
  flex-grow: 2;
`

export const Info = styled.div<{ isSelected: boolean }>`
  display: flex;
  //flex-direction: column;
  //justify-content: space-between;
  gap: 10px;
  font-size: 12px;
  color: ${({ isSelected }) => (isSelected ? '#fff' : '#797b7f')};
`

export const Tags = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: start;
  gap: 5px;
  font-size: 12px;
  //color: ${({ isSelected }) => (isSelected ? '#fff' : '#797b7f')};
`

export const InfoItem = styled.div`
  flex-basis: 50%;
`
