import React, { useContext, useEffect } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import useResizeObserver from 'use-resize-observer'
import { LogoSpinner } from 'components/Loading/LogoSpinner/LogoSpinner'
import { isInitialFlow } from '../../../home/utils'
import { fetchProjects, getCompassDetails } from '../../model'
import { useProjectStore } from '../../projectStore/projectStore'
import { ProjectState } from '../../types'
import { FilterOptionDate, FilterOptionSelect } from '../filters/types'
import { getSavedViews } from '../saved-vews/model'
import ReviewComponent from 'features/project/features/Home/components/ReviewComponent/ReviewComponent'
import { Header } from './components/Header/Header'
import {
  formatDateToUTCEndOfDay,
  formatDateToUTCMidnight,
} from '../filters/helpers'
import moment from 'moment/moment'
import { emptyFilters, getFromLS, saveToLS } from '../../utils'
import { DatasetSelector } from 'features/project/features/Home/components/DatasetSelector/DatasetSelector'
import { home, projectURL } from 'utils/links'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'
import { AuthContext } from 'features/auth'
import { Badge, Button, Modal } from 'antd'
import GenericSvgIcon from 'components/GenericSvgIcon/GenericSvgIcon'
import AdminHomeSvgIcon from 'features/project/features/Home/components/Icons/AdminHomeSvgIcon/AdminHomeSvgIcon'
import mixpanel from 'features/trackers/mixpanel'
import { Notifications } from '@mui/icons-material'
import {
  buttonBlue,
  buttonHover,
  navDefaultIconColor,
} from 'assets/styles/variables'
import { HubSelector } from 'features/project/features/Home/components/HubSelector/HubSelector'
import { SuggestedViewsButton } from 'features/project/features/SuggestedViews/SuggestedViewsButton/SuggestedViewsButton'
import { Loader } from 'shared/components'

const { confirm } = Modal

export const Home: React.FC = () => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )
  const updateFilterValues = useProjectStore(
    (state: ProjectState) => state.updateFilterValues,
  )
  const notifications = useProjectStore(
    (state: ProjectState) => state.localNotifications,
  )
  const notificationsIsOpen = useProjectStore(
    (state: ProjectState) => state.notificationsIsOpen,
  )
  const setNotificationsIsOpen = useProjectStore(
    (state: ProjectState) => state.setNotificationsIsOpen,
  )

  const history = useHistory()
  const { ref } = useResizeObserver<HTMLDivElement>()
  const authContext = useContext(AuthContext)

  // local state
  const [dataset, setDataset] = React.useState<string | undefined>(
    // may want to check filterValues for proj_uuid first
    getFromLS(`defaultDataset-${projectId}`) || undefined,
  )

  const hasDatasets =
    (
      defaultFilterList?.find(
        (el) => el.field === 'proj_uuid',
      ) as FilterOptionSelect
    )?.values.length > 1

  useEffect(() => {
    if (hasDatasets) {
      const storedDataset = getFromLS(`defaultDataset-${projectId}`)
      if (storedDataset) {
        // updateFilterValues(0, 'proj_uuid', [storedDataset])
      } else {
        // select first dataset if
        const defaultDataset = (
          defaultFilterList?.find(
            (el) => el.field === 'proj_uuid',
          ) as FilterOptionSelect
        )?.values?.[0]?.cluster_uuid
        // saveToLS(`defaultDataset-${projectId}`, defaultDataset)
        // updateFilterValues(0, 'proj_uuid', [defaultDataset])
        setDataset(defaultDataset)
      }
    }
  }, [hasDatasets])

  // api
  const { isLoading, data } = useQuery(
    ['home', projectId],
    () => getCompassDetails(projectId, emptyFilters),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!projectId,
    },
  )

  const { data: savedViews } = useQuery(
    ['saved-views', projectId],
    () => getSavedViews(projectId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )

  const { data: projectsData } = useQuery(
    ['project-search'],
    () => fetchProjects(),
    { refetchOnWindowFocus: false },
  )

  const projects = projectsData?.data

  // constants
  let greeting
  const today = new Date()
  const curHr = today.getHours()
  if (curHr < 12) {
    greeting = 'Good Morning'
  } else if (curHr < 18) {
    greeting = 'Good Afternoon'
  } else {
    greeting = 'Good Evening'
  }

  const min = (
    defaultFilterList?.find(
      (el) => el.field === 'create_time',
    ) as FilterOptionDate
  )?.min

  const max = (
    defaultFilterList?.find(
      (el) => el.field === 'create_time',
    ) as FilterOptionDate
  )?.max

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }
  const startDate = new Date(
    formatDateToUTCEndOfDay(moment.unix(min)).unix() * 1000,
  ).toLocaleString('en-US', options)
  const endDate = new Date(
    formatDateToUTCMidnight(moment.unix(max)).unix() * 1000,
  ).toLocaleString('en-US', options)

  if (
    isInitialFlow(details?.state) ||
    isLoading ||
    !data ||
    !savedViews ||
    !min ||
    !max ||
    !greeting ||
    !defaultFilterList.length
  ) {
    return (
      <LoadingWrapper>
        <Loader />
        <LoaderTitle>Loading Homepage</LoaderTitle>
      </LoadingWrapper>
    )
  }

  return (
    <Container>
      <Wrapper>
        <Controls>
          <div style={{ display: 'flex', gap: 10 }}>
            {projects && projects.length > 1 && <HubSelector />}
            {hasDatasets && (
              <DatasetSelector dataset={dataset} setDataset={setDataset} />
            )}
          </div>
          <ControlButtons>
            {authContext?.userDetails?.yogi_user ||
            authContext?.userDetails?.email?.includes('@churchdwight.com') ? (
              <StyledLink
                to={
                  !!authContext?.userDetails?.yogi_user ||
                  authContext?.userDetails?.email?.includes('@churchdwight.com')
                    ? home
                    : ''
                }
                onClick={(e) => {
                  e.preventDefault()
                  confirm({
                    content: 'Do you want to return to All Projects?',
                    onOk() {
                      history.push(home)
                    },
                    onCancel() {},
                  })
                }}
              >
                <GenericSvgIcon SvgComponent={AdminHomeSvgIcon} />
              </StyledLink>
            ) : (
              <StyledLink
                to={window.location.pathname}
                onClick={(e) => {
                  e.preventDefault()
                  history.push(projectURL(projectId))
                }}
              >
                <GenericSvgIcon SvgComponent={AdminHomeSvgIcon} />
              </StyledLink>
            )}
            <SuggestedViewsButton></SuggestedViewsButton>
            <NotificationsButton
              onClick={() => {
                mixpanel.track('open notifications')
                setNotificationsIsOpen(!notificationsIsOpen)
              }}
              style={{
                color:
                  notifications?.filter?.((el) => !el.acknowledged_at).length >
                  0
                    ? 'rgba(232,242,255,1)'
                    : 'rgba(232,242,255,0.5)',
              }}
            >
              <NotificationsCount>
                {notifications?.filter?.((el) => !el.acknowledged_at).length}
              </NotificationsCount>
            </NotificationsButton>
          </ControlButtons>
        </Controls>
        <Header
          data={data}
          resizeRef={ref}
          greeting={greeting}
          startDate={startDate}
          endDate={endDate}
        />
        <ReviewComponent dataset={dataset} />
        {/*<Content*/}
        {/*  data={data}*/}
        {/*  savedViews={savedViews}*/}
        {/*  headerHeight={height}*/}
        {/*  filterList={defaultFilterList}*/}
        {/*/>*/}
      </Wrapper>
    </Container>
  )
}

const Container = styled.div`
  margin: -35px 0 0 35px;
  padding-top: 35px;
  //padding-right: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: calc(100% + 35px);
  width: calc(100% - 35px);
  max-height: 100vh;
  background: var(--color-background-grey);
  background: var(--card-background-white);
  overflow: auto;
`

const Wrapper = styled.div`
  padding-right: 50px;
`

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1400px;
  padding-right: 10px;
`

const ControlButtons = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  color: ${navDefaultIconColor};
`

const StyledLink = styled(Link)`
  color: ${navDefaultIconColor};
  text-decoration: none;
  &:hover {
    color: ${buttonHover};
    text-decoration: none;
  }
`

const NotificationsButton = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: ${buttonBlue};
  color: white;
  border-radius: 50%;
  display: flex;

  font-size: var(--font-size-md);
  //line-height: 12px;

  justify-content: center;
  align-items: center;

  &:hover {
    background: ${buttonHover};
  }
`

const NotificationsCount = styled.div`
  font-size: 11px;
  line-height: 11px;
`

const LoadingWrapper = styled.div`
  margin-top: 100px;
`

const LoaderTitle = styled.div`
  text-align: center;
  font-size: var(--font-size-xxl);
  font-weight: 500;
  margin-top: 8px;
`
