import React from 'react'
import styled from 'styled-components'
import { Checkbox, Input, Tag, Button, Radio } from 'antd'
import { useClickOutside } from 'shared/hooks'
import { CopyOutlined, SearchOutlined } from '@ant-design/icons'
import { Filters } from '../../../../../types'
import { YogiButton } from 'components/UI/YogiButton'
import {
  buttonBlue,
  searchClearBackground,
  searchClearBackgroundHover,
  searchClearColor,
} from 'assets/styles/variables'
import { useSearchPanel } from 'features/project/features/filters/SearchButton/hooks/useSearchPanel'

type Props = {
  closePanel: () => void
  comparativeIndex: number
  filterValues: Filters
  updateLocalFilterValues?: (filterValues: Filters) => void
  setIsFilterUnapplied?: (isFilterUnapplied: boolean) => void
  isKeywordsOnly?: boolean
}

export const SemanticSearchPanel: React.FC<Props> = ({
  closePanel,
  comparativeIndex,
  filterValues,
  updateLocalFilterValues,
  setIsFilterUnapplied,
  isKeywordsOnly,
}) => {
  // refs
  const panelRef = React.createRef<HTMLDivElement>()

  // hooks
  const {
    isSemantic,
    value,
    words,
    setWords,
    joinValue,
    not,
    handleSemanticSearch,
    handleSemanticPressEnter,
    handleClassicPressEnter,
    handleClassicKeyDown,
    handleSetIsSemantic,
    handleSetIsNotSemantic,
    handleSetValue,
    handleSetJoin,
    handleSetNot,
    handleSearch,
    handleClear,
    handleCopyToClipboard,
  } = useSearchPanel({
    filterValues,
    comparativeIndex,
    updateLocalFilterValues,
    closePanel,
    setIsFilterUnapplied,
    isKeywordsOnly,
  })

  useClickOutside(panelRef, () => closePanel())

  return (
    <Wrapper ref={panelRef}>
      {!isKeywordsOnly && (
        <ButtonWrapper>
          <YogiButton
            type={isSemantic ? 'primary' : 'default'}
            onClick={handleSetIsSemantic}
            style={{
              borderColor: isSemantic ? 'black' : 'inherit',
              borderWidth: 1,
            }}
          >
            <SearchOutlined />
            <SearchButtonText>Smart Search</SearchButtonText>
          </YogiButton>
          <YogiButton
            type={isSemantic ? 'default' : 'primary'}
            onClick={handleSetIsNotSemantic}
            style={{
              borderColor: isSemantic ? 'inherit' : 'black',
              borderWidth: 1,
            }}
          >
            <div>
              Only <Bold>Keywords</Bold>
            </div>
          </YogiButton>
        </ButtonWrapper>
      )}
      <SearchWrapper>
        <SearchContent>
          {isSemantic ? (
            <>
              <StyledInput
                placeholder={'Start typing here'}
                value={value}
                onChange={handleSetValue}
                onPressEnter={handleSemanticPressEnter}
              />
              {!!value.length && (
                <ClearButton onClick={handleClear}>clear</ClearButton>
              )}
            </>
          ) : (
            <>
              {words.map((word) => (
                <StyledTag
                  key={word}
                  closable
                  onClose={() => setWords(words.filter((w) => w !== word))}
                >
                  {word}
                </StyledTag>
              ))}
              <StyledInput
                type="text"
                value={value}
                onChange={handleSetValue}
                onPressEnter={handleClassicPressEnter}
                onKeyDown={handleClassicKeyDown}
                placeholder="Start typing here..."
              />
            </>
          )}
        </SearchContent>
      </SearchWrapper>

      {isSemantic ? (
        <CheckboxWrapper style={{ justifyContent: 'space-between' }}>
          <SearchTip>e.g. "confusing instructions"</SearchTip>
          <YogiButton
            type={'primary'}
            style={{ height: 46 }}
            onClick={handleSemanticSearch}
          >
            Submit
          </YogiButton>
        </CheckboxWrapper>
      ) : (
        <CheckboxWrapper>
          <JoinOptionList>
            <JoinOption
              selected={joinValue === 'or' || !joinValue}
              onClick={() => handleSetJoin('or')}
            >
              OR
            </JoinOption>
            <JoinOption
              selected={joinValue === 'and'}
              onClick={() => handleSetJoin('and')}
            >
              AND
            </JoinOption>
            <JoinOption
              selected={joinValue === 'not'}
              onClick={() => handleSetJoin('not')}
            >
              NOT
            </JoinOption>
          </JoinOptionList>

          <ButtonsWrapper>
            <Button
              type="primary"
              danger
              style={{
                borderRadius: '8px',
                borderWidth: '2px',
                height: 46,
              }}
              disabled={!words.length && !value.length}
              onClick={handleClear}
            >
              Clear
            </Button>
            <YogiButton
              type="primary"
              onClick={handleSearch}
              style={{ height: 46 }}
            >
              Search
            </YogiButton>
          </ButtonsWrapper>
        </CheckboxWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 12px 21px 15px 12px;
  z-index: 101;
`
const StyledInput = styled(Input)`
  height: 100%;
  border: none;
  min-width: 100px;
  width: auto;
  flex: 1;
  background: transparent !important;
  font-size: 12px;
  color: ${buttonBlue};

  &:focus {
    box-shadow: none;
  }

  ::placeholder {
    color: #5072a4;
  }
`
const SearchWrapper = styled.div`
  display: flex;
  min-height: 46px;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;
  overflow: hidden;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  margin-bottom: 12px;
  background: #f4f9ff;
`

const SearchContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  row-gap: 5px;
  align-items: center;

  .ant-tag {
    display: flex;
    align-items: center;
  }
`
const CheckboxWrapper = styled.div`
  //padding: 12px 8px 0px;
  display: flex;
  align-items: center;
`
const ButtonsWrapper = styled.div`
  margin-left: auto;
  display: flex;

  & > * {
    margin-left: var(--default-padding-half);
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: 13px;
  align-items: center;
  margin-bottom: 14px;
`

const Bold = styled.span`
  font-weight: 500;
`

const SemanticSearchWrapper = styled.div`
  margin-bottom: 22px;
  position: relative;
`
const SemanticInput = styled(Input)`
  border-radius: 10px;
  font-size: 12px;
  padding: 8px 12px;
`

const SearchTip = styled.div`
  font-size: 12px;
  color: ${buttonBlue};
`

const ClearButton = styled.div`
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-75%);
  cursor: pointer;
  background: ${searchClearBackground};
  color: ${searchClearColor};
  font-size: 8px;
  font-weight: 600;
  padding: 6px 20px;
  border-radius: 4px;

  &:hover {
    background: ${searchClearBackgroundHover};
  }
`

const SearchButtonText = styled.div`
  margin-right: 8px;
`
const StyledTag = styled(Tag)`
  background: white;
  border-radius: 23px;
  padding: 0 8px 0 12px;
  border: 1px solid ${buttonBlue};
  .anticon-close {
    padding-left: 3px;
  }
`

const JoinOptionList = styled.div`
  //padding: 12px 8px 0px;
  display: flex;
  align-items: center;
  gap: 8px;
`
const JoinOption = styled.div<{ selected: boolean }>`
  font-size: 12px;
  font-weight: 600;

  padding: 10px 16px;
  border-radius: 10px;

  background: #f7f7f7;
  color: #c6c6c6;

  cursor: pointer;

  ${({ selected }) =>
    selected &&
    `
    background: #e9f2fe;
    color: ${buttonBlue};
  `}

  &:hover {
    background: #e9f2fe;
    color: ${buttonBlue};
  }
`
