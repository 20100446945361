import React, { useState } from 'react'
import { Section } from '../../InsightDetail'
import styled from 'styled-components'
import {
  buttonBlue,
  darkBlueFontColor,
  mainText,
  notInterestedButtonBackground,
  notInterestedButtonBackgroundHover,
  notInterestedButtonColor,
  primaryOrange,
} from 'assets/styles/variables'
import { OutlierType } from 'features/project/features/Insights/model'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'
import { toast } from 'react-toastify'
import { getFromLS, saveToLS } from 'features/project/utils'
import mixpanel from 'features/trackers/mixpanel'
import { getOutlierTitle } from 'features/project/features/Insights/utils'

type Props = {
  outlier: OutlierType
}

export const NotInterested: React.FC<Props> = ({ outlier }) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)

  const [isNotInterested, setIsNotInterested] = useState(
    !!getFromLS(`${projectId}_notInterested`)?.includes(outlier.uuid),
  )

  const handleNotInterested = () => {
    mixpanel.track('insights feed', {
      action: 'not interested',
      value: getOutlierTitle(outlier),
      context: outlier.context_with_value?.context,
      context_value: outlier.context_with_value?.value,
      theme: outlier.theme,
    })
    setIsNotInterested((prev) => !prev)
    const notInterestedList = getFromLS(`${projectId}_notInterested`)
    if (notInterestedList) {
      saveToLS(`${projectId}_notInterested`, [
        ...notInterestedList,
        outlier.uuid,
      ])
    } else {
      saveToLS(`${projectId}_notInterested`, [outlier.uuid])
    }
    toast.success(
      'Insight will be removed from your feed. Thanks for the feedback!',
    )
  }

  return (
    <Container>
      <NotInterestedButton onClick={handleNotInterested}>
        <div>Not Interested</div>
        <div>{isNotInterested && '✔'}</div>
      </NotInterestedButton>
    </Container>
  )
}

const Container = styled.div`
  padding: 0 20px;
`
const NotInterestedButton = styled.div`
  width: fit-content;

  display: flex;
  align-items: center;
  gap: 10px;

  padding: 5px 15px;
  border-radius: 20px;

  font-size: 8px;

  background: ${notInterestedButtonBackground};
  color: ${notInterestedButtonColor};

  cursor: pointer;

  &:hover {
    background: ${notInterestedButtonBackgroundHover};
  }
  transition: all 0.2s ease-in-out;
`
