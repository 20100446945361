import React, { useRef } from 'react'
import styled from 'styled-components'
import { AlertResponse, getAlerts } from '../model'
import ShareAlert from './ShareAlert'
import ViewAlert from './ViewAlert'
import CreateAlert from './CreateAlert/CreateAlert'
import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'
import { useQuery } from 'react-query'

type Props = {
  alertData?: AlertResponse
  isShare?: boolean
  isView?: boolean
}
export const AlertDrawer: React.FC<Props> = ({
  alertData,
  isShare,
  isView,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const route = useProjectStore((state: ProjectState) => state.route)
  const isAlertDrawerOpen = useProjectStore(
    (state: ProjectState) => state.isAlertDrawerOpen,
  )
  const setIsAlertDrawerOpen = useProjectStore(
    (state: ProjectState) => state.setIsAlertDrawerOpen,
  )
  const alertRequest = useProjectStore(
    (state: ProjectState) => state.alertRequest,
  )

  const ref = useRef<HTMLDivElement>(null)

  // current config for alerts requires refreshing the list of alerts after create
  const { refetch } = useQuery(
    ['alerts', projectId],
    () => {
      return getAlerts(projectId)
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId,
    },
  )

  return (
    <>
      <OverlayContainer
        isOpen={isAlertDrawerOpen}
        onClick={() => {
          setIsAlertDrawerOpen(false)
        }}
        // custom dash references alerts internally which messes with the positioning
        style={{ marginTop: route.isDashboard ? '-55px' : 0 }}
      ></OverlayContainer>
      <Drawer
        ref={ref}
        isOpen={isAlertDrawerOpen}
        style={{ marginTop: route.isDashboard ? '-55px' : 0 }}
      >
        <Wrapper>
          {isShare && alertData ? (
            <ShareAlert
              alertData={alertData}
              setIsOpen={setIsAlertDrawerOpen}
              refetch={refetch}
            />
          ) : (
            <>
              {isView ? (
                <ViewAlert
                  key={alertData?.alert?.id}
                  alertData={alertData}
                  setIsOpen={setIsAlertDrawerOpen}
                  refetch={refetch}
                />
              ) : (
                <CreateAlert
                  key={alertData?.alert?.id ?? JSON.stringify(alertRequest)}
                  alertData={alertData}
                  setIsOpen={setIsAlertDrawerOpen}
                  alertRequest={alertRequest}
                />
              )}
            </>
          )}
        </Wrapper>
      </Drawer>
    </>
  )
}

const OverlayContainer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  z-index: ${(props) => (props.isOpen ? 200 : -1)};
  height: 100vh;
  width: 100vw;
  transition: background-color 0.2s ease-in-out;
  background: ${(props) => (props.isOpen ? 'rgba(0, 0, 0, 0.2)' : 'none')};
`
const Drawer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  z-index: 201;
  height: 100vh;
  width: 50%;
  max-width: 750px;
  background: white;
  right: 0;
  top: 0;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  box-shadow: ${(props) =>
    props.isOpen
      ? '0px 0px 100px -20px rgba(50, 50, 93, 0.25), -10px 0px 60px -30px rgba(0, 0, 0, 0.3)'
      : 'none'};
  transform: ${(props) =>
    props.isOpen ? 'translateX(0%)' : 'translateX(100%)'};
  transition: 0.25s ease-in-out;
  overflow: hidden;
`

const Wrapper = styled.div`
  position: relative;
  background: white;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  height: 100%;
`

const Arrow = styled.div`
  position: absolute;
  z-index: -1;
  top: 45vh;
  left: -40px;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: white;
  cursor: pointer;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  box-shadow:
    -8px 4px 8px rgba(17, 17, 26, 0.1),
    -4px -4px 8px rgba(17, 17, 26, 0.1);
`
