import { toast } from 'react-toastify'
import React from 'react'
import { LinkOutlined } from '@ant-design/icons'
import { useAuthContext } from 'features/auth'
import styled from 'styled-components'
import { buttonBlue, buttonHover } from 'assets/styles/variables'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'
import mixpanel from 'features/trackers/mixpanel'

export const CopyShareUrlButton: React.FC = () => {
  const route = useProjectStore((state: ProjectState) => state.route)
  const authContext = useAuthContext()
  return (
    <Container
      onClick={() => {
        const searchParams = new URLSearchParams(location.search)
        searchParams.set('share_type', 'user')
        searchParams.set('share_user', authContext.user.username)
        const url =
          'https://app.meetyogi.com' +
          window.location.pathname +
          '?' +
          searchParams.toString()
        navigator.clipboard
          .writeText(url)
          .then(() => toast.info('URL copied to clipboard'))
        mixpanel.track('copy url', {
          value: url,
          ...route,
        })
      }}
    >
      <LinkOutlined />
    </Container>
  )
}

const Container = styled.div`
  color: ${buttonBlue};
  cursor: pointer;
  &:hover {
    color: ${buttonHover};
  }
`
