import React from 'react'
import { Container, InsightsListItemsWrapper } from './InsightList.styles'
import InsightListOptions from './components/InsightListOptions/InsightListOptions'
import { Cohort, OutlierType } from '../../model'
import InsightListItem from './components/InsightListItem/InsightListItem'
import YogiInfo from '../../../../../../components/UI/YogiInfo/YogiInfo'
import { lightBlueFontColor } from '../../../../../../assets/styles/variables'
import { Filters } from '../../../../types'
import { BookmarkBorder } from '@mui/icons-material'
import { YogiButton } from '../../../../../../components/UI/YogiButton'
import mixpanel from '../../../../../trackers/mixpanel'
import styled from 'styled-components'
import { LogoSpinner } from 'components/Loading/LogoSpinner/LogoSpinner'

interface Props {
  outliers: OutlierType[]
  selectedOutlier: OutlierType | undefined
  incrementPage: () => void
  isPageLoading: boolean
  hasMoreOutliers: boolean
  search: string
  isLoading: boolean
}

const InsightList: React.FC<Props> = ({
  outliers,
  selectedOutlier,
  incrementPage,
  isPageLoading,
  hasMoreOutliers,
  search,
  isLoading,
}) => {
  const searchedOutliers = outliers.filter((outlier) => {
    if (!search) return true
    return (
      outlier.analysis?.stinger.toLowerCase().includes(search.toLowerCase()) ||
      outlier.context_with_value?.value
        .toLowerCase()
        .includes(search.toLowerCase()) ||
      outlier.theme?.toLowerCase().includes(search.toLowerCase())
    )
  })

  return (
    <Container>
      {/*<InsightListOptions*/}
      {/*  activeTab={activeTab}*/}
      {/*  setActiveTab={setActiveTab}*/}
      {/*  search={search}*/}
      {/*  setSearch={setSearch}*/}
      {/*  resetPageCount={resetPageCount}*/}
      {/*></InsightListOptions>*/}
      <InsightsListItemsWrapper>
        {outliers.length === 0 && !isLoading && (
          <MessageContainer>
            <YogiInfo color={lightBlueFontColor}>
              <div>
                No Insights found in the last 12 months for the selected filter.
              </div>
            </YogiInfo>
          </MessageContainer>
        )}
        {searchedOutliers.map((item, index) => (
          <InsightListItem
            key={item.uuid}
            outlier={item}
            isSelected={
              selectedOutlier ? selectedOutlier.uuid === item.uuid : index === 0
            }
          ></InsightListItem>
        ))}
      </InsightsListItemsWrapper>

      {hasMoreOutliers && (
        <YogiButton
          loading={isPageLoading}
          disabled={!hasMoreOutliers}
          onClick={incrementPage}
          style={{
            marginTop: 10,
            width: '100%',
          }}
        >
          {isPageLoading ? 'Loading More' : 'Load More'}
        </YogiButton>
      )}
    </Container>
  )
}

export default InsightList

const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`
