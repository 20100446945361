import React, { useContext, useMemo, useState } from 'react'
import styled from 'styled-components'
import { buttonBlue, cardBorder } from 'assets/styles/variables'
import FavoriteInsightButton from 'features/project/features/Insights/components/FavoriteInsightButton/FavoriteInsightButton'
import { OutlierType } from 'features/project/features/Insights/model'
import {
  ExportData,
  Filters,
  ProjectState,
  UserInfo,
} from 'features/project/types'
import { SaveAndExportButton } from 'components/SaveAndExportButton/SaveAndExportButton'
import mixpanel from 'features/trackers/mixpanel'
import {
  downloadChartAsCSV,
  onCopyChart,
} from 'features/project/features/Charts/utils'
import { ValuesChart } from 'features/project/features/Charts/types'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import useAutoInsights from 'features/project/features/Insights/hooks/useAutoInsights'
import { DownloadOutlined } from '@ant-design/icons'
import { Download } from '@mui/icons-material'
import { DownloadSVG } from 'features/project/features/Insights/components/InsightsFeedSVGComponents/InsightsFeedSVGComponents'
import SemanticReviewCarousel from 'components/SemanticReviewCarousel/SemanticReviewCarousel'
import { Popover, Select } from 'antd'
import { useQuery, useQueryClient } from 'react-query'
import { fetchSharedUsers } from 'features/project/features/share/model'
import { AuthContext } from 'features/auth'
import {
  saveDashboard,
  shareDashboard,
  updateDashboard,
} from 'features/project/features/dashboard/model'
import { DashboardResponse } from 'features/project/features/dashboard/types'
import { toast } from 'react-toastify'
import useCustomDashboards from 'features/project/hooks/useCustomDashboards'
import { YogiButton } from 'components/UI/YogiButton'
import { getOutlierTitle } from 'features/project/features/Insights/utils'
import { CopyShareUrlButton } from 'components/CopyShareUrlButton/CopyShareUrlButton'
const { Option } = Select

type Props = {
  outlier: OutlierType
  filterValues: Filters
  imageRef: React.RefObject<HTMLDivElement>
}

export const ShareExport: React.FC<Props> = ({
  outlier,
  filterValues,
  imageRef,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const route = useProjectStore((state: ProjectState) => state.route)

  const setExportData = useProjectStore(
    (state: ProjectState) => state.setExportData,
  )
  const setIsExportDrawerOpen = useProjectStore(
    (state: ProjectState) => state.setIsExportDrawerOpen,
  )
  const setIsExporting = useProjectStore(
    (state: ProjectState) => state.setIsExporting,
  )
  const authContext = useContext(AuthContext)
  const currentUserEmail = authContext?.user?.attributes?.email

  const [newViewers, setNewViewers] = useState<string[]>([])
  const [isOpen, setIsOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { convertOutlierToCustomDashboard } = useAutoInsights(
    outlier,
    filterValues,
  )

  const { data: customDashboardsResponse } = useCustomDashboards()

  const queryClient = useQueryClient()

  const { data } = useQuery(
    ['shared-users', projectId],
    () => fetchSharedUsers(projectId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId,
    },
  )

  const allUsers = useMemo(() => {
    if (!data) return []
    return [
      ...new Set(
        [...data.admins, ...data.shared_users, ...data.yogi_employees].sort(),
      ),
    ]
  }, [data?.admins, data?.shared_users])

  const { outlierFilters } = useAutoInsights(outlier, {
    values: outlier.filter.criteria,
    searchCondition: outlier.filter.search_criteria,
    searchQuery: outlier.filter.search_terms,
  })

  const exportFn = () => {
    mixpanel.track('export', {
      type: 'insight',
      action: 'download',
      value: getOutlierTitle(outlier),
      ...route,
    })

    const exportData: ExportData = {
      comparativeIndex: 0,
      filterValues: outlierFilters,
      downloadImage: () => {
        setIsExporting(true)
        onCopyChart(
          imageRef,
          imageRef,
          outlier.analysis?.stinger ?? 'insight',
          true,
        ).then(() => {
          setIsExporting(false)
        })
      },
      isDashboard: true,
    }
    setExportData(exportData)
    setIsExportDrawerOpen(true)
  }

  const handleShare = async () => {
    mixpanel.track('insights feed', {
      action: 'share',
      insight: getOutlierTitle(outlier),
      users: newViewers,
    })
    setIsSubmitting(true)

    let bookmarkRecord = customDashboardsResponse?.find(
      (customDashboard) =>
        customDashboard.dashboard.dashboard_settings.extra_settings?.outlier
          ?.uuid === outlier.uuid,
    )

    // if no "bookmark" (aka custom dashboard record) exists for this outlier,
    // we have to create it for the current user first before we share it
    // this is pretty janky tbh don't love it
    if (!bookmarkRecord) {
      const newDashboard = convertOutlierToCustomDashboard(true)
      // we have to set the active users list in the extra settings, kind of annoying but necessary
      newDashboard.dashboard_settings.extra_settings.active_users = [
        ...new Set([
          ...newDashboard.dashboard_settings.extra_settings.active_users,
          ...newViewers,
        ]),
      ]
      bookmarkRecord = await saveDashboard(projectId, newDashboard)
    } else {
      // we have to set the active users list in the extra settings, kind of annoying but necessary
      bookmarkRecord.dashboard.dashboard_settings.extra_settings.active_users =
        [
          ...new Set([
            ...bookmarkRecord.dashboard.dashboard_settings.extra_settings
              .active_users,
            ...newViewers,
          ]),
        ]
      bookmarkRecord = await updateDashboard(projectId, {
        dashboard_id: bookmarkRecord.dashboard.id,
        name: bookmarkRecord.dashboard.name,
        dashboard_settings: bookmarkRecord.dashboard.dashboard_settings,
      })
    }

    const promises = []

    if (newViewers.length > 0) {
      const body = {
        id: bookmarkRecord?.dashboard.id,
        as_owners: false,
        email_addresses: newViewers,
        message: '',
      }
      promises.push(shareDashboard(projectId, body))
    }
    try {
      const promisesResponse = await Promise.all(promises)

      const newOwners: UserInfo[] = promisesResponse.reduce(
        (acc: UserInfo[], curr) => [...acc, ...curr.data.owners],
        [],
      )
      const newViewers: UserInfo[] = promisesResponse.reduce(
        (acc: UserInfo[], curr) => [...acc, ...curr.data.viewers],
        [],
      )

      const updatedDashboard = {
        dashboard: {
          ...bookmarkRecord.dashboard,
        },
        owners: [...bookmarkRecord.owners, ...newOwners],
        // assemble new viewers list, removing any that may have been
        // "promoted" to owner
        viewers: [...bookmarkRecord.viewers, ...newViewers].filter(
          (viewer) =>
            ![...bookmarkRecord.owners, ...newOwners].some(
              (owner) => owner.email === viewer.email,
            ),
        ),
      }

      queryClient.setQueryData(
        ['custom-dashboards', projectId],
        (oldData: any) => {
          oldData = oldData.filter((dashboard: DashboardResponse) => {
            return dashboard.dashboard.id !== bookmarkRecord.dashboard.id
          })
          return [...oldData, updatedDashboard]
        },
      )
      setNewViewers([])
      // setMessage('')
      setIsOpen(false)
      setIsSubmitting(false)
      toast.success('Insight shared')
    } catch (e) {
      setIsSubmitting(false)
      toast.error('Error sharing Insight')
    }
  }

  return (
    <Container>
      <Top>
        <BookmarkButton>
          <FavoriteInsightButton
            outlier={outlier}
            filterValues={filterValues}
            text={'bookmark'}
          />
        </BookmarkButton>
        <Popover
          trigger={'click'}
          open={isOpen}
          onOpenChange={(v: boolean) => {
            setIsOpen(v)
          }}
          content={
            <div
              style={{ marginTop: -3, width: 300 }}
              // onClick={() => setIsOpen(true)}
            >
              <div>Share Insight</div>
              <Select
                mode="multiple"
                allowClear
                value={newViewers}
                style={{ width: '100%', marginTop: 5 }}
                placeholder="Choose users to share view"
                onChange={(value) => {
                  setNewViewers(value)
                }}
              >
                {allUsers
                  .filter(
                    (user) => user !== currentUserEmail,
                    // &&
                    // !recipients.includes(user) &&
                    // !viewers.find((el) => el.email === user) &&
                    // // we don't support "downgrading" owners to viewers in the API
                    // !owners.find((el) => el.email === user),
                  )
                  .map((user) => (
                    <Option key={user} value={user}>
                      {user}
                    </Option>
                  ))}
              </Select>
              <ButtonWrapper>
                <CopyShareUrlButton />
                <YogiButton
                  type="primary"
                  loading={isSubmitting}
                  onClick={handleShare}
                >
                  share
                </YogiButton>
              </ButtonWrapper>
            </div>
          }
        >
          <ShareButton>share</ShareButton>
        </Popover>
      </Top>
      <Bottom>
        <BookmarkButton style={{ paddingLeft: 5, gap: 10 }} onClick={exportFn}>
          <DownloadSVG />
          <div>save & export</div>
        </BookmarkButton>
      </Bottom>
    </Container>
  )
}

const Container = styled.div`
  font-size: var(--font-size-md);
  padding: 18px;
  background: #ffffff;
  color: ${buttonBlue};
  border: 2px solid ${cardBorder};
  box-shadow: 0 4px 10px 0 #00000026;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const BookmarkButton = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
  cursor: pointer;
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ShareButton = styled.div`
  font-size: var(--font-size-md);
  width: fit-content;
  padding: 5px 15px;
  background: ${buttonBlue};
  color: white;
  border-radius: 20px;
  cursor: pointer;
`

const Bottom = styled.div``

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`
