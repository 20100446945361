import { FilterOptionSelect } from 'features/project/features/filters/types'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'

const useHub = () => {
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )
  // check to see if dataset type hub
  const hasDatasets =
    (
      defaultFilterList?.find(
        (el) => el.field === 'proj_uuid',
      ) as FilterOptionSelect
    )?.values.length > 1

  return {
    hasDatasets,
  }
}

export default useHub
