import styled from 'styled-components'
import {
  darkBlue,
  lightBlueFontColor,
  pageBackgroundGrey,
} from '../../../../../../assets/styles/variables'
import YogiInfo from '../../../../../../components/UI/YogiInfo/YogiInfo'

export const Container = styled.div`
  height: 100%;
  flex-grow: 1;
  margin-top: -7px;
  //padding: 0px 0 20px 27px;
  display: flex;

  background: ${pageBackgroundGrey};
  border-radius: 10px;
`

export const Content = styled.div`
  height: 100%;
  flex-grow: 1;
  overflow: auto;
  padding-right: 15px;
`

export const SidePanel = styled.div`
  height: 100%;
  min-width: 300px;
  padding: 20px;
`

export const Header = styled.div`
  margin-left: 35px;
`

export const Title = styled.h2`
  color: #28364f; //${darkBlue};
  flex-grow: 2;
  margin-bottom: 15px;
  font-weight: 600;
  line-height: 1.4;
`

export const Bookmark = styled.h2`
  color: ${lightBlueFontColor};
  margin-top: 5px;
  margin-bottom: 0;
`

export const Subtitle = styled.div`
  position: relative;

  width: 100%;
  margin-bottom: 20px;
  padding: 33px 24px 26px 69px;

  font-size: 28px;
  font-weight: 300;

  color: white;
  background: linear-gradient(
    180deg,
    #5072a4 0%,
    #4b6a99 11%,
    #3f5a81 34.5%,
    #364d6f 51.5%,
    #2b3e59 74%,
    #1e2b3e 100%
  );

  border-radius: 10px;
`
