import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import {
  buttonBlue,
  darkBlue,
  darkBlueHover,
  lightGreyBackground,
  notInterestedButtonBackground,
} from 'assets/styles/variables'
import { Bookmark } from '@mui/icons-material'
import useCustomDashboards from 'features/project/hooks/useCustomDashboards'
import { OutlierType } from 'features/project/features/Insights/model'
import { useHistory } from 'react-router'
import { LoadingOutlined } from '@ant-design/icons'
import { AuthContext } from 'features/auth'

type Props = {
  isCohortSet: boolean
}

export const Bookmarks: React.FC<Props> = ({ isCohortSet }) => {
  const [collapsed, setCollapsed] = React.useState(false)

  const authContext = useContext(AuthContext)
  const currentUserEmail = authContext?.user?.attributes?.email

  // "saved" insights are stored in the custom dashboards
  const { data: customDashboardsResponse, isLoading } = useCustomDashboards()
  const history = useHistory()

  const bookmarks: OutlierType[] =
    customDashboardsResponse
      ?.filter(
        (item) =>
          item.dashboard.dashboard_settings.extra_settings?.outlier &&
          item.dashboard.dashboard_settings.extra_settings?.active_users?.includes(
            currentUserEmail,
          ),
      )
      .map(
        (outlier) =>
          outlier.dashboard.dashboard_settings.extra_settings.outlier,
      ) ?? []

  useEffect(() => {
    if (isCohortSet) {
      setCollapsed(true)
    }
  }, [isCohortSet])

  const handleBookmarkClick = (bookmark: OutlierType) => {
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.set('id', bookmark.uuid)
    history.push(`${window.location.pathname}?${urlParams.toString()}`)
  }

  return (
    <Container
      collapsed={collapsed}
      onClick={() => {
        if (bookmarks.length === 0) return
        setCollapsed((prev) => !prev)
      }}
    >
      <Header>
        <Title>
          My Bookmarks <Bookmark fontSize={'small'} />
        </Title>
        <div>{isLoading ? <LoadingOutlined /> : bookmarks.length}</div>
      </Header>
      <Body>
        <BookmarkList>
          {bookmarks.map((bookmark) => {
            const ownerEmail = customDashboardsResponse?.find(
              (item) =>
                item.dashboard.dashboard_settings.extra_settings?.outlier
                  ?.uuid === bookmark.uuid,
            )?.owners[0].email

            const isSharedBookmark = ownerEmail !== currentUserEmail

            return (
              <BookmarkItem
                key={bookmark.uuid}
                onClick={(e) => {
                  handleBookmarkClick(bookmark)
                  setCollapsed(true)
                  e.preventDefault()
                  e.stopPropagation()
                }}
              >
                {bookmark.analysis?.stinger}
                {isSharedBookmark && (
                  <SharedText>Shared by {ownerEmail}</SharedText>
                )}
              </BookmarkItem>
            )
          })}
        </BookmarkList>
      </Body>
    </Container>
  )
}

const Container = styled.div<{ collapsed: boolean }>`
  position: absolute;
  top: 95px;
  right: 32px;
  //min-width: 500px;
  max-width: 800px;
  width: 50%;
  margin: 37px 0;
  //padding-bottom: 28px;

  display: flex;
  flex-direction: column;

  //max-height: calc(50% - 100px);
  max-height: ${(props) => (props.collapsed ? '50px' : 'calc(50% - 150px)')};

  background: ${lightGreyBackground};

  border-radius: 10px;
  border: 1px solid ${notInterestedButtonBackground};

  cursor: ${(props) => (props.collapsed ? 'pointer' : 'default')};
  z-index: 101;
  box-shadow: ${(props) =>
    !props.collapsed ? '0 4px 10px 0 #00000026' : 'none'};

  transition: all 0.2s ease-in-out;
  overflow: hidden;
`

const Header = styled.div`
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
  color: ${buttonBlue};
  margin-bottom: 5px;
  padding: 13px 28px;
  cursor: pointer;
`

const Title = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  color: ${buttonBlue};
`

const BookmarkList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  //padding: 10px;

  padding: 0 28px 28px;
`

const BookmarkItem = styled.div`
  flex-basis: calc(50% - 25px);
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;

  //max-width: calc(50% - 10px);
  min-height: 150px;
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  padding: 18px 20px;
  border-radius: 10px;
  background: ${darkBlue};
  color: #fff;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: ${darkBlueHover};
  }
`

const SharedText = styled.div`
  font-weight: 400;
`

const Body = styled.div`
  height: 100%;
  overflow: auto;
`
