import styled from 'styled-components'
import React from 'react'
import { OutlierType } from 'features/project/features/Insights/model'
import { lightGreyBackground } from 'assets/styles/variables'
import useResizeObserver from 'use-resize-observer'
import SemanticKeywords from 'components/SemanticKeywords/SemanticKeywords'
import useAutoInsights from 'features/project/features/Insights/hooks/useAutoInsights'
import { useChartData } from 'features/project/hooks/useChartData'
import { StackBarChart } from 'features/project/features/Charts/types'
import { LoadingOutlined } from '@ant-design/icons'

interface Props {
  outlier: OutlierType
}

export const InsightStats: React.FC<Props> = ({ outlier }) => {
  const { ref: resizeRef, width: resizeWidth } =
    useResizeObserver<HTMLDivElement>()

  const { analysis: parsedAnalysis } = outlier
  const { outlierFilters } = useAutoInsights(outlier, {
    values: outlier.filter.criteria,
    searchQuery: outlier.filter.search_terms,
    searchCondition: outlier.filter.search_criteria,
  })

  const { data: chartItem } = useChartData(
    0,
    outlierFilters,
    'Product',
    '6_3_17_sentiment',
    'post',
    'month',
    0,
    false,
  )

  const chart = chartItem?.charts[0]

  const distributionData = (
    chart as StackBarChart
  )?.values?.[0].stacked_categories?.reverse()

  const largestCategory = distributionData?.sort((a, b) => {
    return (
      Number((b.hover?.['% Volume'] as string).replace('%', '')) -
      Number((a.hover?.['% Volume'] as string).replace('%', ''))
    )
  })[0]?.category

  return (
    <Container>
      <Stats ref={resizeRef}>
        <Content>
          <Section>
            <RatingValue>{outlier.metric_score.toFixed(2)}</RatingValue>
            <RatingLabel>
              <Bold>average</Bold> {outlier.metric}
            </RatingLabel>
          </Section>
          {distributionData ? (
            <Distribution>
              {distributionData.map((distribution) => {
                const percent = distribution.hover?.['% Volume'] as string
                const percentNumber = Number(percent.replace('%', '')).toFixed(
                  0,
                )
                return (
                  <DistributionSection
                    color={distribution.color}
                    width={distribution.hover?.['% Volume'] as string}
                  >
                    {distribution.category === largestCategory
                      ? `${percentNumber}%`
                      : ''}
                  </DistributionSection>
                )
              })}
            </Distribution>
          ) : (
            <LoadingOutlined />
          )}
          <Section>
            <RatingValue>{outlier.volume}</RatingValue>
            <RatingLabel>sentences</RatingLabel>
          </Section>
        </Content>
      </Stats>
      <Summary width={resizeWidth}>
        {parsedAnalysis && (
          <SemanticKeywords
            body={parsedAnalysis?.subheader}
            keywords={parsedAnalysis?.key_phrases}
            brands={
              outlier.context_with_value?.context === 'brand'
                ? [outlier.context_with_value.value]
                : []
            }
            products={
              outlier.context_with_value?.context === 'product'
                ? [outlier.context_with_value.value]
                : []
            }
            themes={outlier.theme ? [outlier.theme] : []}
            filterValues={outlierFilters}
          />
        )}
      </Summary>
    </Container>
  )
}

const Container = styled.div`
  flex-grow: 0;
  flex-shrink: 1;
`
const Stats = styled.div`
  width: fit-content;
  margin-bottom: 18px;
  padding: 15px 30px;

  background: linear-gradient(
    180deg,
    #5072a4 0%,
    #4b6a99 11%,
    #3f5a81 34.5%,
    #364d6f 51.5%,
    #2b3e59 74%,
    #1e2b3e 100%
  );
  color: white;
  height: 100px;
  border-radius: 10px;

  @media screen and (max-width: 1400px) {
    width: 100%;
  }
`

const Content = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
`

const Section = styled.div``

const RatingValue = styled.div`
  font-size: 44px;
  font-weight: var(--font-weight-bold);
  line-height: 45px;
  text-align: center;
`
const RatingLabel = styled.div`
  font-size: var(--font-size-l);
  //line-height: 25px;
  font-weight: 300;
  text-align: center;
  white-space: nowrap;
`
const Bold = styled.span`
  font-weight: var(--font-weight-bold);
`

const Summary = styled.div<{ width?: number }>`
  width: ${(props) => (props.width ? props.width + 60 + 'px' : '400px')};
  background: ${lightGreyBackground};
  padding: 16px;
  border-radius: 10px;
`

const Distribution = styled.div`
  display: flex;
  border-radius: 10px;
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
  max-width: 50%;
  min-width: 150px;
`

const DistributionSection = styled.div<{ color: string; width: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: ${(props) => props.color};
  width: ${(props) => props.width};
`
